import { Component } from '@angular/core';

@Component({
  selector: 'medis-confirm-action-outlet',
  templateUrl: './confirm-action-outlet.component.html',
  styleUrl: './confirm-action-outlet.component.scss'
})
export class ConfirmActionOutletComponent {
  defaultText = {
    rejectLabel: 'Katkesta',
    acceptLabel: 'Kinnita',
    header: 'Kinnita tegevust',
    message: 'Kas te olete kindel?',
    icon: 'pi-question',
    iconBg: 'bg-primary',
  }

}
