<div class="flex flex-column gap-4">
  <div class="flex row w-full gap-1">
    <div class="grid grid-nogutter gap-4 w-full">
      <div class="col-fixed">
        <p-selectButton
          [options]="stateOptions"
          [(ngModel)]="actionValue"
          optionLabel="label"
          optionValue="value"
          aria-labelledby="basic"
          (onChange)="onStateOptionChange($event)"
        />
      </div>
      <div class="col w-full" [formGroup]="form" role="form">
        <p-floatLabel>
          <p-dropdown
            id="select-related-person"
            styleClass="w-full"
            [options]="validProviders"
            optionValue="personId"
            optionLabel="fullName"
            appendTo="body"
            formControlName="relatedPersonId"
          />
          <label for="select-related-person">Kolleeg</label>
        </p-floatLabel>
      </div>
    </div>
  </div>
</div>
