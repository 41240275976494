import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ListService } from "@core/services/list.service";
import { ToastService } from "@core/services/toast.service";
import { IGeneralList } from "@core/interfaces/general-list.interface";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { IMapping } from "@core/interfaces/mapping.interface";
import { GeneralList } from "@core/enums/general-list.enum";

@Component({
  selector: 'medis-general-select-list',
  template: `
    <p-floatLabel>
      @if (!multiple) {
        <p-dropdown
          [id]="formControlName"
          class="w-full"
          styleClass="w-full"
          [ngClass]="{ 'ng-invalid': invalid }"
          [options]="list"
          [filter]="list.length > 15"
          filterBy="name"
          [(ngModel)]="value"
          [showClear]="true"
          (onChange)="onSelect($event.value)"
          optionValue="generalListId"
          optionLabel="name"
          [appendTo]="appendTo"
          [disabled]="disabled"
        />
      } @else {
        <p-multiSelect
          [id]="formControlName"
          class="w-full"
          styleClass="w-full"
          [ngClass]="{ 'ng-invalid': invalid }"
          [options]="list"
          [filter]="list.length > 15"
          filterBy="name"
          [(ngModel)]="value"
          [maxSelectedLabels]="undefined"
          [showClear]="true"
          (onChange)="onSelect($event.value)"
          optionValue="generalListId"
          optionLabel="name"
          display="chip"
          [appendTo]="appendTo"
          [disabled]="disabled"
        >
        </p-multiSelect>
      }
      <label [for]="formControlName">{{ labelName }}</label>
    </p-floatLabel>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GeneralSelectListComponent),
      multi: true
    },
  ]
})
export class GeneralSelectListComponent implements OnInit, ControlValueAccessor {
  @Input() listId!: GeneralList;
  @Input() formControlName: string = '';
  @Input() labelName: string = '';
  @Input() multiple = false;
  @Input() appendTo: any = null;
  @Input() invalid = false;
  @Input() disabled = false;

  @Output() onItemChange = new EventEmitter<IMapping>();
  @Output() selectedGeneralListItem = new EventEmitter();

  private readonly filterKeyMap: IMapping = {
    [GeneralList.SPECIALTY]: 'professionId',
    [GeneralList.STUDY_TYPE]: 'studyType',
    [GeneralList.PROCEDURE_KIND]: 'procedureKind',
    [GeneralList.PROCEDURE_TYPE]: 'procedureType',
  };

  list: IGeneralList[] = [];
  value: any;
  isDisabled = false;

  constructor(private listService: ListService, private toastService: ToastService) {
  }

  ngOnInit(): void {
    if (this.listId) {
      this.getListByType();
    }
  }

  getListByType(listId = this.listId): void {
    this.listService.getByType(listId).subscribe({
      next: resolve => {
        this.list = resolve;
      },
      error: err => {
        this.toastService.error(err.message);
      }
    })
  }

  onChange = (value: any) => {
  };
  onTouched = () => {
  };

  // This method sets the value of the control.
  writeValue(value: any): void {
    this.value = value;
  }

  // This method is called when the value in the form changes.
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  // This method is called when the control is touched.
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  // This method is called to enable or disable the control.
  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  // This method is triggered when the dropdown value changes.
  onSelect(value: any) {
    this.value = value;
    this.onItemChange.emit({ [this.filterKeyMap[this.listId]]: value });

    if (value instanceof Array) {
      this.selectedGeneralListItem.emit(value.map((x: number) =>
        this.list.find(y => y.generalListId == x)
      ));
    } else {
      this.selectedGeneralListItem.emit(this.list.find(y => y.generalListId == value));
    }

    this.onChange(value); // Notify the form about the new value
    this.onTouched(); // Mark the control as touched
  }
}
