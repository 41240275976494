<div class="flex flex-column gap-4">
  <div id="printSection">
    <div class="order-details">
      <h4>Lp. {{ patient?.fullName ?? 'Patsient' }}</h4>
      <p>Olete oodatud:</p>
      <br>
      <br>
      <div class="details">
        <div class="service flex flex-row">
          TEENUS <b class="ml-4">{{ orderService?.serviceName || '-' }}</b>
        </div>
        <br>
        <div class="fin-source">
          MAKSJA <b class="ml-4">{{ orderService?.financialSourceName || '-' }}</b>
        </div>
      </div>
    </div>
    <br>
    <br>
    <div class="mt-2">
      @if (reservations.length) {
        <p-table [value]="reservations" [tableStyle]="{ width: '100%' }">
          <ng-template pTemplate="header">
            <tr>
              <th>Kuupäev</th>
              <th>Kellaaeg</th>
              <th>Osutaja</th>
              <th>Asukoht</th>
              <th>Kabinet</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-item>
            <tr>
              <td>
                {{ item.date | dateFormat }}
              </td>
              <td>{{ item.range }}</td>
              <td>{{ item.providerName }}</td>
              <td>{{ item.address }}</td>
              <td>{{ item.roomCode }}</td>
            </tr>
          </ng-template>
        </p-table>
      }
    </div>
    <br>
    <br>
    @if (orderService && orderService.financialSourceName === FinancialSource.PERSON) {
      <span class="form-group mb-4">
        Palume tulla registratuuri 15 min enne Teile broneeritud vastuvõtuaega. Võtke kaasa isikut tõendav
        dokument.<br>
      </span>
    }
    Kui Te ei saa kokkulepitud ajal vastuvõtule tulla, palun teavitage sellest Medicumi vähemalt 24 tundi ette.
    <br/><br/>
    @if ((orderService?.orderServiceTimes?.length && orderService?.orderServiceTimes?.[0]?.departmentPhone) ||
    (orderService?.orderServiceQueues?.length && orderService?.orderServiceQueues?.[0]?.departmentPhone)) {
      Registratuuri telefon:
      {{ orderService?.orderServiceTimes?.[0]?.departmentPhone ?? orderService?.orderServiceQueues?.[0]?.departmentPhone }}
    }
    <br/><br/>
    @if (orderService && orderService.financialSourceName !== FinancialSource.PERSON) {
      <span class="form-group mb-4">
        <sup>*</sup> Ambulatoorse eriarstiabi visiiditasu Eesti Tervisekassa poolt kindlustatud vanemale kui
        2aastasele isikule on 5 €.
      </span>
    }
    @if (orderService && orderService.financialSourceName === FinancialSource.PERSON) {
      <span class="form-group mb-4">
        <sup>*</sup> Tasu vastavalt Medicumi hinnakirjale.
      </span>
    }
  </div>
</div>
<div class="p-dialog-footer justify-content-end">
  <p-button label="Prindi"
            severity="contrast"
            (click)="print()"
  />
  <p-button label="Sulge"
            [outlined]="true"
            severity="secondary"
            (click)="closeModal()"
  />
</div>
