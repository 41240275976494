import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { TreeNode } from "primeng/api";
import { DatePipe } from "@angular/common";

interface IMassUpdateResponse {
  planTimesTotal: number;
  planTimesFailed: number;
  result: any;
}

interface ICopyReportData {
  total: number;
  failed: number;
  result: any;
}

@Component({
  selector: "medis-plantime-mass-update-report",
  templateUrl: "./plantime-mass-update-report.html",
})

export class PlantimeMassUpdateReportComponent implements OnInit {
  @Input() massUpdateResponse?: IMassUpdateResponse;
  @Input() copyReport?: ICopyReportData;
  @Output() onClose = new EventEmitter();
  @Output() continueEdit = new EventEmitter();
  @Output() continueCopy = new EventEmitter();

  readonly PlanTimeErrorMessages: Record<string, string> = {
    "0": "Muudetud", // Success
    "PT_ERR_O1": "Aeg sisaldab broneeringuid", // HasBookings
    "PT_ERR_O2": "Ei saa kustutada", // CannotDelete
    "PT_ERR_O3": "Planeeritud aeg on minevikus", // PlanTimeInPast
    "PT_ERR_O4": "Ruum ei ole saadaval", // RoomIsNotAvailable
    "PT_ERR_O5": "Seotud isik ei ole saadaval", // RelatedPersonIsNotAvailable
    "PT_ERR_O6": "Seotud planeeritud aega ei leitud", // NoRelatedPlanTime
    "PT_ERR_O7": "Planeeritud ajal on seotud teine isik", // PlanTimeHasAnotherRelatedPerson
    "PT_ERR_O8": "Teenust ei leitud", // PersonServiceNotFound
    "PT_ERR_O9": "Graafik juba sisaldab selle teenust", // PersonServiceAlreadyExist
    "PT_ERR_10": "Teenuse kuupäev on väiksem kui planeeritud aeg", // ServiceDateLessThanPlanTime
    "PT_ERR_11": "Teenust ei saa salvestada", // CannotSaveService
    "PT_ERR_12": "Teenust ei saa kustutada", // CannotDeleteService
    "PT_ERR_13": "Aparaat ei leitud", // DeviceNotFound
    "PT_ERR_14": "Aparaat ei asu samas ruumis", // DeviceIsNotInTheSameRoom
    "PT_ERR_15": "Aparaati ei saa salvestada", // CannotSaveDevice
    "PT_ERR_16": "Antud kolleeg on juba valitud", // The specified colleague has already been selected
  };

  readonly PlanTimeCopyErrorMessages: Record<string, string> = {
    "0": "Kopeeritud", // Success
    "ERR_1010": "Graafik juba valitud ajale sisestatud", // PlanTimeConflict
    "ERR_1015": "PlanTimeHaveOrders", // PlanTimeHaveOrders
    "ERR_1016": "Teenus {service.Name} ei kehti kuupäeval", // PlanTimeServiceNotActive
    "ERR_1120": "Teenus ei ole valitud", // ServiceNotSelected
    "ERR_1121": "Aparaadi MisId on määramata", // NoMisForDevice
    "ERR_1122": "Teenusel puudub kestvus.", // NoRangeForService
    "ERR_1123": "DevicesArentInSameRoom", // DevicesArentInSameRoom
  };

  successCode = "0";

  cols: any[] = [];
  processedResult: TreeNode[] = [];

  constructor(private datePipe: DatePipe) {
  }

  ngOnInit() {
    if (this.massUpdateResponse) {
      this.processedResult = this.processResult(this.massUpdateResponse.result);
    } else if (this.copyReport) {
      this.processedResult = this.processResult(this.copyReport.result);
    }

    this.cols = [
      { field: 'date', header: 'AEG' },
      { field: 'failed', header: 'EBAÕNNESTUNUD' },
      { field: 'success', header: 'ÕNNESTUNUD' },
    ];
  }

  private processResult(result: any): TreeNode[] {
    return Object.keys(result).map((dateKey) => ({
      data: {
        date: this.datePipe.transform(dateKey, "dd.MM.yyyy"),
        success: Object.keys(result[dateKey]).length - this.calculateFailedPlanTimes(result[dateKey]),
        failed: this.calculateFailedPlanTimes(result[dateKey]),
      },
      children: this.processDailyPlanTimes(result[dateKey]),
    }));
  }

  private processDailyPlanTimes(dayObject: any): TreeNode[] {
    const errorMessages = this.massUpdateResponse
      ? this.PlanTimeErrorMessages
      : this.PlanTimeCopyErrorMessages;

    return Object.keys(dayObject).map((planKey) => {
      const code = dayObject[planKey][0]?.code;
      const isSuccess = code === this.successCode;

      return {
        data: {
          date: planKey,
          failed: !isSuccess ? errorMessages[code] || "Tekkis viga" : "",
          success: isSuccess ? errorMessages[code] || "" : "",
        },
      };
    });
  }

  private calculateFailedPlanTimes(dayObject: any):number {
    let failedCount: number = 0;

    Object.keys(dayObject).forEach((key) => {
      dayObject[key].forEach((value: any) => {
        if(value.code != this.successCode){
          failedCount++;
        }
      })
    })

    return failedCount;
  }

  generateReportCounterTitle(): string {
    if (this.massUpdateResponse) {
      const planTimesSuccess = this.massUpdateResponse.planTimesTotal - this.massUpdateResponse.planTimesFailed;
      return `Massmuutmise tulemused ${planTimesSuccess}/${this.massUpdateResponse.planTimesTotal}`
    } else if (this.copyReport) {
      const planTimesSuccess = this.copyReport.total - this.copyReport.failed;
      return `Kopeerimise tulemused ${planTimesSuccess}/${this.copyReport.total}`
    }
    return ""
  }

  closeModal() {
    this.processedResult = [];
    this.onClose.emit();
  }

  restartPreviousAction() {
    if (this.massUpdateResponse?.result) {
      this.continueEdit.emit();
    }
    if (this.copyReport?.result) {
      this.continueCopy.emit();
    }
    this.processedResult = [];
  }
}
