import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationStart, Router } from "@angular/router";
import { right } from "@popperjs/core";
import { IOrderServiceQueue } from "@core/interfaces/order-service-queue.interface";
import { IPatient } from "@core/interfaces/patient.interface";
import { IOrderServiceTime } from "@core/interfaces/order-service-time.interface";
import { IOrder } from "@core/interfaces/order.interface";
import { ToastService } from "@core/services/toast.service";
import { PrintService } from "@core/services/print.service";
import { ServiceService } from "@core/services/service.service";
import { IService } from "@core/interfaces/service.interface";
import { IBrochure } from "@core/interfaces/brochure.interface";
import { IOrderService } from "@core/interfaces/order-service.interface.interface";
import { FinancialSource } from "@core/enums/financial-sources.enum";
import { IBookingConfirmationRequest } from "@core/interfaces/booking-confirmation-request.interface";
import { NotificationService } from "@core/services/notification.service";
import { DynamicDialogConfig } from "primeng/dynamicdialog";
import { PatientService } from "@core/services/patient.service";
import { Subscription } from "rxjs";
import { InvoiceStatus } from "@core/enums/invoice-status.enum";
import { DatePipe } from "@angular/common";

@Component({
  selector: 'medis-booking-confirmation',
  templateUrl: './booking-confirmation.component.html',
  styleUrls: ['./booking-confirmation.component.scss']
})
export class BookingConfirmationComponent implements OnInit, OnDestroy {
  pageHeader: string = 'Broneeringu kokkuvõte';
  data: any;
  patientId: number;
  patient!: IPatient | undefined;
  order!: IOrder;
  orderDetails: any;
  orderService: IOrderService | null = null;
  orderServiceTimes: IOrderServiceTime[] = [];
  orderServiceQueues: IOrderServiceQueue[] = [];
  referral: any;
  brochureText: any | null = null;

  confirmationSent: boolean = false;
  confirmationTooltip = 'Kinnitus saadetud';

  private routerSubscription!: Subscription;

  constructor(
    private router: Router,
    private printService: PrintService,
    private toastService: ToastService,
    private serviceService: ServiceService,
    private notificationService: NotificationService,
    private patientService: PatientService,
    public dynamicDialogConfig: DynamicDialogConfig,
    private datePipe: DatePipe,
  ) {
    this.orderService = this.dynamicDialogConfig.data?.orderService;
    this.patientId = this.dynamicDialogConfig.data?.patientId;
  }

  ngOnInit(): void {
    const state = window.history.state;

    if (state && state['orderService']) {
      this.orderService = state['orderService'] ?? null;
      this.patientId = state['patientId'];
      this.initData();

      const previousRoute = state['previousRoute'];
      const patientDetailsRoutePattern = /^\/patients\/\d+\/.+$/;

      if (previousRoute && patientDetailsRoutePattern.test(previousRoute)) {
        this.routerSubscription = this.router.events.subscribe((event) => {
          if (event instanceof NavigationStart) {
            this.handleNavigation(event.url, patientDetailsRoutePattern);
          }
        });
      }

    } else if (this.orderService) {
      this.initData();
    } else {
      this.router.navigate(['/']);
    }

    const statusesToCheck = [InvoiceStatus.COMPLETED, InvoiceStatus.PREPAID, InvoiceStatus.UNNEEDED];

    if (this.orderService && this.orderService.invoiceStatus
      && !statusesToCheck.includes(this.orderService.invoiceStatus as InvoiceStatus)) {
      this.confirmationSent = true;
    }

  }

  private handleNavigation(nextUrl: string, expectedUrlPattern: RegExp): void {
    const isPatientsPage = expectedUrlPattern.test(nextUrl);
    if (!isPatientsPage) {
      this.clearFilters();
    }
  }

  ngOnDestroy(): void {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

  private clearFilters(): void {
    const keys = ['patientReservationFilters_orders', 'patientReservationFilters_reservations'];
    keys.forEach(key => sessionStorage.removeItem(key));
  }

  back(): void {
    history.back();
  }

  private initData(): void {
    if (this.patientId) {
      this.patientService.get(this.patientId).subscribe({
        next: (patient: IPatient) => {
          this.patient = patient;
        }
      })
    }

    this.initializeOrderDetails();
    this.getBrochures();
  }

  get serviceItems() {
    return this.orderServiceQueues?.length > 0 ? this.orderServiceQueues : this.orderServiceTimes;
  }

  initializeOrderDetails(): void {
    this.referral = this.orderService?.referral;

    if (this.orderService?.orderServiceQueues && this.orderService.orderServiceQueues.length > 0) {
      this.orderServiceQueues = this.orderService.orderServiceQueues ?? [];
    } else if (this.orderService?.orderServiceTimes && this.orderService.orderServiceTimes.length > 0) {
      this.orderServiceTimes = this.orderService.orderServiceTimes ?? [];
    }

    this.orderDetails = {
      leftInfo: [
        { label: 'Tellimus', value: this.orderService?.orderId },
        { label: 'Teenus', value: this.orderService?.serviceName },
      ],
      rightInfo: [
        { label: 'Maksja', value: this.orderService?.financialSourceName },
        { label: 'Saatekiri', value: this.referral },
      ]
    };
  }

  getBrochures(){
      if(this.orderService?.serviceId){
        this.serviceService.get(this.orderService?.serviceId).subscribe({
          next: (service: IService) => {
            if(service.brochures && service.brochures.length > 0) {
              const brochure = service.brochures.find((b: IBrochure) => b.brochureType === 'PrintInfo');
              if(brochure && brochure.brochureTranslates && brochure.brochureTranslates.length > 0) {
                this.brochureText = brochure.brochureTranslates.find((bt: any) => bt.language === 'et');
                this.brochureText ? this.brochureText = this.brochureText.description : this.brochureText = brochure.brochureTranslates[0].description
                this.brochureText = this.splitLines(this.brochureText)
              }
            }
            return null;
          },
          error: (err: any) => {
            console.error("Error fetching service", err);
          }
        })
      } else return;
  }

  splitLines = (brochureLines: string) => {
    const split = brochureLines.split('\n');
    const lines = [];
    for (let i = 0; i < split.length; i++)
      if (split[i]) lines.push(split[i]);
    return lines;
  }

  handleNotificationResponse(response: any) {
    const emailStatus = response.emailResult?.status;
    const smsStatus = response.smsResult?.status;

    if (emailStatus !== undefined) {
      if (emailStatus === 0) {
        this.toastService.success("E-kiri saadetud patsiendile.");
      } else {
        this.toastService.error("E-kiri saatmise ebaõnnestus.");
      }
    }

    if (smsStatus !== undefined) {
      if (smsStatus === 0) {
        this.toastService.success("SMS saadetud patsiendile.");
      } else {
        this.toastService.error("SMS saatmise ebaõnnestus.");
      }
    }
  }

  sendConfirmation() {
    if (this.confirmationSent) return;

    if (!this.patient?.phone && !this.patient?.contact?.email) {
      this.toastService.warn("Täitke patsiendi kontakt andmed");
      return;
    }
    const sendSms = !!this.patient?.phone;
    const sendEmail = !!this.patient?.contact?.email;

    const orderServiceIds = [this.orderService!.orderServiceId] ?? [];

    if (!orderServiceIds || orderServiceIds.length === 0) {
      this.toastService.warn("Kinnituse viga, palun proovige veel kord");
      return;
    }
    this.confirmationSent = true;

    const bookingConfirmationRequest: IBookingConfirmationRequest = {
      sendEmail,
      sendSms,
      orderServiceIds
    };

    this.notificationService.sendBookingConfirmation(bookingConfirmationRequest).subscribe({
      next: (response: any) => {
        this.handleNotificationResponse(response);
      },
      error: () => {
        this.toastService.error("Kinnituse saatmine ebaõnnestus.");
        this.confirmationSent = false;
      }
    });
  }

  print(){
    const doc = document.getElementById("print-section");
    if (!doc) {
      console.error("Print section not found!");
      return;
    }

    const tableSection = doc.querySelector(".mt-2");
    if (!tableSection) {
      console.error("Table container not found!");
      return;
    }

    tableSection.innerHTML = "";
    const tableContainer = document.createElement("div");

    tableContainer.style.backgroundColor = "#f9f9f9";
    tableContainer.style.border = "2px solid #405C9B";
    tableContainer.style.borderRadius = "8px";

    const table = document.createElement("table");
    table.style.width = "100%";
    table.style.borderCollapse = "collapse";

    const headerRow = document.createElement("tr");
    const headers = ["Kuupäev", "Kellaaeg", "Osutaja", "Asukoht", "Kabinet"];
    headers.forEach((headerText, i) => {
      const th = document.createElement("th");
      if (i === 0) {
        th.style.borderTopLeftRadius = "5px";
      }
      if (i === headers.length - 1) {
        th.style.borderTopRightRadius = "5px";
      }
      th.textContent = headerText;
      th.style.textAlign = "start";
      th.style.padding = "1rem";
      th.style.backgroundColor = "#405C9B";
      th.style.color = "white";
      headerRow.appendChild(th);
    });
    table.appendChild(headerRow);


    const serviceItems = this.serviceItems;
    serviceItems.forEach((item: any) => {
      const row = document.createElement("tr");

      const cellValues = [
        item.date,
        item.range,
        item.providerName,
        item.address,
        item.roomCode,
      ];

      cellValues.forEach((value, i) => {
        const td = document.createElement("td");
        if (i === 0) {
          td.textContent = this.datePipe.transform(item.date, "dd.MM.yyyy");
        } else {
          td.textContent = value;
        }
        if (i === 1) {
          td.style.whiteSpace = "nowrap";
        }
        td.style.textAlign = "start";
        td.style.padding = "1rem";
        row.appendChild(td);
      });

      table.appendChild(row);
    });

    const dateContainer = document.createElement("div");
    const totalBookingsContainer = document.createElement("div");

    dateContainer.style.height = "48px"
    dateContainer.style.borderTop = "2px solid #405C9B"
    dateContainer.style.display = "flex";
    dateContainer.style.flexDirection = "row";

    totalBookingsContainer.style.backgroundColor = "#E5ECFB"
    totalBookingsContainer.style.color = "#405C9B"
    totalBookingsContainer.style.height = "48px"
    totalBookingsContainer.style.borderTop = "2px solid #405C9B"
    totalBookingsContainer.style.borderBottomLeftRadius = "8px";
    totalBookingsContainer.style.borderBottomRightRadius = "8px";
    totalBookingsContainer.style.display = "flex";
    totalBookingsContainer.style.flexDirection = "row";

    const leftSideDateContainer = document.createElement("div");
    const rightSideDateContainer = document.createElement("div");
    const totalBookingsLeftContainer = document.createElement("div");
    const totalBookingsRightContainer = document.createElement("div");

    totalBookingsLeftContainer.style.height = "100%"
    totalBookingsRightContainer.style.height = "100%"
    totalBookingsLeftContainer.style.width = "50%"
    totalBookingsRightContainer.style.width = "50%"

    leftSideDateContainer.style.height = "100%"
    rightSideDateContainer.style.height = "100%"
    leftSideDateContainer.style.width = "50%"
    rightSideDateContainer.style.width = "50%"

    totalBookingsRightContainer.style.display = "flex";
    totalBookingsRightContainer.style.justifyContent = "space-between";
    totalBookingsRightContainer.style.alignItems = "center";

    rightSideDateContainer.style.display = "flex";
    rightSideDateContainer.style.justifyContent = "space-between";
    rightSideDateContainer.style.alignItems = "center";

    totalBookingsRightContainer.style.color = "#405C9B"

    const rightContainerDateTitleContainer = document.createElement("div");
    const rightContainerDateContentContainer = document.createElement("div");
    const totalBookingsRightContainerTitle = document.createElement("div");
    const totalBookingsRightContainerContent = document.createElement("div");


    dateContainer.appendChild(rightContainerDateTitleContainer);
    dateContainer.appendChild(rightContainerDateContentContainer);

    const rightContainerDateTitle = document.createElement("b");
    const rightContainerDateContent = document.createElement("b");
    const rightContainerTitle = document.createElement("b");
    const rightContainerContent = document.createElement("b");

    rightContainerDateContentContainer.style.marginRight = "1rem"
    totalBookingsRightContainerContent.style.marginRight = "1rem"

    rightContainerDateTitle.textContent = "Kuupäev"
    rightContainerDateContent.textContent = this.datePipe.transform(this.orderService?.created, "dd.MM.yyyy") ?? this.datePipe.transform(new Date(), "dd.MM.yyyy");
    rightContainerTitle.textContent = "Kokku"
    rightContainerContent.textContent = serviceItems.length + this.generateBookingContent(serviceItems.length);

    rightContainerDateTitleContainer.append(rightContainerDateTitle);
    rightContainerDateContentContainer.append(rightContainerDateContent);
    totalBookingsRightContainerTitle.append(rightContainerTitle);
    totalBookingsRightContainerContent.append(rightContainerContent);

    rightSideDateContainer.appendChild(rightContainerDateTitleContainer);
    rightSideDateContainer.appendChild(rightContainerDateContentContainer);
    totalBookingsRightContainer.appendChild(totalBookingsRightContainerTitle);
    totalBookingsRightContainer.appendChild(totalBookingsRightContainerContent);

    dateContainer.appendChild(leftSideDateContainer);
    dateContainer.appendChild(rightSideDateContainer);
    totalBookingsContainer.appendChild(totalBookingsLeftContainer);
    totalBookingsContainer.appendChild(totalBookingsRightContainer);

    tableContainer.appendChild(table);
    tableContainer.appendChild(dateContainer);
    tableContainer.appendChild(totalBookingsContainer);
    tableSection.appendChild(tableContainer);

    this.printService.printDiv("print-section");
  }

  private generateBookingContent(len: number) {
    return len > 1 ? " visiidi" : " visiit"
  }

  updatePatient(patient: IPatient): void {
    this.patient = patient;
  }

  protected readonly right = right;
  protected readonly FinancialSource = FinancialSource;
}
