import {
  Component, ComponentRef, ContentChild, ElementRef,
  EventEmitter, Injector,
  Input, OnChanges, OnDestroy, OnInit,
  Output, Renderer2, SimpleChanges, TemplateRef,
  ViewChild, ViewContainerRef, WritableSignal
} from '@angular/core';
import { FullCalendarComponent } from "@fullcalendar/angular";
import {
  CalendarMonthDropdownComponent
} from "app/feature/doctors/doctors/plantime/components/calendar-month-dropdown/calendar-month-dropdown.component";
import { CalendarOptions } from "@fullcalendar/core";
import { Subscription } from "rxjs";
import { HeaderService } from "@core/services/header.service";

@Component({
  selector: 'medis-calendar',
  templateUrl: './calendar.component.html',
  styleUrl: './calendar.component.scss'
})
export class CalendarComponent implements OnInit, OnDestroy, OnChanges {

  @ViewChild('calendar') calendar!: FullCalendarComponent;
  @Input() calendarOptions!: WritableSignal<CalendarOptions>;
  @Input() calendarCurrentMonth?: Date | number | undefined = undefined;
  @Input() calendarCurrentWeek?: {start: Date; end: Date} | undefined = undefined;
  @Input() currDayGrid: string = "dayGridMonth";
  @Input() small: boolean = false;
  @Output() calendarObject = new EventEmitter<FullCalendarComponent>();
  @ContentChild('eventContent') eventContent!: TemplateRef<FullCalendarComponent>;

  private componentRef!: ComponentRef<CalendarMonthDropdownComponent>;
  private observer!: MutationObserver;
  private subscription$: Subscription = new Subscription();

  sidebarOpen: boolean = false;

  constructor(
    private elementRef: ElementRef,
    private vcr: ViewContainerRef,
    private injector: Injector,
    private renderer: Renderer2,
    private headerService: HeaderService,
  ) {}

  ngOnInit() {
    this.observer = new MutationObserver(() => {
      if (this.calendar && this.calendar.getApi()) {
        this.calendar.templateMap['eventContent'] = this.eventContent;
        this.calendarObject.emit(this.calendar);
        this.observer.disconnect();
      }
    });
    this.observer.observe(this.elementRef.nativeElement, {
      attributes: false,
      childList: true,
      characterData: false,
      subtree: false
    });

    if (this.small) {
      this.subscription$ = this.headerService.sidebarOpen.subscribe({
        next: (sidebarOpen: boolean) => {
          this.sidebarOpen = sidebarOpen;

          const titleElement = document.getElementById('fc-dom-1') ?? document.getElementById('fc-dom-86');
          if (titleElement) {
            setTimeout(() => {
              titleElement?.classList.toggle('sidebar-open', this.sidebarOpen);
            }, this.sidebarOpen ? 100 : 300);
          }
        },
      })
    }
  }

  ngOnDestroy() {
    if (this.observer) {
      this.observer.disconnect();
    }
    if (this.subscription$) {
      this.subscription$.unsubscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges) {

    if (this.componentRef && this.calendar.getApi()){

      if (changes['currDayGrid'] && this.currDayGrid !== undefined) {

        if (this.currDayGrid === "dayGridMonth") {
          const dropdownDateValue =  this.componentRef.instance.selectedValue;
          this.onMonthChange({
            month: dropdownDateValue.getMonth(),
            year: dropdownDateValue.getFullYear()
          })
        }

        this.componentRef.changeDetectorRef.detectChanges();
      }

      if (changes['calendarCurrentMonth'] && this.calendarCurrentMonth != undefined && this.currDayGrid === "dayGridMonth") {
        const calendarDateValue = this.calendar.getApi().getDate()
        const dropdownDateValue =  this.componentRef.instance.selectedValue;

        if (
          calendarDateValue.getFullYear() === dropdownDateValue.getFullYear() &&
          calendarDateValue.getMonth() === dropdownDateValue.getMonth() &&
          calendarDateValue.getDate() === dropdownDateValue.getDate()
          ) {}
        else {
          this.componentRef.instance.selectedValue = calendarDateValue;
        }
      }

      if (
        changes['calendarCurrentWeek'] &&
        this.calendarCurrentWeek != undefined &&
        this.currDayGrid === "dayGridWeek"
      ) {
        const startWeekDayDate = new Date(this.calendarCurrentWeek.start);
        const endWeekDayDate = new Date(this.calendarCurrentWeek.end);
        const currentMonthDropdown = new Date(this.componentRef.instance.selectedValue);

        if (startWeekDayDate.getMonth() === endWeekDayDate.getMonth()) {
          if (currentMonthDropdown.getMonth() === startWeekDayDate.getMonth()) {
            return;
          }

          if (currentMonthDropdown.getMonth() === endWeekDayDate.getMonth() + 1) {
            currentMonthDropdown.setMonth(endWeekDayDate.getMonth());
          }

          if (currentMonthDropdown.getFullYear() === endWeekDayDate.getFullYear() + 1) {
            currentMonthDropdown.setFullYear(currentMonthDropdown.getFullYear() - 1);
            currentMonthDropdown.setMonth(endWeekDayDate.getMonth());
          }

          this.componentRef.instance.selectedValue = currentMonthDropdown;

        } else if (startWeekDayDate.getMonth() + 1 === endWeekDayDate.getMonth()) {
          if (currentMonthDropdown.getMonth() !== endWeekDayDate.getMonth()) {
            currentMonthDropdown.setMonth(endWeekDayDate.getMonth());
            this.componentRef.instance.selectedValue = currentMonthDropdown;
          }

        } else {
          if (currentMonthDropdown.getFullYear() + 1 === endWeekDayDate.getFullYear()) {
            currentMonthDropdown.setMonth(endWeekDayDate.getMonth());
            currentMonthDropdown.setFullYear(endWeekDayDate.getFullYear());
            this.componentRef.instance.selectedValue = currentMonthDropdown;
          }
        }

        this.componentRef.changeDetectorRef.detectChanges();
      }
    } else return;

  }

  private getToolbarChunk(): HTMLElement | null {
    return document.querySelector('.fc-header-toolbar .fc-toolbar-chunk:first-child');
  }

  // Method to call on a reference of this component to place
  // a month dropdown in referenced instance of CalendarComponent
  addMonthSelectionDropdown(): void {
    if (this.small) {
      const titleElement = document.getElementById('fc-dom-1') ?? document.getElementById('fc-dom-86');
      if (titleElement) {
        titleElement?.classList.toggle('sidebar-open', this.sidebarOpen);
      }
    }

    const toolbarChunk = this.getToolbarChunk();

    if (!toolbarChunk) {
      console.error('Calendar Header Toolbar chunk not found. Cannot add month dropdown.');
      return;
    }

    if (!document.getElementById('monthDropdown')) {

      const container = this.renderer.createElement('div');
      this.renderer.setAttribute(container, 'id', 'monthDropdown');
      this.renderer.addClass(container, 'p-dropdown-container');
      this.renderer.addClass(container, 'w-12rem');

      this.renderer.appendChild(toolbarChunk, container);
      if (!this.componentRef) {
        this.componentRef = this.vcr.createComponent(CalendarMonthDropdownComponent, {
          injector: this.injector
        });
      }

      this.componentRef.instance.onChange.subscribe((selectedOption: any) => {

        if (this.currDayGrid === "dayGridWeek") {

          const currentDate = new Date(this.componentRef.instance.selectedValue)
          const options = this.componentRef.instance.options;
          const selectedOption = options.find(option => {
            const compareDate = new Date(option.date);
            if (
                currentDate.getFullYear() === compareDate.getFullYear() &&
                currentDate.getMonth() === compareDate.getMonth() &&
                currentDate.getDate() === compareDate.getDate()
                )
              {
                return option
              }
          })

          if (selectedOption) {
            this.onMonthChange({
              month: selectedOption.date.getMonth(),
              year: selectedOption.date.getFullYear(),
            });
          }

        } else if (this.currDayGrid === "dayGridMonth") {

          this.onMonthChange({
            month: selectedOption.date.getMonth(),
            year: selectedOption.date.getFullYear(),
          });
        }
      });

      this.vcr.insert(this.componentRef.hostView);
      this.renderer.appendChild(container, (this.componentRef.hostView as any).rootNodes[0]);
    }
  }

  private onMonthChange({ month, year }: { month: number; year: number }): void {
    if (this.calendar && this.calendar.getApi()) {
      if (isNaN(month)) {
        console.error('Invalid month selected');
        return;
      }

      const calendarApi = this.calendar.getApi();
      calendarApi.gotoDate(new Date(year, month, 1));

    }
  }

}
