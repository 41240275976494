import { Component, Input, signal, TemplateRef, ViewChild } from '@angular/core';
import { CalendarOptions, EventContentArg, EventInput } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { PlanTimeType } from "@core/enums/plan-type.enum";
import { IPerson } from "@core/interfaces/person.interface";
import { IPlanTime } from "@core/interfaces/plan-time.interface";
import { FullCalendarComponent } from "@fullcalendar/angular";
import { Router } from "@angular/router";
import { DynamicDialogRef } from "primeng/dynamicdialog";
import { DatePipe } from "@angular/common";
import { TimeFormatPipe } from "@shared/pipes/time-format.pipe";
import { PlanTimeService } from "@core/services/plan-time.service";
import { OverlayPanel } from "primeng/overlaypanel";
import { IPersonService } from "@core/interfaces/person-service.interface";
import { IFinancialSource } from "@core/interfaces/financial-source.interface";
import { AbsenceReason, AbsenceReasonName } from "@core/enums/absence-reason";
import { CalendarComponent } from "@shared/components/calendar/calendar.component";

@Component({
  selector: 'medis-plan-time-calendar',
  templateUrl: './absence-plan-time-calendar.component.html',
  styleUrl: './absence-plan-time-calendar.component.scss',
  providers: [TimeFormatPipe],
})
export class AbsencePlanTimeCalendarComponent {
  @Input() person!: IPerson;
  planTimes: IPlanTime[] = [];
  providers: IPerson[] = [];

  calendar: FullCalendarComponent | null = null;
  calendarResponse: any | null = null;
  calendarTimes: EventInput[] = [];
  eventGuid = 0;
  calendarPlanTime: any = null;
  finSources: IFinancialSource[] | null = null;
  @ViewChild('eventContent') eventContent!: TemplateRef<any>;
  @ViewChild('overlay') overlay!: OverlayPanel;
  @ViewChild('medisCalendar') medisCalendar!: CalendarComponent;

  createEventId() {
    return String(this.eventGuid++);
  }

  constructor(public ref: DynamicDialogRef,
              private router: Router,
              private datePipe: DatePipe,
              private timeFormat: TimeFormatPipe,
              private planTimeService: PlanTimeService,
  ) {
  }

  hoveredEvent: any = null;
  currentHoveredEventId: string | null = null;

  private handleEventMouseEnter(event: any, mouseEvent: MouseEvent) {
    this.currentHoveredEventId = event.id;
    this.hoveredEvent = event;

    setTimeout(() => {
      if (this.currentHoveredEventId === event.id) {
        this.overlay.show(mouseEvent);
      }
    }, 350);
  }

  private handleEventMouseLeave(): void {
    this.currentHoveredEventId = null;
    this.hoveredEvent = null;
    this.overlay.hide();
  }

  currentDayGrid: string = "dayGridMonth";
  calendarCurrentMonth: Date | undefined = undefined;
  calendarCurrentWeek: {start: Date; end: Date} | undefined = undefined;

  handleCalendarDateChange(arg?: any) {
    if(this.calendar) {

      if (arg.view.type === "dayGridWeek") {
        this.calendarCurrentWeek = {start: arg.start, end: arg.end};
      } else if (arg.view.type === "dayGridMonth") {
        this.calendarCurrentMonth = arg
      }

      if (this.currentDayGrid != arg.view.type) {
        this.currentDayGrid = arg.view.type
      }
    }
  }

  private getStartDate(): string {
    const currentDate = new Date();
    const startDate: Date = new Date(currentDate);
    startDate.setMonth(currentDate.getMonth() - 12);

    return startDate.toISOString().split('T')[0]
  }

  private getEndDate(): string {
    const currentDate = new Date();
    const endDate: Date = new Date(currentDate);
    endDate.setMonth(currentDate.getMonth() + 12);

    return endDate.toISOString().split('T')[0]
  }

  calendarOptions = signal<CalendarOptions>({
    plugins: [dayGridPlugin, interactionPlugin],
    headerToolbar: {
      left: 'prev,next',
      center: 'title',
      right: 'dayGridMonth,dayGridWeek',
    },
    firstDay: 1,
    dayHeaderFormat: { weekday: 'short' },
    locale: 'et',
    initialView: 'dayGridMonth',
    weekends: true,
    validRange: {
      start: this.getStartDate(),
      end: this.getEndDate(),
    },
    eventClick: this.handleEventClick.bind(this),
    events: this.calendarTimes,
    eventContent: this.eventContent,
    datesSet: (dateInfo) => {
      this.handleCalendarDateChange(dateInfo);
      this.fetchCalendarTimes();
    },
    eventDidMount: (info) => {
      // Allowing for overlay panel display on event hover
      info.el.addEventListener('mouseenter', (event) => {
        this.calendarPlanTime = info.event.extendedProps['data'];
        this.finSources = this.getUniqueFinancialSources(this.calendarPlanTime);
        this.handleEventMouseEnter(info.event, event);
      });
      info.el.addEventListener('mouseleave', () => {
        this.calendarPlanTime = null;
        this.finSources = null;
        this.handleEventMouseLeave();
      });
    },
    dayCellClassNames: renderProps => {
      if (!this.calendarResponse) return '';
      const propDate = this.datePipe.transform(renderProps.date, 'yyyy-MM-ddTHH:mm:ss');
      if (propDate) {
        if (Object.keys(this.calendarResponse).includes(propDate)) {
          const eventsForDate = this.calendarResponse[propDate];
          if (eventsForDate.length >= 5) {
            return 'event-full';
          }
        }
      }
      return '';
    },
    eventClassNames: (arg: EventContentArg) => {
      const eventContentProps = arg.event.extendedProps['data'];
      if (eventContentProps.absenceId) {
        return ["absenceEvent"];
      } else if (eventContentProps.planTimeType === 2) {
        return ["basicEvent"];
      } else if (eventContentProps.planTimeType === PlanTimeType.Draft) {
        return ["nonActiveEvent"];
      } else if (eventContentProps.planTimeType === PlanTimeType.Rest) {
        return ["lunchEvent"];
      } else return ''
    },
    buttonText: {
      today: 'Täna',
      month: 'Kuu',
      week: 'Nädal',
      day: 'Päev',
    },
  });

  /**
   * Extract unique IFinancialSource[] from IPlanTime
   * @param planTime
   */
  private getUniqueFinancialSources(planTime: IPlanTime): IFinancialSource[] {
    const uniqueSourcesMap = new Map<number, IFinancialSource>();
    planTime.personServices.forEach((personService: IPersonService) => {
      personService.service?.financialSources.forEach(financialSource => {
        if (!uniqueSourcesMap.has(financialSource.financialSourceId)) {
          uniqueSourcesMap.set(financialSource.financialSourceId, financialSource);
        }
      });
    });
    return Array.from(uniqueSourcesMap.values());
  }

  private handleEventClick(event: any): void {
    let data = event.event.extendedProps['data'];
    if (data && data.personId && data.date) {
      let time = new Date(data.date).getTime();
      this.router.navigate(
        ['/doctors', data.personId, 'plan-time'],
        {
          queryParams: {
            createPlanTime: true,
            date: time,
          }
        });
    }
  }

  fetchCalendarTimes(): void {
    if (!this.calendar || !this.calendar.getApi()) return;

    this.calendarResponse = null;
    this.calendarTimes = [];
    let dateRange = this.calendar.getApi().getCurrentData().dateProfile.activeRange;
    const startDate = dateRange?.start
    const endDate = dateRange?.end

    if (startDate && endDate) {
      const dateFrom = new Date(startDate)
      const dateUpto = new Date(endDate)
      const finalDateFrom = this.datePipe.transform(dateFrom, 'yyyy-MM-dd')!;
      const finalDateUpto = this.datePipe.transform(dateUpto, 'yyyy-MM-dd')!;

      this.planTimeService.getCalendarPlanTimes(this.person.personId, finalDateFrom, finalDateUpto)
        .subscribe({
          next: (response) => {
            this.calendarResponse = response;
            this.calendarTimes = Object.keys(response).flatMap(dateKey => {
              return response[dateKey].map((calendarPlanTime: any) => {
                return {
                  id: this.createEventId(),
                  title: this.timeFormat.transform(calendarPlanTime.timeFrom) + '-' + this.timeFormat.transform(calendarPlanTime.timeUpto) + ' ' + this.createPostfix(calendarPlanTime),
                  allDay: false,
                  start: this.getDateTime(dateKey, calendarPlanTime.timeFrom),
                  end: this.getDateTime(dateKey, calendarPlanTime.timeUpto),
                  extendedProps: {
                    data: calendarPlanTime,
                  },
                }
              });
            });
          },
          complete: () => {
            if (this.calendar) {
              this.calendar.getApi().render();
              this.calendar.events = this.calendarTimes;
              this.calendar.getApi().refetchEvents();
              this.medisCalendar.addMonthSelectionDropdown();
            }

          }
        })
    }
  }

  private getDateTime(date: string, timeFrom: string): Date {
    const time = timeFrom.split(':');
    let dateTime = new Date(date);
    dateTime.setHours(Number(time[0]), Number(time[1]), Number(time[2]));
    return dateTime;
  }

  private createPostfix(calendarPlanTime: any): string {
    if (!calendarPlanTime.planTimeId) {
      return ''
    }
    if (calendarPlanTime.absenceId) {
      return AbsenceReasonName[calendarPlanTime.absenceReasonId as AbsenceReason] || 'Puudumine';
    } else if (calendarPlanTime.planTimeType === PlanTimeType.Rest) {
      return 'Lõunapaus'
    } else if (calendarPlanTime.planTimeType === PlanTimeType.WorkIncludeServices) {
      const services = calendarPlanTime.planTimePersonServicesCount;
      const roomCodeName = calendarPlanTime.room?.codeName ?? '';
      return `${roomCodeName} (${services})`
    } else {
      return '';
    }
  }
}
