<div class="flex flex-column gap-4">
  <div class="flex row w-full gap-1">
    <div class="grid grid-nogutter gap-4 w-full">
      <div class="col w-full" [formGroup]="form" role="form">
        <p-floatLabel>
          <p-dropdown
            id="select-room"
            styleClass="w-full"
            [options]="roomOptions"
            optionValue="value"
            optionLabel="label"
            appendTo="body"
            formControlName="roomId"
          />
          <ng-template let-room pTemplate="selectedItem">
                <span class="room-selected">
                  {{ room.type }} - {{ room.label }}
                </span>
          </ng-template>
          <ng-template let-room pTemplate="item">
                <span class="room-item">
                  {{ room.type }} - {{ room.label }}
                </span>
          </ng-template>
          <label for="select-room">Ruum</label>
        </p-floatLabel>
      </div>
    </div>
  </div>
</div>
