import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class HeaderService {
  readonly sidebarOpen$ = new BehaviorSubject<boolean>(false);

  constructor() { }

  updateSidebarOpen(open: boolean): void {
    this.sidebarOpen$.next(open);
  }

  get sidebarOpen(): Observable<boolean> {
    return this.sidebarOpen$.asObservable();
  }
}
