export enum ServiceType {
  COMBO_SERVICE = 1, //Teenusepakett
  FULL_SERVICE = 2, //Kompleksteenus
  IN_COMBO_ONLY = 3, //Kulub ainult paketti
  SERVICE = 0,
  PRODUCT = 4,
  TRAINING = 14,
  GOOD = 15,
  SPECIAL_SERVICE = 16,
}
