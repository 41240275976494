<p-table [value]="bookings">
  <ng-template pTemplate="header">
    <tr>
      <th>Kuupäev</th>
      <th>Aeg</th>
      <th>Osutaja</th>
      <th>Maksja</th>
      <th>Teenus</th>
      <th>Staatus</th>
      <th>Aadress</th>
      <th>Ruum</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-item>
    <tr>
      <td>
        {{ item.date | dateFormat }}
      </td>
      <td>{{ item.timeFrom }} - {{ item.timeUpto }}</td>
      <td>{{ item.fullName }}</td>
      <td>
        <medis-financial-source-label [financialSourceId]="item.financialSourceId"></medis-financial-source-label>
      </td>
      <td>
        <div class="flex gap-2 align-items-center">
          <medis-service-badge [badges]="item.serviceBadges"></medis-service-badge>
          {{ item.serviceName }}
        </div>
      </td>
      <td>
        <medis-order-service-status-label [status]="item.orderServiceQueueStatus ?? item.orderServiceTimeStatus"></medis-order-service-status-label>
      </td>
      <td>
        {{ item.room.roomDepartment }}
      </td>
      <td>
        {{ item.room.codeName }}
      </td>
    </tr>
  </ng-template>
</p-table>
<div class="flex row justify-content-end py-4 gap-2">
  <p-button label="Sulge" [outlined]="true" severity="secondary" (click)="closeModal()"/>
</div>
