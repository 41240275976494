@if (!loading) {
  @if (displayDefaultFilters) {
    <div class="dashboard-filter flex flex-column gap-3 mb-4">
      <div class="dashboard-topbar__top row flex gap-4 align-items-center justify-content-between">
        <div class="dashboard-search flex gap-2 align-items-center">
          <p-iconField iconPosition="left">
            <p-inputIcon styleClass="pi pi-search"/>
            <input
              type="text"
              id="search"
              pInputText
              placeholder="Otsi"
              [(ngModel)]="searchTerm"
              (input)="applyFilterGlobal($event)"
              style="width: 28rem"
            />
          </p-iconField>
        </div>
        <p-button [label]="addButtonLabel" class="ml-auto" routerLink="create"/>
      </div>
    </div>
  }
  <p-table
    #dt
    [value]="data"
    [paginator]="paginator"
    [rows]="10"
    [showCurrentPageReport]="true"
    [globalFilterFields]="filterFields"
    [sortField]="columns[0].name"
    [rowsPerPageOptions]="[10, 25, 50]"
    [rowHover]="rowHover"
    currentPageReportTemplate="Kuvan {first} kuni {last} kokku {totalRecords} kirjet"
    class="table table-products"
  >
    <ng-template pTemplate="header">
      <tr>
        @for (column of columns; track column) {
          <th [pSortableColumn]="column.name" [style.width]="column.width ?? 'auto'">
            <div>
              {{ column.title }}
              <p-sortIcon [field]="column.name"/>
            </div>
          </th>
        }
        @if (buttonColumns) {
          <th></th>
        }
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-row>
      <tr [ngClass]="rowNgClass(row)">
        @for (col of columns; track col) {
          <td>
            @if (col.idKey) {
              <div class="flex row gap-2">
                <medis-service-badge [badges]="row.serviceBadges"></medis-service-badge>
                <a class="text-primary service-link flex row gap-2"
                   [routerLink]="createHref(row, col)">{{ row[col.name] }}
                </a>
              </div>
            } @else if (col.isDate) {
              {{ row[col.name] | dateTimeDisplay: 'dd.MM.YYYY' }}
            } @else if (col.isDateTime) {
              {{ row[col.name] | dateTimeDisplay: 'dd.MM.YYYY HH:mm' }}
            } @else if (col.isArray && col.nextName) {
              <div class="flex flex-column gap-2">
                @for (c of row[col.name]; track $index) {
                  <div>{{ c[col.nextName] }}</div>
                }
              </div>
            } @else if (Array.isArray(row[col.name])) {
              {{ row[col.name] | arrayToString: col.name }}
            } @else if (isBoolean(row[col.name])) {
              {{ row[col.name] ? 'Jah' : 'Ei' }}
            } @else if (col.componentRef) {
              <ng-container *ngComponentOutlet="col.componentRef; inputs: getDynamicInputs(row, col)"
              ></ng-container>
            } @else if (col.templateRef) {
              <ng-container *ngTemplateOutlet="col.templateRef; context: { $implicit: row, col: col }"></ng-container>
            } @else {
              {{ row[col.name] }} {{ (row[col.name] !== null && row[col.name] !== undefined) && col.postfix ? col.postfix : '' }}
            }
          </td>
        }
        @if (buttonColumns) {
          <td>
            <div class="flex justify-content-end gap-2 text-primary">
              @for (button of buttonColumns; track button) {
                @if (button.action) {
                  <p-button [label]="button.title"
                            iconPos="right"
                            [icon]="button.icon"
                            (click)="button.action(row)"
                            [text]="true"
                            [rounded]="true"
                            [disabled]="!permissionsService.hasPermission(button.permission)"
                            [pTooltip]="permissionsService.getNoPermissionText(button.permission)"
                            severity="secondary"
                            class="btn-disabled"
                  ></p-button>
                } @else if (button.componentRef) {
                  <!-- Displaying some component, initially meant for OrderActionsComponent -->
                  <ng-container *ngComponentOutlet="button.componentRef; inputs: button.componentRefInputs(row)"
                  ></ng-container>
                } @else if (button.actionTree?.length) {
                  <!-- Displaying multiple actions as p-menu options -->
                  <p-button [label]="button.title"
                            iconPos="right"
                            [icon]="button.icon"
                            (click)="toggleActions($event, menu, row, button.actionTree)"
                            [text]="true"
                            [rounded]="true"
                            severity="secondary"
                  />
                  <!-- class="absolute" is required to take p-menu container out of document flow -->
                  <p-menu #menu [popup]="true"
                          appendTo="body"
                          [model]="actions"
                          class="absolute"
                          styleClass="more-menu bg-white"
                  />
                }
              }
            </div>
          </td>
        }
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td [attr.colspan]="columns.length + (buttonColumns?.length ? buttonColumns!.length : 0)">Midagi pole leitud.
        </td>
      </tr>
    </ng-template>
  </p-table>
} @else {
  <div class="flex flex-column gap-4">
    <div class="flex justify-content-between">
      <p-skeleton width="24rem" height="2.5rem"></p-skeleton>
      <p-skeleton width="8rem" height="2.5rem"></p-skeleton>
    </div>
    <p-skeleton width="100%" height="24rem"></p-skeleton>
    <p-skeleton width="100%" height="3rem"></p-skeleton>
  </div>
}
