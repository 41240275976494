import { Component, Input } from '@angular/core';
import { IMapping } from "@core/interfaces/mapping.interface";
import { BadgeType } from "@core/enums/badge-type.enum";
import { IServiceBadge } from "@core/interfaces/service-badge.interface";

@Component({
  selector: 'medis-service-badge',
  template: `
    <div class="flex row gap-1 overflow-visible">
      @for (badge of badges; track badge) {
        <p-badge [value]="badge.title"
                 [styleClass]="statusStyleMap[badge.type]"
                 class="flex overflow-visible"
        />
      }
    </div>
  `,
  styles: [
    `:host {
      overflow: visible !important;
    }`
  ],
})

export class ServiceBadgeComponent {
  public statusStyleMap: IMapping = {
    [BadgeType.INFO]: 'bg-blue-500',
    [BadgeType.WARNING]: 'bg-orange-500',
    [BadgeType.YELLOW]: 'bg-yellow-500',
    [BadgeType.PURPLE]: 'bg-purple-500',
    [BadgeType.DANGER]: 'bg-red-500',
    [BadgeType.PRIMARY]: 'bg-blue-500',
    [BadgeType.DEFAULT]: 'bg-default-500',
    [BadgeType.SUCCESS]: 'bg-green-500'
  };

  @Input() badges: IServiceBadge[] = [];
}
