import { Component } from '@angular/core';
import { Button } from "primeng/button";
import { DialogModule } from "primeng/dialog";
import { MessagesModule } from "primeng/messages";
import { NgForOf } from "@angular/common";
import { Message, PrimeTemplate } from "primeng/api";
import { TableModule } from "primeng/table";
import { TagModule } from "primeng/tag";
import { ScrollPanelModule } from "primeng/scrollpanel";
import { IOrderServiceLog } from "@core/interfaces/order-service-log.interface";
import { DialogService, DynamicDialogComponent, DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { DateTimePipe } from "@shared/pipes/datetime.pipe";
import { StatusInfo } from "@core/interfaces/status-info.interface";
import { OrderServiceStatus } from "@core/enums/order-service-status.enum";
import { IOrderServiceNotificationLog } from "@core/interfaces/order-service-notification-log.interface";
import { OrderHistoryMessage } from "@core/enums/order-history-message.enum";
import { ReferralStatusEnum } from "@core/enums/referral-status.enum";
import { InvoiceStatus } from "@core/enums/invoice-status.enum";

@Component({
  selector: 'app-modal-table',
  standalone: true,
  imports: [
    Button,
    DialogModule,
    MessagesModule,
    NgForOf,
    PrimeTemplate,
    TableModule,
    TagModule,
    ScrollPanelModule,
    DateTimePipe
  ],
  templateUrl: './table-history.component.html',
  styleUrls: ['./table-history.component.scss']
})
export class TableHistoryComponent {
  orderServiceLogs: IOrderServiceLog[];
  orderServiceNotificationLogs: IOrderServiceNotificationLog[];
  orderServiceInvoiceLogs: IOrderServiceLog[] = [];
  instance: DynamicDialogComponent | undefined;

  statusMapping: { [key: string]: StatusInfo } = {
    [OrderServiceStatus.CREATED]: { class: 'tag--blue', name: 'LOODUD' },
    [OrderServiceStatus.PREPARING]: { class: 'tag--yellow', name: 'TELLITUD' },
    [OrderServiceStatus.CANCELLED]: { class: 'tag--default', name: 'TÜHISTATUD' },
    [OrderServiceStatus.EXPIRED]: { class: 'tag--default', name: 'AEGUNUD' },
    [OrderServiceStatus.CONFIRMED]: { class: 'tag--purple', name: 'BRONEERITUD' },
    [OrderServiceStatus.PRESENT]: { class: 'tag--green', name: 'PATSIENT KOHAL' },
    [OrderServiceStatus.REALIZATION]: { class: 'tag--orange', name: 'TEOSTAMISEL' },
    [OrderServiceStatus.UNFULFILLED]: { class: 'tag--gray', name: 'TÄITMATA' },
    [OrderServiceStatus.FINISHED]: { class: 'tag--default', name: 'LÕPETATUD' },
    [OrderServiceStatus.CHANGED]: { class: 'tag--default', name: 'MUUDETUD' },
    [OrderServiceStatus.IN_PROVIDER_QUEUE]: { class: 'tag--green', name: 'LISANUMBER' },
    [OrderServiceStatus.IN_SERVICE_QUEUE]: { class: 'tag--green', name: 'LISANUMBER' },
    [OrderServiceStatus.BOOKING_NOT_NEEDED]: { class: 'tag--info', name: 'EI VAJA BRONEERINGUT' },
    [OrderServiceStatus.NOT_APPEARED]: { class: 'tag--dark', name: 'EI ILMUNUD' },
    [OrderServiceStatus.ABSENCE]: { class: 'tag--red', name: 'ANNULLEERITUD' },

    [ReferralStatusEnum.CREATED]: { class: 'tag--yellow', name: 'SAATEKIRI SALVESTATUD' },
    [ReferralStatusEnum.CONFIRMED]: { class: 'tag--green', name: 'SAATEKIRI SEOTUD' },
  };


  //to do classes with relevant tags styles
  invoiceStatusMapping: { [key: string]: StatusInfo } = {
    [InvoiceStatus.REQUIRED]: { class: 'tag--red', name: 'OOTAB MAKSMIST'},
    [InvoiceStatus.CREATED]: { class: 'tag--purple', name: 'SUUNATUD KASSASSE'},
    [InvoiceStatus.CANCELLED]: { class: 'tag--red', name: 'OOTAB MAKSMIST'},
    [InvoiceStatus.PAYMENT_LINK_SENT]: { class: 'tag--blue', name: 'MAKSELINK SAADETUD'},
    [InvoiceStatus.PREPAID_FAILED]: { class: 'tag--default', name: 'ARVE LOOMINE EBAÕNNESTUS'},
    [InvoiceStatus.COMPLETED]: { class: 'tag--green', name: 'MAKSTUD'},
    [InvoiceStatus.PREPAID]: { class: 'tag--yellow', name: 'ARVE LOODUD'},
    [InvoiceStatus.REFUND]: { class: 'tag--info', name: 'MAKSE TAGASTUS'},
  };


  constructor(public config: DynamicDialogConfig, private ref: DynamicDialogRef, private dialogService: DialogService) {
    this.orderServiceLogs = this.config.data?.orderServiceLogs || [];
    this.orderServiceNotificationLogs = this.config.data?.orderServiceNotificationLogs || [];
    this.orderServiceInvoiceLogs = this.config.data?.orderServiceInvoiceLogs || [];
    this.instance = this.dialogService.getInstance(this.ref);
  }

  closeModal(): void {
    this.instance?.close();
  }

  getStatusInfo(status: string | undefined): StatusInfo {
    if (!status) {
      return { class: '', name: '' };
    }

    return this.statusMapping[parseInt(status)] || this.statusMapping[status] || { class: '', name: '' };
  }

  getInvoiceStatusInfo(status: string | undefined): StatusInfo {
    if (!status) {
      return { class: '', name: '' };
    }
    return this.invoiceStatusMapping[status] || { class: '', name: '' };
  }

  orderServiceLogMessage: Message[] = [
    {summary: OrderHistoryMessage.ORDER_STATUS_HISTORY},
  ];

  orderServiceNotificationMessage: Message[] = [
    {summary: OrderHistoryMessage.NOTIFICATION_HISTORY}
  ];
}
