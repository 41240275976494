@if (!loadingVal) {
  <p-table [value]="data" dataKey="orderService.orderServiceId"
           [expandedRowKeys]="expandedRows"
           (onRowExpand)="onRowExpand($event)"
           (onRowCollapse)="onRowCollapse($event)"
           [columns]="columns"
           [paginator]="data.length > 10"
           [rows]="10"
  >
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th class="first-col">Tellimus</th>
        @for (col of columns; track col) {
          <th [style.width]="col.width">{{ col.name }}</th>
        }
        @if (actions) {
          <th class="last-col"></th>
        }
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-order let-expanded="expanded" let-columns="columns">
      <tr [class.expanded-background]="expanded">
        <td class="first-col">
          <div class="flex align-items-center">
            @if (order.orderService.orderServiceTimes?.length > 0 || order.orderService.orderServiceQueues?.length > 0) {
              <p-button
                type="button"
                [pRowToggler]="order"
                [text]="true"
                [rounded]="true"
                [plain]="true"
                [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
              ></p-button>
            } @else {
              <div class="expand-button-placeholder"></div>
            }
            <div class="ml-2 flex flex-column" [style.gap.px]="6">
              <p class="m-0">{{ order.orderService.orderId }}</p>
              <span class="text-sm">{{ order.orderService.created | dateFormat }}</span>
            </div>
          </div>
        </td>

        @for (col of columns; track col) {
          <td [style.width]="col.width ?? 'auto'">
            @if (loadingVal) {
              <p-skeleton></p-skeleton>
            }
            @switch (col.name) {
              @case (OrderColumnName.PATIENT) {
                <div class="flex flex-column gap-2">
                  @if (patientClickable) {
                    <a routerLink="/patients/{{order.order.patient.patientId}}/details"
                       class="text-primary patient-link">{{ order.order.patient.fullName }}
                    </a>
                  } @else {
                    {{ order.order.patient.fullName }}
                  }
                  <medis-copyable-id-code [idCode]="order.order.patient.idCode"/>
                </div>
              }
              @case (OrderColumnName.SUBSCRIBER_PROVIDER) {
                {{ (order.order.provider?.lastName ?? '') + ' ' + (order.order.provider?.firstName ?? '') }}
              }
              @case (OrderColumnName.FIN_SOURCE) {
                @if (order.orderService.financialSourceName) {
                  <medis-financial-source-label
                    [financialSourceId]="order.orderService.financialSourceId ?? order.orderService.financialSourceName"></medis-financial-source-label>
                }
              }
              @case (OrderColumnName.SERVICE) {
                <div class="flex gap-2 align-items-center">
                  <medis-service-badge [badges]="order.service.serviceBadges"></medis-service-badge>
                  {{ order.service.name }}
                  @if (order.orderService.commentsCount && order.orderService.commentsCount > 0) {
                    <p-button [rounded]="true"
                              icon="pi pi-comment"
                              [text]="true"
                              severity="secondary"
                              (click)="openCommentsByOrder(order.orderService)"/>
                  }
                </div>
              }
              @case (OrderColumnName.DESCRIPTION) {
                <!-- here goes Order Description-->
              }
              @case (OrderColumnName.STATUS) {
                @if (!(order.orderService.orderServiceTimes.length > 0 || order.orderService.orderServiceQueues.length > 0)) {
                  <medis-order-service-status-label
                    [status]="order.orderService.orderServiceTimeStatus ?? order.orderService.orderServiceStatus"
                    [invoiceStatus]="order.orderService.invoiceStatus">
                  </medis-order-service-status-label>
                }
              }
              @case (OrderColumnName.CHANNEL) {
                {{ getChannelName(order.order.channelId) }}
              }
              @case (OrderColumnName.REFERRAL_STATUS) {
                <div class="flex flex-column gap-1">
                  @if (order.orderService.referralStatus !== "NotAllowed") {
                    <medis-referral-status-label
                      [referralStatus]="order.orderService.referralStatus"></medis-referral-status-label>
                  }
                </div>
              }
              @case (OrderColumnName.REFERRAL_NR) {
                <span class="text-base font-semibold text-center">{{ order.orderService.referralNumber }}</span>
              }
              @case (OrderColumnName.REFERRAL) {
                <div class="flex flex-column">
                  <p class="m-0">{{ order.orderService.referralAuthorName }}</p>
                  <p class="m-0">{{ order.orderService.referralSourceCompanyName }}</p>
                </div>
              }
              @case (OrderColumnName.RESERVATION_CHANNEL) {
                <div class="flex flex-column">
                  <p class="m-0">{{ order.order.channelId | channelName }}</p>
                </div>
              }
              @case (OrderColumnName.TEHIK_STATUS) {
                <div class="flex flex-column">
                  <p class="m-0">
                    <!-- tehik status -->
                  </p>
                </div>
              }
            }
          </td>
        }

        @if (actions) {
          <td class="last-col">
            <div class="flex justify-content-end">
              <medis-order-actions [orderService]="order.orderService"/>
            </div>
          </td>
        }
      </tr>
    </ng-template>
    <ng-template pTemplate="rowexpansion" let-order let-expanded="expanded">
      @for (reservation of (order.orderService.orderServiceTimes!.length > 0)
        ? order.orderService.orderServiceTimes
        : order.orderService.orderServiceQueues; let index = $index; track reservation) {
        <tr class="border-0 expanded-background">
          <td class="first-col"></td>
          @for (col of columns; track col) {
            @switch (col.name) {
              @default {
                @if (col?.expandableContent) {
                  <td [style.width]="col.width">
                    {{ col?.expandableContent(reservation) }}
                  </td>
                } @else {
                  <td [style.width]="col.width"></td>
                }
              }
              @case (OrderColumnName.SERVICE) {
                <td [style.width]="col.width">
                  <div class="flex align-items-center">
                    @if (reservation.orderServiceQueueId) {
                      <p-badge value="L" [pTooltip]="'Lisa number'" severity="success" styleClass="mr-2"/>
                    }
                    <div>
                      Aeg {{ index + 1 }} -
                      <span [innerHTML]="reservation.date | dateTimeDisplay: 'dd.MM.yyyy': true"
                      ></span> &nbsp;
                      {{ reservation?.timeFrom | timeFormat }} - {{ reservation?.timeUpto | timeFormat }}
                      &nbsp;
                      {{ reservation?.roomCode }} &nbsp;
                      {{ reservation?.departmentShort }}
                    </div>
                  </div>
                </td>
              }
              @case (OrderColumnName.STATUS) {
                <td [style.width]="col.width">
                  @if (reservation?.orderServiceTimeStatus || reservation?.orderServiceQueueStatus; as status) {
                    <medis-order-service-status-label
                      [status]="status"
                      [invoiceStatus]="reservation?.invoiceStatus"
                    />
                  }
                </td>
              }
            }
          }
          @if (actions) {
            <td class="last-col">
              <div class="flex justify-content-end">
                <medis-order-actions [time]="reservation" (onTimeChanged)="onTimeChanged($event)"/>
              </div>
            </td>
          }
        </tr>
      }
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td [attr.colspan]="columns.length + 2">
          Midagi pole leitud.
        </td>
      </tr>
    </ng-template>
  </p-table>
} @else {
  <div class="flex flex-column gap-4">
    <p-skeleton width="100%" height="3rem"></p-skeleton>
    <p-skeleton width="100%" height="3rem"></p-skeleton>
    <p-skeleton width="100%" height="3rem"></p-skeleton>
  </div>
}

@if (printableReservations) {
  <div id="print-section" class="hidden">
    @if (data.length) {
      <div class="order-details">
        <h4>Lp. {{ data[0].order.patient?.fullName ?? 'Patsient' }}</h4>
        <p>Olete oodatud:</p>
        <br>
        <br>
      </div>
      <p-table [value]="reservations" [tableStyle]="{ width: '100%' }">
        <ng-template pTemplate="header">
          <tr>
            <th>Kuupäev</th>
            <th>Kellaaeg</th>
            <th>Osutaja</th>
            <th>Teenus</th>
            <th>Asukoht</th>
            <th>Kabinet</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item>
          <tr>
            <td>
              {{ item.date | dateFormat }}
            </td>
            <td>{{ item.range }}</td>
            <td>{{ item.providerName }}</td>
            <td>{{ item.serviceName }}</td>
            <td>{{ item.address }}</td>
            <td>{{ item.roomCode }}</td>
          </tr>
        </ng-template>
      </p-table>
      <br>
      <br>
      @if (data[0].orderService; as orderService) {
        @if (orderService.financialSourceName !== FinancialSource.PERSON){
          <span>
            <sup>*</sup> Ambulatoorse eriarstiabi visiiditasu Eesti Tervisekassa poolt kindlustatud vanemale kui 2aastasele isikule on 5 €.
          </span>
          <br>
        }

        @if (orderService.financialSourceName === FinancialSource.PERSON){
          <span>
            Palume tulla registratuuri 15 min enne Teile broneeritud vastuvõtuaega. Võtke kaasa isikut tõendav dokument.<br>
          </span>
          <br>

          <span>
            <sup>*</sup> Tasu vastavalt Medicumi hinnakirjale.
          </span>
        }
      }
    }
  </div>
}
