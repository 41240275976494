<p-table [value]="logs"
         dataKey="tempId"
         [expandedRowKeys]="expandedRows"
         (onRowExpand)="onRowExpand($event)"
         (onRowCollapse)="onRowCollapse($event)">
  <ng-template pTemplate="header">
    <tr>
      <th></th>
      @for (col of columns; track col) {
        <th [style.width]="col.width">{{ col.name }}</th>
      }
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-item let-expanded="expanded">
    <tr>
      <td class="first-col">
        <div class="flex align-items-center">
          @if (item.planTimeType != 1) {
            <p-button
              type="button"
              [pRowToggler]="item"
              [text]="true"
              [rounded]="true"
              [plain]="true"
              [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
            ></p-button>
          } @else {
            <div class="expand-button-placeholder"></div>
          }
        </div>
      </td>
      @for (col of columns; track col) {
        <td [style.width]="col.width ?? 'auto'">
          @switch (col.name) {
            @case (OrderColumnName.TIME) {
              {{ item.created | DateTime}}
            }
            @case (OrderColumnName.TIME_ROW) {
              {{ item.newTime ? item.newTime : item.oldTime }}
            }
            @case (OrderColumnName.USER) {
              {{ item.createdByIdentifier }}
            }
            @case (OrderColumnName.ACTION) {
              {{ item.action }}
            }
            @case (OrderColumnName.FIELD) {
              {{ item.planTimeType === 1 ? "Lõunapaus" : "Ajarida" }}
            }
            @case (OrderColumnName.OLD_VALUE) {
              {{ item.planTimeType === 1 ?  '' : item.oldTime }}
            }
            @case (OrderColumnName.NEW_VALUE) {
              {{ item.planTimeType === 1 ?  '' : item.newTime }}
            }
          }
        </td>
      }
    </tr>
  </ng-template>
  <ng-template pTemplate="rowexpansion" let-item let-expanded="expanded">
    @for(change of item.changes; track change){
      <tr class="border-0 expanded-background" [class.expanded-background]="expanded">
        <td></td>
        @for (col of columns; track col) {
          @switch (col.name) {
            @default {
              <td [style.width]="col.width"></td>
            }
            @case (OrderColumnName.FIELD) {
              <td>
                {{ change.name }}
              </td>
            }
            @case (OrderColumnName.OLD_VALUE) {
              @if (change.name === 'Teenused'){
                <td>
                  @if (change.oldValue && change.oldValue.length > 0) {
                    @for (service of change.oldValue; track service) {
                      @if (service) {
                        <p class="m-1">{{ service }}</p>
                      }
                    }
                  }
                </td>
              } @else if(change.name === 'Ära kuva välisveebis'){
                <td>
                  @if(isBoolean(change.oldValue)){
                    {{ change.oldValue ? 'Jah' : 'Ei' }}
                  }
                </td>
              } @else{
                <td>
                  {{ change.oldValue || '' }}
                </td>
              }
            }
            @case (OrderColumnName.NEW_VALUE) {
              @if (change.name === 'Teenused'){
                <td>
                  @if (change.newValue && change.newValue.length > 0) {
                    @for (service of change.newValue; track service) {
                      @if (service) {
                        <p class="m-1">{{ service }}</p>
                      }
                    }
                  }
                </td>
              } @else if(change.name === 'Ära kuva välisveebis'){
                <td>
                  @if(isBoolean(change.newValue)){
                    {{ change.newValue ? 'Jah' : 'Ei' }}
                  }
                </td>
              } @else{
                <td>
                  {{ change.newValue || '' }}
                </td>
              }
            }
          }
        }
      </tr>
    }
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td [attr.colspan]="8">Andmed puuduvad</td>
    </tr>
  </ng-template>
</p-table>
<div class="flex row justify-content-end py-4 gap-2">
  <p-button label="Sulge" [outlined]="true" severity="secondary" (click)="closeModal()"/>
</div>
