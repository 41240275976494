import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'medis-calendar-month-dropdown',
  template: `
    <p-dropdown
      [options]="options"
      optionLabel="label"
      optionValue="value"
      [(ngModel)]="selectedValue"
      (onChange)="handleChange($event)"
      styleClass="w-full"
    ></p-dropdown>
  `,
})

export class CalendarMonthDropdownComponent implements OnInit {
  @Input() selectedValue: Date = new Date();
  @Input() currentDateCalendarApi: Date = new Date();
  @Input() isMonthSelected: boolean = true;
  @Output() onChange = new EventEmitter<number>();
  options: any[] = [];

  private estonianMonths = [
    'Jaanuar', 'Veebruar', 'Märts', 'Aprill', 'Mai', 'Juuni',
    'Juuli', 'August', 'September', 'Oktoober', 'November', 'Detsember'
  ];

  ngOnInit(): void {
    this.generateMonthOptions();
  }

  private generateMonthOptions(): void {
    this.options = [];
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();

    for (let i = -12; i <= 12; i++) {
      const date = new Date(currentYear, currentMonth + i, 1);
      const label = `${this.estonianMonths[date.getMonth()]} ${date.getFullYear()}`;
      const value = date;
      const index = i;
      if (i == 0) {
        this.selectedValue = value;
      }
      this.options.push({ label, value, date, index });
    }
  }

  handleChange(event: any): void {
    const selectedOption = this.options.find(o => o.value === event.value);
    if (selectedOption) {
      this.selectedValue = selectedOption.value;
      this.onChange.emit(selectedOption);
    } else {
      console.error("Selected option not found in options list");
    }
  }

}
