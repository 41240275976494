<section class="products-edit container--full flex justify-content-center">
  <div class="container flex flex-column gap-4 py-4">
    <medis-page-header [title]="pageHeader"/>
    @if(patient){
      <medis-order-patient [patient]="patient" (onPatientChange)="updatePatient($event)"/>
    }

      @if(orderDetails){
        <div class="bg-white modal__section border-round-md px-4 pb-2">
          <div class="flex flex-row justify-content-between">
            <h3 class="user-details__section-title text-color-secondary text-xl font-bold">Tellimuse info</h3>
          </div>
          <div class="user-details__section-info flex flex-row" [style]="{gap: '28px'}">
            <div class="flex flex-column w-6">
              @for (item of orderDetails.leftInfo; track item) {
                <div class="user-details__section-field flex flex-row" [ngClass]="{'last-element': $last}">
                  <div class="user-details__section-label">
                    <span><b>{{ item.label }}</b></span>
                  </div>
                  <p class="user-details__section-value m-0 text-color-secondary">{{ item.value }}</p>
                </div>
              }
            </div>
            <div class="flex flex-column w-6">
              @for (item of orderDetails.rightInfo; track item) {
                <div class="user-details__section-field flex flex-row" [ngClass]="{'last-element': $last}">
                  <div class="user-details__section-label">
                    <span><b>{{ item.label }}</b></span>
                  </div>
                  <p class="user-details__section-value m-0 text-color-secondary">{{ item.value }}</p>
                </div>
              }
            </div>
          </div>
        </div>
      }

      <div class="bg-white modal__section border-round-md px-4 pb-4">
        <div class="flex flex-row justify-content-between">
          <h3 class="user-details__section-title text-color-secondary text-xl font-bold">Vastuvõtuinfo</h3>
        </div>

        <div class="col-md-12 mt-2">
          <p-table [value]="serviceItems" [tableStyle]="{'min-width': '50rem'}"
                   styleClass="p-datatable-sm p-datatable-striped">
            <ng-template pTemplate="header">
              <tr>
                <th>Kuupäev</th>
                <th>Kellaaeg</th>
                <th>Osutaja</th>
                <th>Asukoht</th>
                <th>Kabinet</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-item>
              <tr>
                <td>
                  {{ item.date | dateFormat }}
                </td>
                <td>{{ item.range }}</td>
                <td>{{ item.providerName }}</td>
                <td>{{ item.address }}</td>
                <td>{{ item.roomCode }}</td>
              </tr>
            </ng-template>
          </p-table>
        </div>

    </div>
    <div class="form-buttons flex justify-content-between gap-3">
      <span>
        <p-button label="Tagasi" severity="primary" [outlined]="true" icon="pi pi-arrow-left" (click)="back()"/>
      </span>
      <span class="flex row gap-4">
        <p-button
          [class.btn-disabled]="confirmationSent"
          label="Saada kinnitus"
          (click)="sendConfirmation()"
          [disabled]="confirmationSent"
          [pTooltip]="confirmationSent ? confirmationTooltip : ''"
          tooltipPosition="bottom"
          severity="warning"
        />
        <p-button label="Prindi" type="submit" severity="primary" icon="pi pi-print" (click)="print()" [iconPos]="right"/>
      </span>
    </div>


    @if(orderDetails){
      <div id="print-section">
        <div class="header" style="width: 100%; display: flex; justify-content: center; padding-bottom: 0.5rem">
          <img [src]="'/assets/logo/medicum.svg'" alt="Medicum logo" height="120" width="300">
        </div>
        @if((orderService?.orderServiceTimes?.length && orderService?.orderServiceTimes?.[0]?.departmentPhone) ||
        (orderService?.orderServiceQueues?.length && orderService?.orderServiceQueues?.[0]?.departmentPhone)) {
          <div class="phone-section" style="display: flex; justify-content: flex-end">
            <span style="margin-right: 0.1rem;">&#9742;</span>
            Registratuuri telefon:
            {{ orderService?.orderServiceTimes?.[0]?.departmentPhone ?? orderService?.orderServiceQueues?.[0]?.departmentPhone }}
          </div>
        }
        <div style="border-bottom: 2px solid #405C9B; padding: 0.5rem 0"></div>
        <div style="padding-top: 1.25rem; padding-bottom: 1rem; padding-left: 1rem">
          <h4>Lugupeetud {{ patient?.fullName ?? 'Patsient' }}</h4>
          <p>Olete oodatud:</p>
        </div>
        <div class="order-details" style="display: flex; flex-direction: row; gap: 1.5rem; width: 100%">
          <div class="details" style="display: flex; flex-direction: column; gap: 1rem; justify-content: center; width: 70%; padding-left: 1rem">
            <span>
              <b style="color: #405C9B">Tellimuse andmed</b>
            </span>
            <div class="left-info">
              <div class="flex flex-row" style="display: flex; flex-direction: row">
                Teenus <p class="ml-8" style="margin: 0 0 0 2.5rem">{{ orderDetails.leftInfo[1].value }}</p>
              </div>
            </div>
            <div class="right-info">
              <div class="flex flex-row" style="display: flex; flex-direction: row">
                Maksja <p class="ml-8" style="margin: 0 0 0 2.5rem">{{ orderDetails.rightInfo[0].value }}</p>
              </div>
            </div>
          </div>
          @if (patient?.phone || patient?.contact?.phone || patient?.email || patient?.contact?.email) {
            <div class="details" style="display: flex; flex-direction: column; gap: 1rem; justify-content: center; width: 30%">
            <span>
              <b style="color: #405C9B">Tellija andmed</b>
            </span>
              @if (patient?.email || patient?.contact?.email) {
                <div class="left-info">
                  <div class="flex flex-row" style="display: flex; flex-direction: row">
                    <p class="ml-8" style="margin: 0">{{ patient?.email ?? patient?.contact?.email ?? 'E-post' }}</p>
                  </div>
                </div>
              }
              @if (patient?.phone || patient?.contact?.phone) {
                <div class="right-info">
                  <div class="flex flex-row" style="display: flex; flex-direction: row">
                    <p class="ml-8" style="margin: 0">{{ patient?.phone ?? patient?.contact?.phone ?? '' }}</p>
                  </div>
                </div>
              }
            </div>
          }
        </div>
        <br>
        <br>
        <div class="mt-2"></div>
        <br>
        <br>
        <span style="padding-bottom: 2.5rem">
          <b style="color: #405C9B">Lisainfo</b>
          <br>
        </span>
        <br>
        @if(brochureText){
          <span class="form-group mb-4" style="margin-bottom: 1.5rem; white-space: nowrap">
            @for(bText of brochureText; track bText){
              <span style="white-space: nowrap">{{bText}}<br><br></span>
            }
          </span>
          <br>
        }
        @if(orderService && orderService.financialSourceName === FinancialSource.PERSON){
          <span class="form-group mb-4" style="padding-bottom: 1.5rem; white-space: nowrap">
            Palume tulla registratuuri 15 min enne Teile broneeritud vastuvõtuaega. Võtke kaasa isikut tõendav dokument.<br>
          </span>
          <br>
        }
        <span style="padding-bottom: 1.5rem">
          Kui Te ei saa kokkulepitud ajal vastuvõtule tulla, palun teavitage sellest Medicumi vähemalt 24 tundi ette.
        </span>
        <br>
        @if(orderService && orderService.financialSourceName !== FinancialSource.PERSON){
          <span class="form-group mb-4" style="padding-bottom: 1.5rem">
            <sup>*</sup> Ambulatoorse eriarstiabi visiiditasu Eesti Tervisekassa poolt kindlustatud vanemale kui 2aastasele isikule on 5 €.
          </span>
          <br>
        }
        @if(orderService && orderService.financialSourceName === FinancialSource.PERSON){
          <br>
          <br>
          <span class="form-group mb-4" style="padding-bottom: 1.5rem; width: 100%; display: flex; justify-content: flex-end">
            <sup>*</sup>&nbsp; Tasu vastavalt Medicumi hinnakirjale.
          </span>
          <br>
        }
      </div>
    }
  </div>
</section>
