import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
  provideHttpClient,
  withInterceptorsFromDi
} from "@angular/common/http";
import { SharedModule } from "@shared/shared.module";
import { AuthService } from "@core/services/auth.service";
import { AuthInterceptorService } from "@core/interceptors/auth-interceptor.service";
import { DateFormatInterceptor } from "@core/interceptors/date-format-interceptor";
import { I18nModule } from "./i18n.module";
import { Router, RouterModule } from "@angular/router";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ConfirmationService, MessageService } from "primeng/api";
import { ToastService } from "@core/services/toast.service";
import { DatePipe, registerLocaleData } from '@angular/common';
import localeEt from '@angular/common/locales/et';
import { HeaderModule } from "./feature/header/header.module";
import { DialogService } from "primeng/dynamicdialog";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { StyleClassModule } from "primeng/styleclass";
import { ErrorInterceptor } from "@core/interceptors/error-interceptor";
import { PermissionsService } from "@core/services/permissions.service";
import { CookieService } from "ngx-cookie-service";

registerLocaleData(localeEt, 'et');

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    RouterModule,
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    SharedModule,
    I18nModule,
    BrowserAnimationsModule,
    HttpClientModule,
    HeaderModule,
    ConfirmDialogModule,
    StyleClassModule,
  ],
  providers: [
    MessageService,
    DialogService,
    CookieService,
    DatePipe,
    PermissionsService,
    provideHttpClient(withInterceptorsFromDi()),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
      deps: [Router, AuthService, ToastService]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DateFormatInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
      deps: [ToastService]
    },
    {
      provide: LOCALE_ID,
      useValue: 'et'
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [PermissionsService, HttpClient],
      multi: true
    },
    ConfirmationService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

export function initializeApp(configService: PermissionsService): () => Promise<void> {
  return () => {
    return configService.loadCurrentUserPermissions();
  }
}

