<p-table [value]="[selectedReferral]">
  <ng-template pTemplate="header">
    <tr>
      <th>Kuupäev</th>
      <th>Number</th>
      <th>Tellija</th>
      <th>Eriala</th>
      <th>Eesmärk</th>
      <th>Teenus</th>
      <th>Cito</th>
      <th>Kehtivus</th>
      <th>Staatus</th>
      <th>Tehik staatus</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-referral>
    <tr>
      <td>{{ referral.documentDate | dateTimeDisplay }}</td>
      <td>
        <a class="text-primary underline cursor-pointer"
           (click)="showReferralInfo(referral)">
          {{ referral.documentNr }}
        </a>
      </td>
      <td>
        {{ referral.authorName }}
        <br/>
        <small class="text-muted">
          <i>{{ referral.sourceCompanyName }}</i>
        </small>
      </td>
      <td>{{ referral.targetProfession }}</td>
      <td>{{ referral.reason }}</td>
      <td>{{ referral.targetServiceCode }} - {{ referral.serviceName }}</td>
      <td>{{ referral.cito }}</td>
      <td>
        <span *ngIf="referral.validFrom  || referral.validTo ">
                    {{ referral.validFrom | dateTimeDisplay }} - {{ referral.validTo | dateTimeDisplay }}
        </span>
      </td>
      <td>
        <medis-order-referral-status-label
          [referralStatus]="referral.status"
        ></medis-order-referral-status-label>
      </td>

      <td>
        <medis-referral-etervis-status-label [eTervisStatus]="referral.eTervisStatus">

        </medis-referral-etervis-status-label>
      </td>
    </tr>
  </ng-template>
</p-table>
