<div class="flex flex-column w-full h-full m-auto pb-4 gap-4">

  <p-treeTable
    [value]="processedResult"
    [columns]="cols"
    [scrollable]="true"
    scrollHeight="250px">

    <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngFor="let col of columns">
          {{ col.header }}
        </th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-rowNode let-rowData="rowData" let-columns="columns">
      <tr>
        <td *ngFor="let col of columns; let i = index">
          <p-treeTableToggler [rowNode]="rowNode" *ngIf="i === 0"></p-treeTableToggler>
          {{ rowData[col.field] }}
        </td>
      </tr>
    </ng-template>

  </p-treeTable>

  <h5 class="m-0 flex justify-content-center"> {{ generateReportCounterTitle() }}</h5>

  <div class="flex row w-full justify-content-between">
    <p-button [label]="massUpdateResponse ? 'Jätka massmuutmist' : 'Jätka kopeerimist'" [outlined]="true" severity="primary" (onClick)="restartPreviousAction()"/>
    <p-button label="Sulge" [outlined]="true" severity="secondary" (onClick)="closeModal()"/>
  </div>
</div>
