@if (filterForm) {
  <section class="dashboard px-4 flex flex-column">
    <div class="dashboard-filter flex flex-column gap-3">
      <form [formGroup]="filterForm">
        <div class="dashboard-topbar__top row flex gap-4" [style.padding-top.rem]="2.5">
          <div class="w-full">
            <p-floatLabel>
              <p-dropdown
                id="provider"
                class="w-full"
                styleClass="w-full"
                [options]="providers"
                formControlName="provider"
                optionValue="personId"
                optionLabel="fullname"
                [filter]="true"
                (onChange)="onProviderChange(); addFiltersToURL()"/>
              <label class="text-color-secondary" for="provider">Osutaja</label>
            </p-floatLabel>
          </div>

          <div class="flex flex-row align-items-center gap-1">
            <p-button (onClick)="datePickerSetPreviousDay()" icon="pi pi-chevron-left" [outlined]="true"
                      severity="secondary"/>
            <p-floatLabel>
              <p-calendar
                id="bookingDate"
                inputId="bookingDate"
                formControlName="bookingDate"
                [firstDayOfWeek]="1"
                dateFormat="dd.mm.yy"
                [iconDisplay]="'input'"
                [showIcon]="true"
                class="w-14rem"
                styleClass="w-14rem"
                (onSelect)="onDateChange(); addFiltersToURL()"/>
              <label class="text-color-secondary" for="bookingDate">Kuupäev</label>
            </p-floatLabel>
            <p-button (onClick)="datePickerSetNextDay()" icon="pi pi-chevron-right" [outlined]="true"
                      severity="secondary"/>
          </div>

          <div class="w-full">
            <p-floatLabel>
              <p-dropdown
                id="service"
                class="w-full"
                styleClass="w-full"
                [filter]="true"
                [options]="services"
                [showClear]="true"
                formControlName="service"
                optionValue="id"
                optionLabel="value"
                placeholder="Valige teenus" (onChange)="onServiceChange(); addFiltersToURL()"/>
              <label class="text-color-secondary" for="service">Teenus</label>
            </p-floatLabel>
          </div>

          <div class="w-full">
            <p-floatLabel>
              <p-dropdown
                id="location"
                [options]="locations"
                formControlName="location"
                placeholder="Valige asukoht"
                class="w-full"
                styleClass="w-full"
                optionValue="id"
                optionLabel="value"
                [filter]="true"
                [showClear]="true"
                (onChange)="onLocationChange();addFiltersToURL()"/>
              <label class="text-color-secondary" for="location">Asukoht</label>
            </p-floatLabel>
          </div>
        </div>
      </form>
      <div class="dashboard-filter__bottom row flex gap-4 justify-content-between">
        @if (tableViewForm) {
          <div class="dashboard-filter__left flex row gap-4">
            <div>
              <form [formGroup]="tableViewForm">
                <p-selectButton
                  [options]="tableViews"
                  [multiple]="false"
                  formControlName="tableView"
                  optionValue="value"
                  (onChange)="onTableViewChange($event.value);addFiltersToURL()"
                >
                </p-selectButton>
              </form>
            </div>
            <div class="flex align-items-center">
              <p-checkbox
                inputId="choice"
                binary="true"
                label="Vabad ajad"
                class="text-color-secondary"
                [(ngModel)]="isFreeTimesSelected"
                (onChange)="onFreeTimesChange($event)">
              </p-checkbox>
            </div>
            <div class="flex align-items-center">
              <p-checkbox
                inputId="choice"
                name="ok"
                binary="true"
                label="Tühistatud/Muudetud"
                class="text-color-secondary"
                [(ngModel)]="isCancelledTimesSelected"
                (onChange)="onCancelledTimesChange($event)">
              </p-checkbox>
            </div>
          </div>
        }
        <div class="dashboard-filter__right">
          <p-button [disabled]="!filteredTimes.length || filteredTimes.length === 0 || this.loading" icon="pi pi-print"
                    [outlined]="true" (onClick)="printAllPatientsByDate()"/>
        </div>
      </div>
    </div>
    @if (currentUser?.personRole === PersonRole.PROVIDER) {
      <div class="flex flex-row gap-4 w-full">
        <div class="mt-4 flex-column" [style.width.%]="60">
          <medis-patients-table
            [data]="filteredTimes"
            [isFreeTimesLoaded]="isFreeTimesLoaded"
            (menuOpenForDailyPlan)="onMenuOpenForDailyPlan($event)"
            [bookingDate]="bookingDate.value"
            (updateList)="updateList($event)"
            (refreshList)="refresh()"
            [loading]="loading"
          />

        </div>
        <div class="flex flex-column" [style.width.%]="40">
          <div class="table-label"><b>Kohale jõudnud patsiendid</b></div>
          <medis-present-patients-table
            [data]="presentTimes"
            [bookingDate]="bookingDate.value"
            (updateList)="updateList($event)"
            [loading]="loading"
          />
        </div>
      </div>
    } @else {
      <div class="mt-4">
        <medis-patients-table
          [data]="filteredTimes"
          [isFreeTimesLoaded]="isFreeTimesLoaded"
          (menuOpenForDailyPlan)="onMenuOpenForDailyPlan($event)"
          [bookingDate]="bookingDate.value"
          (updateList)="updateList($event)"
          [loading]="loading"
        />
      </div>
    }
  </section>
}
