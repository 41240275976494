import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { IMapping } from "@core/interfaces/mapping.interface";
import { ToastService } from "@core/services/toast.service";
import { ServiceService } from "@core/services/service.service";
import { IService } from "@core/interfaces/service.interface";
import { Subscription } from "rxjs";
import { HttpParams } from "@angular/common/http";

@Component({
  selector: 'medis-service-select-list',
  template: `
    <p-floatLabel>
      <p-dropdown
        [id]="formControlName"
        class="w-full"
        styleClass="w-full"
        appendTo="body"
        [options]="services"
        [filter]="services.length > 15"
        filterBy="name"
        [ngModel]="selectedServiceId"
        (onChange)="onSelect($event.value)"
        (onClear)="onSelect(undefined)"
        optionValue="serviceId"
        [showClear]="showClear"
        [disabled]="isDisabled!"
        optionLabel="name"
      >
        <ng-template pTemplate="selectedItem">
          @if (selectedService) {
            <div class="flex align-items-center gap-2 overflow-visible">
              <medis-service-badge
                [badges]="selectedService!.serviceBadges"
                class="flex-shrink-0"
              />
              <div>{{ selectedService!.name }}
              </div>
            </div>
          }
        </ng-template>
        <ng-template let-service pTemplate="item">
          <div class="flex align-items-center gap-2">
            <medis-service-badge
              [badges]="service.serviceBadges"
              class="flex-shrink-0"
            />
            <div>{{ service.name }}</div>
          </div>
        </ng-template>
      </p-dropdown>
      <label class="text-color-secondary"
             [for]="formControlName">{{ labelName }}</label>
    </p-floatLabel>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ServiceSelectListComponent),
      multi: true
    }
  ]
})
export class ServiceSelectListComponent implements OnInit, OnChanges, ControlValueAccessor, OnDestroy {
  @Input() labelName: string = '';
  @Input() formControlName: string = '';
  @Input() showClear = true;
  @Input() isDisabled?: boolean = false;
  @Input() selectedServiceId: any;
  @Input() displayOptionIds!: number[];
  @Input() serviceFieldId!: number;

  @Output() onProviderChange = new EventEmitter<IMapping>();
  @Output() onServiceSelect = new EventEmitter<IService>();

  @Input() services: IService[] = [];
  selectedService: IService | undefined;

  private subscription$: Subscription = new Subscription();

  constructor(
    private serviceService: ServiceService,
    private toastService: ToastService,
  ) {
  }

  ngOnInit(): void {
    if (this.services.length === 0) {
      this.getTypes();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['selectedServiceId']?.currentValue && this.isDisabled) {
      this.getTypes();
    }
  }

  private getTypes(): void {
    this.subscription$ =
      (this.serviceFieldId ?
        this.serviceService.getServices(new HttpParams().set('fieldId', this.serviceFieldId)) :
        this.serviceService.servicesForSelect)
        .subscribe({
          next: (service: IService[] | null) => {
            if (service) {
              if (this.displayOptionIds) {
                this.services = service.filter(cat => this.displayOptionIds.includes(cat.serviceId))
              } else {
                this.services = service;
              }
              this.selectedService = this.services?.find(x => x.serviceId == this.selectedServiceId);
            } else {
              this.serviceService.getServicesForSelectList();
            }
          },
          error: (err: Error) => {
            this.toastService.error(err.message);
          }
        })
  }

  onChange = (value: any) => {
  };

  onTouched = () => {
  };

  writeValue(value: any): void {
    this.onSelect(value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onSelect(value: any) {
    this.selectedServiceId = value;
    this.selectedService = this.services?.find(x => x.serviceId == value);
    this.onServiceSelect.emit(this.selectedService);
    this.onProviderChange.emit({ serviceId: value });
    this.onChange(value);
    this.onTouched();
  }

  ngOnDestroy(): void {
    if (this.subscription$) {
      this.subscription$.unsubscribe();
    }
  }
}
