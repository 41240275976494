<div class="flex flex-column gap-4">
  <form [formGroup]="commentForm">
    <div class="flex gap-2">
      <textarea
        name="comment"
        rows="2"
        cols="50"
        class="form-control w-full"
        [style.min-height.rem]="4"
        pInputTextarea
        autoResize
        placeholder="Sisesta uus kommentaar"
        formControlName="comment"
      >
      </textarea>
      <p-button
        styleClass="min-h-4rem"
        class="min-h-4rem"
        severity="success"
        (click)="saveComment()"
        label="Lisa Kommentaar"
      />
    </div>
  </form>
  @if (comments.length > 0) {
    <div class="row form-group">
      <div class="w-12">
        <p-table [value]="comments" class="table table--today-plan">
          <ng-template pTemplate="header">
            <tr>
              <th>Broneeringu teatis</th>
              <th>Kasutaja</th>
              <th>Loodud</th>
              <th>Kommentaar</th>
              <th></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-comment>
            <tr>
              <td>
                <div class="flex flex-column gap-2">
                  <span>
                    {{ comment.serviceName }}
                  </span>
                  <span>
                    {{ comment.bookingTime | DateTime }}
                  </span>
                </div>
              </td>
              <td>
                {{ comment.userName }}
              </td>
              <td>
                {{ comment.created | dateTimeDisplay: 'dd.MM.yyyy HH:mm' }}
              </td>
              <td>
                {{ comment.comment }}
              </td>
              <td>
                <div class="float-right">
                  <p-button [rounded]="true"
                            icon="pi pi-times"
                            [text]="true"
                            severity="secondary"
                            medisConfirmAction
                            (confirmedAction)="deleteComment($event, comment.orderServiceCommentId)"
                            confirmMessage="Kas olete kindel, et soovite kommenaari eemaldada?"
                  />
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  }
</div>
<div class="p-dialog-footer justify-content-end">
  <p-button label="Sulge" [disabled]="commentLoading" (click)="closeModal()" size="small"/>
</div>
