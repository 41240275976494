import { Pipe, PipeTransform } from '@angular/core';
import { IMapping } from "@core/interfaces/mapping.interface";
import { OrderServiceStatus } from "@core/enums/order-service-status.enum";
import { InvoiceStatus } from "@core/enums/invoice-status.enum";

@Pipe({
  name: 'orderStatus',
  standalone: true
})
export class OrderStatusPipe implements PipeTransform {
  private statusMap: IMapping = {
    [OrderServiceStatus.CREATED]: 'Loodud',
    [OrderServiceStatus.PRESENT]: 'Kohal',
    [OrderServiceStatus.REALIZATION]: 'Teostamisel',
    [OrderServiceStatus.CANCELLED]: 'Tühistatud',
    [OrderServiceStatus.ABSENCE]: 'Annulleeritud',
    [OrderServiceStatus.PREPARING]: 'Tellitud',
    [OrderServiceStatus.FINISHED]: 'Lõpetatud',
    [OrderServiceStatus.UNFULFILLED]: 'Täitmata',
    [OrderServiceStatus.EXPIRED]: 'Aegunud',
    [OrderServiceStatus.CONFIRMED]: 'Broneeritud',
    [OrderServiceStatus.IN_PROVIDER_QUEUE]: 'Lisanumber',
    [OrderServiceStatus.IN_SERVICE_QUEUE]: 'Lisanumber',
    [OrderServiceStatus.BOOKING_NOT_NEEDED]: 'Ei vaja broneeringut',
    [OrderServiceStatus.NOT_APPEARED]: 'Ei ilmunud',
    [OrderServiceStatus.CHANGED]: 'Muudetud',
    [InvoiceStatus.REQUIRED]: 'Ootab maksmist',
    [InvoiceStatus.CREATED]: 'Suunatud kassasse',
    [InvoiceStatus.CANCELLED]: 'Ootab maksmist',
    [InvoiceStatus.PAYMENT_LINK_SENT]: 'Makselink saadetud',
    [InvoiceStatus.PREPAID_FAILED]: 'Arve loomine ebaõnnestus',
  };

  transform(status: any, isShort?: boolean): string {
    let result = status ? this.statusMap[status] : '';
    return isShort ? result[0].toUpperCase() : result;
  }
}
