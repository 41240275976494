<div class="flex flex-column gap-4">
  <div class="flex row w-full gap-1">
    <div class="grid grid-nogutter gap-4 w-full">
      <div class="col-fixed">
        <p-selectButton
          [options]="stateOptions"
          [(ngModel)]="actionValue"
          optionLabel="label"
          optionValue="value"
          aria-labelledby="basic"
          (onChange)="onStateOptionChange($event)"
        />
      </div>
      <div class="col w-full" [formGroup]="form" role="form">
        <p-floatLabel>
          <p-dropdown
            id="select-service"
            styleClass="w-full"
            [options]="personServices"
            optionValue="personServiceId"
            optionLabel="name"
            appendTo="body"
            formControlName="personServiceId"
          />
          <label for="select-service">Teenus</label>
        </p-floatLabel>
      </div>
    </div>
  </div>
  @if(actionValue === STATE_OPTIONS.ADD && selectedService){
    <div class="flex row w-12 gap-4">
      <div class="flex row w-6 gap-4">
        <div class="flex flex-column w-full">
          <p-floatLabel>
            <p-dropdown
              id="select-service-payer"
              styleClass="w-full"
              [options]="paymentOptions"
              appendTo="body"
              [(ngModel)]="selectedService.financialSourceId"
              (onChange)="onFinancialSourceChange($event)"
            />
            <label for="select-service-payer">Maksja</label>
          </p-floatLabel>
        </div>
      </div>
      <div class="flex row w-6 gap-2 overflow-visible">
        <div>
          <p-floatLabel>
            <p-inputNumber
              id="age-from"
              class="input-number-small"
              [(ngModel)]="selectedService.ageFrom"
            />
            <label for="age-from">Vanus alates</label>
          </p-floatLabel>
        </div>
        <div >
          <p-inputNumber
            inputId="age-to"
            class="input-number-small"
            placeholder="Vanus kuni"
            [(ngModel)]="selectedService.ageTo"
          />
        </div>
      </div>
    </div>
    <div class="flex row w-full gap-4">
      <div class="flex flex-column w-full">
        <input
          type="text"
          pInputTextarea
          [(ngModel)]="selectedService.info"
          placeholder="Kirjeldus">
      </div>
      <div class="flex w-3">
          <span class="flex align-items-center gap-1 text-color-secondary">
            <p-checkbox
              [binary]="true"
              id="primary-service"
              [(ngModel)]="selectedService.isPrimary"
            ></p-checkbox>
            <label for="primary-service">Esmane teenus</label>
          </span>
      </div>
    </div>
  }
</div>
