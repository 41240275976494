import { Component, Input, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { IRoom } from "@core/interfaces/room.interface";
import { LocationService } from "@core/services/location.service";

@Component({
  selector: "medis-room-action",
  templateUrl: "./room-action.component.html",
})

export class RoomActionComponent implements OnInit {
  @Input() form!: FormGroup;
  rooms: IRoom[] = [];

  roomOptions: { label: string; value: number }[] = [];

  constructor(private locationService: LocationService) {
  }

  ngOnInit() {
    if (!this.rooms || this.rooms.length === 0) {
      this.loadRooms();
    }
  }

  private loadRooms() {
    this.locationService.getAllRooms().subscribe({
      next: (response) => {
        this.rooms = response;
        this.transformRoomsOptions(this.rooms);
        this.setDefaultValue(this.rooms);
      },
      error: (err: any) => {
        console.error("Error fetching rooms", err);
      }
    });
  }

  private transformRoomsOptions(rooms: IRoom[]) {
    this.roomOptions = rooms.map(room => ({
      label: `${room.roomDepartment} - ${room.codeName}` || '',
      value: room.roomId || 0,
    }));
  }

  private setDefaultValue(data: any[]){
    if(data.length > 0){
      this.form.get("roomId")?.setValue(data[0].roomId);
    }
  }

}
