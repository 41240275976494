<div class="flex flex-column w-full gap-4 pb-4" [formGroup]="form">
  <div class="flex flex-column w-full pt-2">
    <p-floatLabel>
      <p-dropdown
        [options]="gpTeams"
        id="df-value"
        styleClass="w-full"
        optionLabel="name"
        optionValue="generalPractitionerTeamId"
        formControlName="gpTeamId"
        appendTo="body"
        [showClear]="true"
      />
      <label for="df-value">Perearstide nimistu</label>
    </p-floatLabel>
  </div>
  <div class="flex row justify-content-end gap-2">
    <p-button label="Sulge" [outlined]="true" severity="secondary" (click)="closeModal()"/>
    <p-button label="Salvesta" (click)="onSave()"/>
  </div>
</div>
