<div class="flex flex-column gap-4">
  <form class="flex flex-column gap-4"
        role="form"
        [formGroup]="addGeneralListItemForm">
    <div class="form-section__row flex gap-4">
      <div class="form-section__col w-12">
        <p-floatLabel>
          <input
            id="name"
            type="text"
            class="w-full"
            [ngClass]="{'ng-invalid ng-dirty': formSubmitted && name.errors}"
            formControlName="name"
            pInputText
          />
          <label for="name">{{ label }}</label>
        </p-floatLabel>
        @if (formSubmitted && name.errors) {
          <small class="p-error">{{ label }} on kohustuslik</small>
        }
      </div>
    </div>
  </form>
</div>
<div class="p-dialog-footer justify-content-end">
  <p-button
    label="Katkesta"
    outlined="true"
    severity="secondary"
    (click)="closeModal()"
    size="small"/>
  <p-button
    label="Salvesta"
    icon="pi pi-check"
    size="small"
    (click)="save()"
  ></p-button>
</div>
