import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { PersonService } from "@core/services/person.service";
import { IPerson } from "@core/interfaces/person.interface";
import { HttpParams } from "@angular/common/http";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { IPersonService } from "@core/interfaces/person-service.interface";
import { ToastService } from "@core/services/toast.service";
import { PlanTimeService } from "@core/services/plan-time.service";
import { IPlanTimeMassUpdate } from "@core/interfaces/plantime-mass-update.interface";
import { PlanTimeType } from "@core/enums/plan-type.enum";
import { DatePipe } from "@angular/common";

export const EDIT_ACTIONS = {
  CHOOSE_ACTION: "chooseAction",
  DELETE_PLAN_TIMES: "deletePlanTimes",
  TOGGLE_ACTIVATION: "toggleActivation",
  EDIT_ROOM: "editRoom",
  EDIT_RELATED_PERSON: "editRelatedPerson",
  EDIT_SERVICE: "editService",
  EDIT_DEVICE: "editDevice",
};

export const STATE_OPTIONS = {
  DELETE: "delete",
  ADD: "add",
};

export const EDIT_ACTION_OPTIONS = [
  {
    label: " - Vali tegevus -",
    value: EDIT_ACTIONS.CHOOSE_ACTION,
  },
  {
    label: "Töögraafikute kustutamine",
    value: EDIT_ACTIONS.DELETE_PLAN_TIMES,
    dividerLabel: "Kustutan",
  },
  {
    label: "Aktiveerimine/Deaktiveerimine",
    value: EDIT_ACTIONS.TOGGLE_ACTIVATION,
    dividerLabel: "Seisundi muutmine",
  },
  {
    label: "Muuda ruumi",
    value: EDIT_ACTIONS.EDIT_ROOM,
    dividerLabel: "Vali ruumi",
  },
  {
    label: "Muuda kolleegi",
    value: EDIT_ACTIONS.EDIT_RELATED_PERSON,
    dividerLabel: "Vali kolleeg",
  },
  {
    label: "Muuda teenust",
    value: EDIT_ACTIONS.EDIT_SERVICE,
    dividerLabel: "Vali teenus",
  },
];

export const ACTIVATION_OPTIONS = [
  { label: 'Mitteaktiivne', value: PlanTimeType.Draft },
  { label: 'Aktiivne', value: PlanTimeType.WorkIncludeServices }
];

@Component({
  selector: "medis-plantime-mass-edit",
  templateUrl: "./plantime-mass-edit.modal.html",
  styleUrls: ["./plantime-mass-edit.modal.scss"],
})

export class PlantimeMassEditModal implements OnInit {

  editActionsOptions = EDIT_ACTION_OPTIONS;
  selectedEditAction: string = EDIT_ACTIONS.CHOOSE_ACTION;
  actionValue: string = STATE_OPTIONS.DELETE;
  selectedActivationOption: PlanTimeType = PlanTimeType.Draft;

  readonly initTimeFrom: string = "08:00";
  readonly initTimeTo: string = "20:00";

  form!: FormGroup;
  massPlanTimeObject!: IPlanTimeMassUpdate;
  selectedPerson: IPerson | null = null;
  persons: IPerson[] = [];
  selectedService: IPersonService | null | undefined = null;
  personServices: IPersonService[] = [];
  massUpdateResponse: any | null = null;

  isLoadingPerson: boolean = false;
  formSubmitted: boolean = false;
  isMassUpdateResponseReceived: boolean = false;

  constructor(
    public ref: DynamicDialogRef,
    private toastService: ToastService,
    public config: DynamicDialogConfig,
    private personService: PersonService,
    private cdr: ChangeDetectorRef,
    private datePipe: DatePipe,
    private planTimeService: PlanTimeService,
    private fb: FormBuilder) {
  }

  ngOnInit() {
    const personId = this.config.data?.personId;

    if (!personId) {
      this.ref.close();
      return;
    }
    this.initForm();
    this.loadPersons();
    this.loadSelectedPerson(personId);
  }

  private initForm(){
    this.form = this.fb.group({
      selectedPersonId: [null, Validators.required],
      dateFrom: [null, Validators.required],
      dateTo: [null, Validators.required],
      timeFrom: [this.initTimeFrom],
      timeTo: [this.initTimeTo],
      personServiceId: [null],
      relatedPersonId: [null],
      roomId: [null],
    });

    this.trackFormChanges();
  }

  private trackFormChanges(){
    this.form.valueChanges.subscribe((formValues) => {
      if (formValues.timeFrom instanceof Date) {
        this.form.patchValue({ timeFrom: this.formatTime(formValues.timeFrom) }, { emitEvent: false });
      }
      if (formValues.timeTo instanceof Date) {
        this.form.patchValue({ timeTo: this.formatTime(formValues.timeTo) }, { emitEvent: false });
      }
      this.selectedService = this.personServices.find(
        (service) => service.personServiceId === formValues.personServiceId
      );
    });
  }

  private loadPersons(): void {
    const params = new HttpParams().set("includeNonActive", true);

    this.personService.getProviders(params).subscribe({
      next: (response) => {
        this.persons = response;
        this.cdr.detectChanges();
      },
      error: (err) => console.error("Error fetching persons", err),
    });
  }

  private loadSelectedPerson(personId: number): void {
    this.isLoadingPerson = true;

    this.personService.get(personId).subscribe({
      next: (response) => {
        this.selectedPerson = response;
        this.form.patchValue({ selectedPersonId: response.personId });
        this.getPersonServices(this.selectedPerson)
        this.cdr.detectChanges();
      },
      error: (err) => console.error("Error fetching person", err),
      complete: () => (this.isLoadingPerson = false)
    });
  }

  private getPersonServices(person: IPerson){
    this.personServices = person?.personServices ?? [];
    if (this.personServices.length === 1) {
      const singleService = this.personServices[0];
      this.setSelectedService(singleService);
    }
  }

  private setSelectedService(service: IPersonService) {
    this.form.get("personServiceId")?.setValue(service.personServiceId);
    this.selectedService = service;
  }

  private formatTime(date: Date): string {
    return date.toTimeString().slice(0, 5);
  }

  private isValidDates(startDate: Date, endDate: Date): boolean {
    return startDate <= endDate;
  }

  saveForm() {
    this.formSubmitted = true;

    if (this.form.invalid) {
      this.toastService.error("Palun täitke vajalikud andmed");
      return;
    }

    if (!this.isValidDates(this.dateFrom.value, this.dateTo.value)) {
      this.toastService.error("Palun valige korrektsed kuupäevad");
      return;
    }

    this.massPlanTimeObject = this.createMassPlanTimeObject();

    this.planTimeService.updateMassPlanTime(this.massPlanTimeObject).subscribe({
      next: (response) => {
        this.toastService.success("Muutmine edukas!");
        this.processMassUpdateResponse(response);
      },
      error: () => {
        this.toastService.error("Muutmine ebaõnnestus");
      },
      complete: () => {
        this.formSubmitted = false;
      }
    });
  }

  private createMassPlanTimeObject(): IPlanTimeMassUpdate {
    const formValues = this.form.value;

    const baseObject: IPlanTimeMassUpdate = {
      personId: formValues.selectedPersonId,
      timeFrom: this.addSeconds(formValues.timeFrom),
      timeTo: this.addSeconds(formValues.timeTo),
      from: this.formatDate(formValues.dateFrom),
      to: this.formatDate(formValues.dateTo),
    };

    this.addActionSpecificParams(baseObject);
    return baseObject;
  }

  private formatDate(date: Date): string {
    return this.datePipe.transform(date, "yyyy-MM-dd") ?? "";
  }

  private addSeconds(time: string): string {
    return time?.includes(":") ? `${time}:00` : time;
  }

  private addActionSpecificParams(object: IPlanTimeMassUpdate): void {
    switch (this.selectedEditAction) {
      case EDIT_ACTIONS.EDIT_SERVICE:
        if (this.actionValue === STATE_OPTIONS.ADD && this.selectedService) {
          object.isServiceChanging = true;
          object.personService = this.selectedService;
        } else if (this.actionValue === STATE_OPTIONS.DELETE) {
          object.deletePersonServiceId = this.personServiceId.value;
        }
        break;

      case EDIT_ACTIONS.EDIT_RELATED_PERSON:
        if (this.actionValue === STATE_OPTIONS.ADD && this.relatedPersonId.value != null) {
          object.isRelatedPersonChanging = true;
          object.relatedPersonId = this.relatedPersonId.value;
        } else if (this.actionValue === STATE_OPTIONS.DELETE) {
          object.deleteRelatedPersonId = this.relatedPersonId.value;
        }
        break;

      case EDIT_ACTIONS.EDIT_ROOM:
        object.isRoomChanging = true;
        object.roomId = this.roomId.value;
        break;

      case EDIT_ACTIONS.TOGGLE_ACTIVATION:
        object.isActivation = this.selectedActivationOption === PlanTimeType.WorkIncludeServices;
        object.isDeactivation = this.selectedActivationOption === PlanTimeType.Draft;
        break;

      case EDIT_ACTIONS.DELETE_PLAN_TIMES:
        object.isDeleting = true;
        break;

      default:
        break;
    }
  }

  private processMassUpdateResponse(response: any): void {
    this.massUpdateResponse = response;
    this.isMassUpdateResponseReceived = true;
  }

  onActionChange(event: any) {
    this.selectedEditAction = event?.value || null;

    const controls = ["personServiceId", "relatedPersonId", "roomId", "deviceId"];
    controls.forEach(control => {
      const formControl = this.form.get(control);
      formControl?.clearValidators();
      formControl?.reset();
    });

    const actionValidators: Record<string, string[]> = {
      [EDIT_ACTIONS.EDIT_SERVICE]: ["personServiceId"],
      [EDIT_ACTIONS.EDIT_RELATED_PERSON]: ["relatedPersonId"],
      [EDIT_ACTIONS.EDIT_ROOM]: ["roomId"],
      [EDIT_ACTIONS.EDIT_DEVICE]: ["deviceId"],
    };

    const requiredControls = actionValidators[this.selectedEditAction] || [];
    requiredControls.forEach(control => {
      this.form.get(control)?.setValidators(Validators.required);
    });

    controls.forEach(control => this.form.get(control)?.updateValueAndValidity());

    this.cdr.detectChanges();
  }

  onProviderChange(event: any){
    if (event.value) {
      this.loadSelectedPerson(event.value);
    }
  }

  onStateOptionChange(event: any) {
    this.actionValue = event;
  }

  onFinSourceChange(event: any): void {
    if(event && this.selectedService){
      this.selectedService.financialSourceId = event.value;
      this.selectedService.financialSourceName = event.label;
    }
  }

  getDividerLabel(value: string): string {
    return this.editActionsOptions.find((action) => action.value === value)?.dividerLabel || "";
  }

  restartMassEditModal() {
    this.loadSelectedPerson(this.selectedPersonId.value);
    this.initForm();
    this.isMassUpdateResponseReceived = false;
    this.massUpdateResponse = null;
  }

  closeModal(){
    this.ref.close(this.massUpdateResponse);
  }

  get selectedPersonId(): FormControl {
    return this.form.get('selectedPersonId') as FormControl;
  }

  get dateFrom(): FormControl {
    return this.form.get('dateFrom') as FormControl;
  }

  get dateTo(): FormControl {
    return this.form.get('dateTo') as FormControl;
  }

  get timeFrom(): FormControl {
    return this.form.get('timeFrom') as FormControl;
  }

  get timeTo(): FormControl {
    return this.form.get('timeTo') as FormControl;
  }

  get personServiceId(): FormControl {
    return this.form.get('personServiceId') as FormControl;
  }

  get relatedPersonId(): FormControl {
    return this.form.get('relatedPersonId') as FormControl;
  }

  get roomId(): FormControl {
    return this.form.get('roomId') as FormControl;
  }

  protected readonly EDIT_ACTIONS = EDIT_ACTIONS;
  protected readonly ACTIVATION_OPTIONS = ACTIVATION_OPTIONS;
}
