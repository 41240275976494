import { Component, Input } from '@angular/core';
import { ToastService } from "@core/services/toast.service";

@Component({
  selector: 'medis-copyable-id-code',
  styleUrl: './copyable-id-code.component.scss',
  template: `
    <span class="text-base font-semibold patient-code">
      <span class="patient-code__value cursor-pointer" (click)="copyIdCode(idCode)">{{ idCode }}</span>
    </span>
  `
})
export class CopyableIdCodeComponent {
  @Input() idCode?: string;

  constructor(private toastService: ToastService) {
  }

  copyIdCode(value?: string): void {
    if (!value) return;
    navigator.clipboard.writeText(value.toString()).then(() => {
      this.toastService.contrast("Isikukood kopeeritud");
    }).catch(() => {
      this.toastService.error("Isikukoodi kopeerimine nurjus");
    });
  }
}
