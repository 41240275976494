import { Component, EventEmitter, input, Output } from '@angular/core';
import { PlanTimeType } from "@core/enums/plan-type.enum";
import { MenuItem } from "primeng/api";

@Component({
  selector: 'medis-select-button',
  template:  `
    @if (value() && options()) {
      <div
        role="group"
        class="p-component p-selectbutton p-buttonset"
      >
        @for (option of options(); track $index) {
          <div
            role="radio"
            class="p-element p-button p-component"
            tabindex="0"
            [class.p-disabled]="disabled()"
            [class.p-highlight]="option['value'] === value()"
            [attr.aria-selected]="option['value'] === value()"
            (click)="optionClick(option)"
            (keydown.enter)="optionClick(option)"
          >
            <span class="p-button-label">
              {{ option.label }}
            </span>
          </div>
        }
      </div>
    }`,
  styleUrl: './select-button.component.scss'
})
export class SelectButtonComponent {
  options = input.required<MenuItem[]>();
  value = input.required<any|undefined>();
  disabled = input(false);
  @Output() onSelect = new EventEmitter<MenuItem>();

  optionClick(option: MenuItem): void {
    this.onSelect.emit(option);
  }

  protected readonly PlanTimeType = PlanTimeType;
}
