import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { DynamicDialogRef } from "primeng/dynamicdialog";
import { IOrderServiceTime } from "@core/interfaces/order-service-time.interface";
import { OrderServiceStatus } from "@core/enums/order-service-status.enum";
import { IService } from "@core/interfaces/service.interface";
import { OrderServiceService } from "@core/services/order-service.service";
import { Router } from "@angular/router";
import { HttpParams } from "@angular/common/http";
import { OrderService } from "@core/services/order.service";
import { ExternalMedicalCaseService } from "@core/services/external-medical-case.service";

@Component({
  selector: 'medis-present-patients-table',
  templateUrl: './present-patients-table.component.html',
  styleUrls: ['./present-patients-table.component.scss'],
})
export class PresentPatientsTableComponent implements OnDestroy {
  @Input() data: IOrderServiceTime[] = [];
  @Input() bookingDate: Date = new Date();
  @Input() service!: IService;
  @Input() loading = false;

  @Output() updateList = new EventEmitter<{time: IOrderServiceTime, status: OrderServiceStatus}>();

  orderTypes: any = [];
  ref: DynamicDialogRef | undefined;

  constructor(
    private orderServiceService: OrderServiceService,
    private router: Router,
    private orderService: OrderService,
    private externalMedicalCaseService: ExternalMedicalCaseService,
  ) {
  }

  openCommentsByOrder = (orderServiceTime: IOrderServiceTime | null) => {
    this.orderServiceService.openCommentsByOrder(orderServiceTime);
  };

  openPatient(time: IOrderServiceTime){
    this.sendOpenPatientMessage(time);
  }

  private sendOpenPatientMessage(time: IOrderServiceTime): void {
    let httpParams = new HttpParams()
      .set("orderServiceTimeId", time.orderServiceTimeId || 0)
      .set("orderServiceQueueId", time.orderServiceQueueId || 0);
    this.orderService
      .getInitExternalMedicalCaseData(httpParams)
      .subscribe({
        next: (response: any) => {
          this.externalMedicalCaseService.open(response);
        }
      });
  }

  onTimeCancel(timeData: {time: IOrderServiceTime, status: OrderServiceStatus}): void {
    this.updateList.emit(timeData);
  }

  openPatientView(patientId: number){
    this.router.navigate([`/patients/${patientId}/details`]);
  }

  ngOnDestroy() {
    if (this.ref) {
      this.ref.close();
    }
  }

  protected readonly OrderServiceStatus = OrderServiceStatus;
}
