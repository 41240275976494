import { Component } from '@angular/core';
import { OrderServiceStatus } from "@core/enums/order-service-status.enum";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { PrintService } from "@core/services/print.service";
import { IOrderServiceTime } from "@core/interfaces/order-service-time.interface";
import { IOrderServiceQueue } from "@core/interfaces/order-service-queue.interface";
import { IOrderService } from "@core/interfaces/order-service.interface.interface";
import { FinancialSource } from "@core/enums/financial-sources.enum";
import { IPatient } from "@core/interfaces/patient.interface";

@Component({
  selector: 'app-print-reservations.modal',
  templateUrl: './print-reservations.modal.component.html',
  styleUrl: './print-reservations.modal.component.scss'
})
export class PrintReservationsModalComponent {
  orderService?: IOrderService;
  patient?: IPatient;
  reservations: Array<Partial<IOrderServiceTime> | Partial<IOrderServiceQueue>> = [];

  constructor(private printService: PrintService,
              private ref: DynamicDialogRef,
              private config: DynamicDialogConfig,
  ) {
    const orderService = this.config.data?.orderService
    if (orderService) {
      this.orderService = this.config.data.orderService;
      this.reservations = [...orderService.orderServiceTimes || [], ...orderService.orderServiceQueues || []];
    }
    const patient = this.config.data?.patient;
    if (patient) {
      this.patient = patient;
    }
  }

  print = () => {
    this.printService.printDiv('printSection');
  }

  closeModal(): void {
    this.ref.close();
  }

  protected readonly OrderServiceStatus = OrderServiceStatus;
  protected readonly FinancialSource = FinancialSource;
}
