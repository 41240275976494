@if (!(loading.user && loading.consultations)) {
  <section class="dashboard px-4 flex flex-column gap-4 pt-4">

    <h3 class="form-section__title">Vastamata E-konsultatsioonid</h3>
    @if (eConsultations) {
      <medis-e-consultations-table
        [data]="eConsultations"
        [providerMode]="true"
        (onActionComplete)="loadEConsultations()"
      ></medis-e-consultations-table>
    }
  </section>
  <section class="dashboard px-4 flex flex-column gap-4 pt-6">
    <h3 class="form-section__title">Kinnitatud E-konsultatsioonid</h3>
    <form [formGroup]="filterForm">
      <div class="dashboard-topbar__top row flex gap-4 pt-2">
        <div class="flex flex-row align-items-center gap-1">
          <p-floatLabel>
            <p-calendar
              id="dateFrom"
              inputId="dateFrom"
              formControlName="dateFrom"
              [firstDayOfWeek]="1"
              dateFormat="dd.mm.yy"
              [iconDisplay]="'input'"
              [showIcon]="true"
              class="w-14rem"
              styleClass="w-14rem"
              [showButtonBar]="true"
            />
            <label class="text-color-secondary" for="dateFrom">Alates</label>
          </p-floatLabel>
        </div>
        <div class="flex flex-row align-items-center gap-1">
          <p-floatLabel>
            <p-calendar
              id="dateTo"
              inputId="dateTo"
              formControlName="dateTo"
              [firstDayOfWeek]="1"
              dateFormat="dd.mm.yy"
              [iconDisplay]="'input'"
              [showIcon]="true"
              class="w-14rem"
              styleClass="w-14rem"
              [showButtonBar]="true"
            />
            <label class="text-color-secondary" for="dateTo">Kuni</label>
          </p-floatLabel>
        </div>
        <p-button label="Otsi" (click)="searchConfirmed()"/>
      </div>
    </form>

    @if (eConsultations) {
      <medis-e-consultations-table [data]="confirmedEConsultations"></medis-e-consultations-table>
    }

  </section>
} @else {
  <div class="flex flex-column gap-4 p-4">
    <p-skeleton borderRadius="25px" height="2rem" width="10rem"/>
    <p-skeleton height="10rem"/>
    <p-skeleton borderRadius="25px" height="2rem" width="10rem"/>
    <div class="flex gap-4">
      <p-skeleton height="3rem" width="10rem"/>
      <p-skeleton height="3rem" width="10rem"/>
      <p-skeleton height="3rem" width="5rem"/>
    </div>
    <p-skeleton height="10rem"/>
  </div>
}
