import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { DialogService, DynamicDialogComponent, DynamicDialogRef } from "primeng/dynamicdialog";
import { ToastService } from "@core/services/toast.service";
import { IOrderServiceTime } from "@core/interfaces/order-service-time.interface";
import { IPerson } from "@core/interfaces/person.interface";
import { PersonService } from "@core/services/person.service";
import { OrderService } from "@core/services/order.service";
import { FinancialSource, FinancialIdBySource } from "@core/enums/financial-sources.enum";
import { IChangeBooking } from "@core/interfaces/change-booking.interface";

@Component({
  templateUrl: './reception-data-edit.modal.html',
  styleUrl: './reception-data-edit.modal.scss'
})
export class ReceptionDataEditModal implements OnInit {
  orderServiceTime!: IOrderServiceTime;
  provider!: IPerson;

  receptionDataForm!: FormGroup;
  formSubmitted = false;

  instance: DynamicDialogComponent | undefined;

  constructor(
    private personService: PersonService,
    private toastService: ToastService,
    private formBuilder: FormBuilder,
    private dialogService: DialogService,
    private ref: DynamicDialogRef,
    private orderService: OrderService,
  ) {
    this.instance = this.dialogService.getInstance(this.ref);
    if (this.instance && this.instance.data && this.instance.data['orderServiceTime']) {
      this.orderServiceTime = this.instance.data['orderServiceTime'];
    }
  }

  ngOnInit() {
    this.receptionDataForm = this.formBuilder.group({
      providerId: [this.orderServiceTime.providerId ?? null, Validators.required],
      serviceId: [this.orderServiceTime.serviceId ?? null, Validators.required],
      roomId: [this.orderServiceTime.roomId ?? null],
    })
    this.getProviderData();
  }

  getProviderData(): void {
    if (!this.providerId.value || isNaN(this.providerId.value)) {
      console.error('Provider ID is not set');
      return;
    }

    this.personService.get(this.providerId.value).subscribe(
      {
        next: (response: IPerson) => {
          this.provider = response;

          if (!this.provider.personServices.find(s => s.serviceId === this.serviceId.value)) {
            this.serviceId.setValue(null);
          }
        },
        error: err => {
          this.toastService.error(err.message);
        }
      }
    )
  }

  onProviderChange(event: any): void {
    if (event && event.providerId && Number.isInteger(event.providerId)) {
      this.providerId.setValue(event.providerId);
      this.getProviderData();
    } else {
      console.warn('Invalid provider ID:', event?.providerId);
      return;
    }
  }

  save(): void {
    this.formSubmitted = true;
    if (this.receptionDataForm.invalid) {
      return;
    }

    const changeBookingDto = this.createChangeBookingDto();

    this.orderService.changeBooking(changeBookingDto).subscribe({
      next: result => {
        this.ref.close(result);
        this.toastService.success('Teenuse muutmise õnnestus');
      }
    });
  }

  closeModal(): void {
    this.ref.close();
  }

  get providerId(): FormControl {
    return this.receptionDataForm.get('providerId') as FormControl;
  }

  get roomId(): FormControl {
    return this.receptionDataForm.get('roomId') as FormControl;
  }

  get serviceId(): FormControl {
    return this.receptionDataForm.get('serviceId') as FormControl;
  }

  private createChangeBookingDto(): IChangeBooking {
    const isServiceTime = this.orderServiceTime?.orderServiceTimeId;
    let changeBookingDto = {} as IChangeBooking;

    if (isServiceTime) {
      changeBookingDto.orderServiceTimeId = this.orderServiceTime.orderServiceTimeId;
      changeBookingDto.originalServiceId = this.orderServiceTime.serviceId;
      changeBookingDto.originalPersonId = this.orderServiceTime.providerId;

      if (this.orderServiceTime.financialSourceName === FinancialSource.ANOTHER_JURIDICAL_PERSON) {
        changeBookingDto.financialSourceId = FinancialIdBySource[this.orderServiceTime.financialSourceName];
        if (this.orderServiceTime.companyId) {
          changeBookingDto.companyId = this.orderServiceTime.companyId;
        }
      }
      if (this.orderServiceTime.roomId) {
        changeBookingDto.originalRoomId = this.orderServiceTime.roomId;
      }
    } else {
      changeBookingDto.orderServiceQueueId = this.orderServiceTime.orderServiceQueueId!;
    }

    changeBookingDto.personId = this.providerId.value || 0;
    changeBookingDto.serviceId = this.serviceId.value || 0;
    changeBookingDto.roomId = this.roomId.value || 0;

    return changeBookingDto;
  }

}


