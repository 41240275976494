@if (data) {
  <div class="flex flex-row w-full gap-4" style="height: 80.5px; padding: 17.5px 0 17.5px 0">

    <div class="single-patient__content flex flex-row w-full gap-4">

      <div class="single-patient__element w-full flex flex-column">
        <div class="single-patient__label font-bold flex-grow-1">PATSIENT</div>
        <div class="single-patient__value text-color-secondary flex align-items-center flex-wrap gap-1 row-gap-2 flex-grow-1">
          <a [routerLink]="['/patients', data.patientId, 'details']"
             class="text-primary underline hover:cursor-pointer"
             [style.text-underline-offset.px]="4"
          >{{ data.fullName }}</a>|
          <medis-copyable-id-code [idCode]="data.idCode"/>
        </div>
      </div>

      <div class="single-patient__element w-full flex flex-column">
        <div class="single-patient__label font-bold flex-grow-1">TELEFON</div>
        <div class="single-patient__value text-color-secondary flex align-items-center flex-grow-1">{{ data.phone }}
        </div>
      </div>

      <div class="single-patient__element w-full flex flex-column">
        <div class="single-patient__label font-bold flex-grow-1">PEREARST</div>
        <div
          class="single-patient__value text-color-secondary flex align-items-center flex-grow-1">{{ data.doctorName }}
        </div>
      </div>

      <div class="single-patient__element w-full flex flex-column">
        <div class="single-patient__label font-bold flex-grow-1">VIIMANE KONTAKT</div>
        <div
          class="single-patient__value text-color-secondary flex align-items-center flex-grow-1">{{ data.gpTeamLastProviderName ? data.gpTeamLastProviderName.toUpperCase() : "" }}
        </div>
      </div>

      <div class="single-patient__element w-full flex flex-column">
        <div class="single-patient__label font-bold flex-grow-1">KINDLUSTATUD</div>
        <div class="single-patient__value flex-grow-1">
          <p-tag
            [styleClass]="'white-space-nowrap tag tag--small ' + (getStatusInfo(data.isInsured).class)"
            rounded="true"
            [value]="getStatusInfo(data.isInsured).name">
          </p-tag>
        </div>
      </div>

      <div class="single-patient__element w-full flex flex-column">
        <div class="single-patient__label font-bold flex-grow-1">VIIMATI MUUDETUD</div>
        <div
          class="single-patient__value text-color-secondary flex align-items-center flex-grow-1"
          [class.date-time--highlight]="isUpdatedLongTimeAgo">
          {{ data.modified | dateTimeDisplay }}
        </div>
      </div>
    </div>

    @if (!simpleView) {
      <div class="single-patient__actions flex flex-grow-1 justify-content-end w-18rem gap-4">
        @if (!showActions) {
          <p-button
            [outlined]="true"
            severity="secondary"
            [style]="{ height: '2.5rem' }"
            (click)="showFutureReservations()"
            styleClass="flex-grow-1 w-15rem">
            <b>Tulevased broneeringud ({{futureBookingsCount}})</b>
          </p-button>
          <p-button
            [outlined]="true"
            severity="secondary"
            [style]="{ height: '2.5rem', width: '2.5rem' }"
            icon="pi pi-times"
            (click)="onClearSearch()">
          </p-button>
        }
        @if (showActions) {
          <p-button
            [outlined]="true"
            severity="secondary"
            [style]="{ height: '2.5rem' }"
            styleClass="flex-grow-1"
            (click)="showPatientBooking(data)">
            <b>Broneeri aeg</b>
          </p-button>
          <p-button
            [outlined]="true"
            severity="secondary"
            [style]="{ height: '2.5rem', width: '2.5rem' }"
            icon="pi pi-ellipsis-h"
            (click)="openActions($event, overlayPanel)">
          </p-button>
          <p-overlayPanel #overlayPanel>
            <p-menu styleClass="more-menu" [model]="actions"></p-menu>
          </p-overlayPanel>
        }
      </div>
    } @else {
      <p-button
        [outlined]="true"
        severity="secondary"
        [style]="{ height: '2.5rem', width: '2.5rem' }"
        icon="pi pi-times"
        (click)="onClearSearch()">
      </p-button>
    }
  </div>
}
