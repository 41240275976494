@if (userDetails) {
  <div class="flex flex-column w-12 gap-4">
    <div class="bg-white user-details__section border-round-md">
      <div class="flex flex-row justify-content-between">
        <h3 class="user-details__section-title text-color-secondary text-xl font-bold">Patsiendi detailandmed</h3>
        <div class="flex row gap-2">
          <medis-patient-last-modified-label
            [lastModifiedDate]="this.patient.modified"
            class="flex align-items-center"
          />
          <p-button
            severity="secondary"
            [outlined]="true"
            class="flex align-self-center"
            icon="pi pi-pencil"
            (click)="openPatientEdit()"
          />
        </div>
      </div>
      <div class="user-details__section-info flex flex-row" [style]="{gap: '28px'}">
        <div class="flex flex-column w-6">
          @for (item of userDetails.leftInfo; track item) {
            <div class="user-details__section-field flex flex-row" [ngClass]="{'last-element': $last}">
              <div class="user-details__section-label">
                <span><b>{{ item.label }}</b></span>
              </div>
              <p class="user-details__section-value m-0 text-color-secondary">{{ item.value }}</p>
            </div>
          }
        </div>
        <div class="flex flex-column w-6">
          @for (item of userDetails.rightInfo; track item) {
            <div class="user-details__section-field flex flex-row" [ngClass]="{'last-element': $last}">
              <div class="user-details__section-label">
                <span><b>{{ item.label || '' }}</b></span>
              </div>
              @if (item.label === 'Kindlustatud') {
                <ng-container>
                  <p-tag
                    [styleClass]="'white-space-nowrap tag tag--small ' + item.value.class"
                    rounded="true"
                    [value]="item.value.name"
                  />
                </ng-container>
              } @else {
                <p class="user-details__section-value m-0 text-color-secondary">{{ item.value }}</p>
              }
            </div>
          }
        </div>
      </div>
    </div>
  </div>
}
