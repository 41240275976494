import { Component } from "@angular/core";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { GpteamService } from "@core/services/gpteam.service";
import { IGeneralPractitionerTeam } from "@core/interfaces/general-practitioner-team.interface";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ToastService } from "@core/services/toast.service";
import { HttpResponse } from "@angular/common/http";

@Component({
  selector: "medis-doctor-family-edit",
  templateUrl: "./doctor-family-edit.modal.component.html",
})

export class DoctorFamilyEditModalComponent {

  gpTeams: IGeneralPractitionerTeam[] = [];
  form!: FormGroup;
  initialGpTeamId: number | null = null;

  constructor (
    private gpTeamService: GpteamService,
    private toastService: ToastService,
    private ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private formBuilder: FormBuilder,
  ) {
      this.getGpTeams();
      this.createForm();

      if (this.config.data.currentGpTeam.generalPractitionerTeamId) {
        this.initialGpTeamId = this.config.data.currentGpTeam.generalPractitionerTeamId;
      }
  }

  private createForm() {
    this.form = this.formBuilder.group({
      gpTeamId: [this.config.data.currentGpTeam.generalPractitionerTeamId ?? null],
      patientId: [this.config.data.currentGpTeam.patientId, Validators.required],
    })
  }

  private getGpTeams() {
    this.gpTeamService.getAll().subscribe({
      next: data => {
        this.gpTeams = data;
      },
      error: err => {
        console.error("Error fetching gp teams", err)
      }
    })
  }

  closeModal() {
    this.ref.close();
  }

  onSave(){
    if (this.form.invalid) {
      this.toastService.error("Patsient valimata. Proovige uuesti.")
      return;
    }

    if (this.form.get("gpTeamId")!.value === this.initialGpTeamId) {
      this.toastService.warn("Antud PA nimistu hetkel on juba valitud.")
      return;
    }

    const gp = this.form.value;

    this.gpTeamService.changePatientGpTeam(gp).subscribe({
      next: () => {
        this.toastService.success("Patsiendi PA nimistu on edukalt salvestatud.");
        this.ref.close(true);
      },
      error: err => {
        console.error("huynja", err);
      }
    })
  }

}
