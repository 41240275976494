<div class="flex flex-column gap-4">
  @if (receptionDataForm) {
    <form class="flex flex-column gap-4"
          role="form"
          [formGroup]="receptionDataForm">
      <div class="form-section__row flex gap-4">
        <div class="form-section__col w-12">
          <medis-provider-select-list
            id="provider"
            labelName="Osutaja"
            formControlName="providerId"
            (onProviderChange)="onProviderChange($event)"
          />
          @if (formSubmitted && providerId.errors) {
            <small class="p-error">Osutaja on kohustuslik</small>
          }
        </div>
      </div>
      @if (provider) {
        <div class="form-section__row flex gap-4">
          <div class="form-section__col w-12">
            <p-floatLabel>
              <p-dropdown
                id="service"
                class="w-full"
                styleClass="w-full"
                appendTo="body"
                [ngClass]="{'ng-invalid ng-dirty': formSubmitted && serviceId.errors?.['required']}"
                [filter]="true"
                [options]="provider.personServices"
                [showClear]="true"
                formControlName="serviceId"
                optionValue="serviceId"
                optionLabel="name"
              />
              <label for="service">Teenus</label>
            </p-floatLabel>
            @if (formSubmitted && serviceId.errors?.['required']) {
              <small class="p-error">Teenus on kohustuslik</small>
            }
          </div>
        </div>
      }
      <div class="form-section__row flex gap-4">
        <div class="form-section__col w-12">
          <medis-room-select-list
            id="room"
            labelName="Ruum"
            formControlName="roomId"
          />
        </div>
      </div>
    </form>
  }
</div>
<div class="p-dialog-footer justify-content-end">
  <p-button
    label="Katkesta"
    outlined="true"
    severity="secondary"
    (click)="closeModal()"
    size="small"/>
  <p-button
    label="Salvesta"
    icon="pi pi-check"
    size="small"
    (click)="save()"
  ></p-button>
</div>

