import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "@environments/environment";
import { Observable } from "rxjs";
import { IOrder } from "../interfaces/order.interface";
import { IOrderService } from "../interfaces/order-service.interface.interface";
import { IBookingTime } from "../interfaces/booking-time.interface";
import { IOrderServiceSearchResultRow } from "../interfaces/order-service-search-result-row.interface";
import { IOrderServiceSearchFilters } from "../interfaces/order-service-search-filters.interface";
import { IOrderServiceComment } from "../interfaces/order-service-comment.interface";
import { ChangeFinancialSourceRequest } from "@core/interfaces/change-financial-source-request.interface";
import { IReferral } from "@core/interfaces/referral.interface";
import { IChangeBooking } from "@core/interfaces/change-booking.interface";


@Injectable({
  providedIn: 'root'
})
export class OrderService {
  private readonly BACKEND_URL: string;
  private readonly OLD_BACKEND_URL: string;

  constructor(private http: HttpClient) {
    this.BACKEND_URL = `${environment.backendUrl}/orders`;
    this.OLD_BACKEND_URL = `${environment.oldBackendUrl}/orders`;
  }

  public get(orderId: number): Observable<IOrder> {
    return this.http.get<IOrder>(`${this.BACKEND_URL}/${orderId}`);
  }

  public save(order: IOrder | any): Observable<IOrder> {
    return this.http.post<IOrder>(`${this.BACKEND_URL}`, order);
  }

  // public updateOrderService(orderService: IOrderService): Observable<IOrderService> {
  //   return this.http.post<IOrderService>(`${this.OLD_BACKEND_URL}/service/save`, orderService);
  // }

  // public deleteOrderService(orderServiceId: number): Observable<any> {
  //   return this.http.delete<any>(`${this.BACKEND_URL}/service/${orderServiceId}`);
  // }

  // public getOrderBookingTimes(orderId: number): Observable<any> {
  //   return this.http.get<any>(`${this.BACKEND_URL}/bookingtimes/${orderId}`);
  // }

  // public getServiceFirstBookingTimes(serviceId: number, financialSourceId: number): Observable<IBookingTime[]> {
  //   return this.http.get<IBookingTime[]>(`${this.BACKEND_URL}/bookingtimes/${serviceId}/${financialSourceId}`);
  // }

  // public getPaymentMethods(): Observable<IBookingTime[]> {
  //   return this.http.get<IBookingTime[]>(`${this.BACKEND_URL}/paymentmethods`);
  // }

  // public getServiceBookingDates(params: HttpParams): Observable<IBookingDates> {
  //   return this.http.get<IBookingDates>(`${this.BACKEND_URL}/bookingdates`, {params});
  // }

  // public getBookingTimesProviders(params: HttpParams): Observable<IPerson[]> {
  //   return this.http.get<IPerson[]>(`${this.BACKEND_URL}/bookingtimes/providers`, {params});
  // }

  // public getServiceBookingTimes(date: string, patientId: number, serviceId: number, addressId: number, financialSourceId: number): Observable<IBookingTime[]> {
  //   return this.http.get<IBookingTime[]>(`${this.BACKEND_URL}/bookingtimes/date/${date}/${serviceId}/${patientId}/${addressId}/${financialSourceId}`);
  // }

  // public getAllOrders(): Observable<IOrderRow[]> {
  //   return this.http.get<IOrderRow[]>(`${this.BACKEND_URL}/current`);
  // }

  public searchOrders(params: HttpParams): Observable<IOrderServiceSearchResultRow[]> {
    return this.http.get<IOrderServiceSearchResultRow[]>(`${this.BACKEND_URL}/orderServices/search`, { params });
  }

  // public searchOrdersByOrderDate(params: HttpParams): Observable<IOrderServiceSearchResultRow[]> {
  //   return this.http.get<IOrderServiceSearchResultRow[]>(`${this.BACKEND_URL}/searchByOrderDate`, {params});
  // }

  public getAllFilters(params: HttpParams): Observable<IOrderServiceSearchFilters> {
    return this.http.get<IOrderServiceSearchFilters>(`${this.BACKEND_URL}/getFilters`, { params });
  }

  public getReferral(referralId: number): Observable<IReferral> {
    return this.http.get<IReferral>(`${this.BACKEND_URL}/referral/${referralId}`);
  }

  // public cancelReferral(referralId: number): Observable<any> {
  //   return this.http.get<any>(`${this.BACKEND_URL}/referral/cancel/${referralId}`);
  // }

  public getOrderService(orderServiceId: number): Observable<IOrderService> {
    return this.http.get<IOrderService>(`${this.BACKEND_URL}/orderServices/${orderServiceId}`);
  }

  public getOrderServiceComments(
    orderServiceId: number,
    options: { orderServiceTimeId?: number; orderServiceQueueId?: number } = {}
  ): Observable<IOrderServiceComment[]> {
    const params = new URLSearchParams();

    params.append('orderServiceId', orderServiceId.toString());

    if (options.orderServiceTimeId) {
      params.append('orderServiceTimeId', options.orderServiceTimeId.toString());
    }
    if (options.orderServiceQueueId) {
      params.append('orderServiceQueueId', options.orderServiceQueueId.toString());
    }

    const url = `${this.BACKEND_URL}/orderServices/orderServiceComments?${params.toString()}`;
    return this.http.get<IOrderServiceComment[]>(url);
  }

  public saveOrderServiceComment(comment: IOrderServiceComment): Observable<boolean> {
    return this.http.post<boolean>(`${this.BACKEND_URL}/orderServices/saveOrderServiceComment`, comment);
  }

  public deleteOrderServiceComment(params: HttpParams): Observable<boolean> {
    return this.http.delete<boolean>(`${this.BACKEND_URL}/orderServices/deleteOrderServiceComment`, { params });
  }

  public saveFinancialSource(newFinSourceRequest: ChangeFinancialSourceRequest): Observable<any> {
    return this.http.post<any>(`${this.BACKEND_URL}/orderServices/saveFinancialSource`, newFinSourceRequest);
  }

  // public createOrderOtherVisits(params: HttpParams): Observable<IOrder> {
  //   return this.http.get<IOrder>(`${this.BACKEND_URL}/createOrderOtherVisits`, {params});
  // }

  public getFirstBookingTimes(params?: HttpParams): Observable<IBookingTime[]> {
    return this.http.get<IBookingTime[]>(`${environment.backendUrl}/bookings`, { params });
  }

  public changePatient(orderId: number, data: { patientId: number }): Observable<any> {
    return this.http.put<any>(`${this.BACKEND_URL}/changePatient/${orderId}/`, data);
  }

  public changeProvider(orderId: number, data: { providerId: number, departmentId: number }): Observable<IOrder> {
    return this.http.put<any>(`${this.BACKEND_URL}/changeProvider/${orderId}/`, data);
  }

  public getInitExternalMedicalCaseData(params: HttpParams): Observable<any> {
    return this.http.get<any>(`${this.BACKEND_URL}/initExternalMedicalCaseData`, { params });
  }

  // public orderToPosInvoice(registryCode: string, orderNumber: number): Observable<IOrderToPosInvoiceResponse> {
  //   return this.http.get<IOrderToPosInvoiceResponse>(`${this.BACKEND_URL}/orderToPosInvoice/${registryCode}/${orderNumber}`);
  // }

  public deleteReferral(orderServiceId: number): Observable<boolean> {
    return this.http.put<boolean>(`${this.BACKEND_URL}/orderServices/referral/remove/${orderServiceId}`, {});
  }

  public cancelService(orderServiceId: number): Observable<any> {
    return this.http.post<any>(`${this.BACKEND_URL}/orderServices/cancel`, { orderServiceId });
  }

  public changeBooking(changeBookingDto: IChangeBooking): Observable<any> {
    return this.http.post<any>(`${this.BACKEND_URL}/orderServices/changeBooking`, changeBookingDto);
  }
}
