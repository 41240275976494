import { Component, Input, OnInit } from '@angular/core';
import { IPerson } from "@core/interfaces/person.interface";
import { PersonService } from "@core/services/person.service";
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { DepartmentsService } from "@core/services/departments.service";
import { IDepartment } from "@core/interfaces/department.interface";
import { IService } from "@core/interfaces/service.interface";
import { OrderServiceStatus } from "@core/enums/order-service-status.enum";
import { ServiceService } from "@core/services/service.service";
import { HttpParams } from "@angular/common/http";
import { Router } from "@angular/router";
import { idCodeValidator } from "@shared/validators/idcodeValidator";
import { GeneralList } from "@core/enums/general-list.enum";
import { ToastService } from "@core/services/toast.service";
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { MenuItem } from "primeng/api";
import { HREFTarget } from "@core/enums/HREFTarget.enum";
import { AuthService } from "@core/services/auth.service";
import { ICurrentUser } from "@core/interfaces/current-user.interface";

@Component({
  selector: 'app-doctor-edit',
  templateUrl: './doctor-edit.component.html',
  styleUrl: './doctor-edit.component.scss'
})
export class DoctorEditComponent implements OnInit {

  private readonly CREATE_FORM_TITLE = 'Osutaja lisamine';
  private readonly EDIT_FORM_TITLE = 'Osutaja muutmine';

  @Input('id') id?: number;
  isModal?: boolean = false;

  person: IPerson | null = null;
  pageTitle: string = '';

  departments: IDepartment[] = [];
  relatedPersons: IPerson[] = [];
  services: IService[] = [];

  doctorEditForm!: FormGroup;
  currentUser: ICurrentUser | null = null;
  basicEdit: boolean = true;

  get firstName(): FormControl {
    return this.doctorEditForm.get('firstName') as FormControl;
  }

  get lastName(): FormControl {
    return this.doctorEditForm.get('lastName') as FormControl;
  }

  get code(): FormControl {
    return this.doctorEditForm.get('code') as FormControl;
  }

  get personProfessions(): FormArray {
    return this.doctorEditForm.get('personProfessions') as FormArray;
  }

  get workaddressId(): FormControl {
    return this.doctorEditForm.get('workaddressId') as FormControl;
  }

  constructor(
    private personService: PersonService,
    private formBuilder: FormBuilder,
    private departmentsService: DepartmentsService,
    private serviceService: ServiceService,
    private router: Router,
    private toastService: ToastService,
    private dialogConfig: DynamicDialogConfig,
    private authService: AuthService,
    private ref: DynamicDialogRef,
  ) {}

  ngOnInit() {

    this.authService.getCurrentUser().subscribe({
      next: currentUser => {
        this.currentUser = currentUser;
        this.basicEdit =
          this.id == this.currentUser?.personId &&
          !currentUser!.isCustomerSupport;

        if (this.dialogConfig?.modal) {
          this.isModal = this.dialogConfig.modal;
        } else {
          this.pageTitle = this.id ? this.EDIT_FORM_TITLE : this.CREATE_FORM_TITLE;
        }

        if (!this.id && this.dialogConfig?.data?.id) {
          this.id = this.dialogConfig.data.id;
        }

        this.getDepartments();
        this.getProviders();
        this.getServices();

        if (this.id) {
          this.personService.get(this.id).subscribe(
            {
              next: (response: IPerson) => {
                this.person = response;

                this.initEmptyForm();
                this.initBreadcrumbs();
              },
            }
          )
        } else {
          this.initEmptyForm();

        }
      }
    })
  }

  private getDepartments(): void {
    this.departmentsService.getDepartments().subscribe(
      {
        next: (response: IDepartment[]) => {
          this.departments = response;
        },
      }
    );
  }

  private getProviders(): void {
    this.personService.getProvidersForSelect().subscribe(
      {
        next: (response: IPerson[]) => {
          this.relatedPersons = response;
        },
        error: err => {
          this.toastService.error(err.message);
        }
      }
    );
  }

  private getServices(): void {
    this.serviceService.getServices(new HttpParams()
      .append("expandInfo", "PartData")
      .append("expandInfo", "ListData")
      .append("expandInfo", "EmployeeGroups")
      .append("isActive", true)
      .append("pageSize", 1000)
      .append("types", 0)
      .append("types", 3)
    ).subscribe(
      {
        next: (response: IService[]) => {
          this.services = response;
        },
        error: err => {
          this.toastService.error(err.message);
        }
      }
    );
  }

  private initEmptyForm(): void {
    this.doctorEditForm = this.formBuilder.group({
      personId: [this.person?.personId || 0],
      firstName: [{ value: this.person?.firstName || null, disabled: this.basicEdit }, Validators.required],
      lastName: [{ value: this.person?.lastName || null, disabled: this.basicEdit }, Validators.required],
      code: [{ value: this.person?.code || null, disabled: this.basicEdit }, [Validators.required, idCodeValidator()]],

      professionTypeId: [{ value: this.person?.professionTypeId || null, disabled: this.basicEdit }],
      personProfessionCode: [{ value: this.person?.professionId || null, disabled: this.basicEdit }],
      email: [this.person?.email || null],
      mobile: [this.person?.mobile || null],

      languages: [{ value: this.person?.languages || [], disabled: this.basicEdit }],
      DepartmentIds: [{ value: this.person?.personDepartments?.map(x => x.departmentId) || [], disabled: this.basicEdit }],
      workaddressId: [{ value: this.person?.workaddressId || null, disabled: this.basicEdit }],

      doctorCode: [{ value: this.person?.doctorCode || null, disabled: this.basicEdit }],
      professionCode: [{ value: this.person?.professionCode || null, disabled: this.basicEdit }],
      relatedPersonId: [{ value: this.person?.relatedPersonId || null, disabled: this.basicEdit }],
      roomId: [{ value: this.person?.roomId || null, disabled: this.basicEdit }],
      isProvider: [{ value: true, disabled: this.basicEdit }],

      bookingWithoutPlanTime: [{ value: this.person?.bookingWithoutPlanTime || false, disabled: this.basicEdit }],


      personProfessions:
        this.formBuilder.array(this.person?.personProfessions ? this.person?.personProfessions.map((service) => this.formBuilder.group({
          professionId: [{ value: service.professionId || null, disabled: this.basicEdit }],
          personProfessionId: [{ value: service.personProfessionId || null, disabled: this.basicEdit }],
          personId: [{ value: this.id ?? 0, disabled: this.basicEdit }],
          professionCode: [{ value: service.professionCode || null, disabled: this.basicEdit }],
          doctorCode: [{ value: service.doctorCode || null, disabled: this.basicEdit }],
          tehikProfessionCode: [{ value: service.tehikProfessionCode || null, disabled: this.basicEdit }],
        })) : []),
    });
  }

  getPersonProfession(idx: number): FormGroup {
    return this.personProfessions.at(idx) as FormGroup;
  }

  removePersonProfession(event: boolean, idx: number): void {
    if (event && idx >= 0) {
      this.personProfessions.removeAt(idx);
    }
  }

  addPersonProfession() {
    this.personProfessions.push(this.formBuilder.group({
      professionId: [{ value:  null, disabled: this.basicEdit }],
      personProfessionId: [{ value:  null, disabled: this.basicEdit }],
      personId: [{ value: this.id ?? 0, disabled: this.basicEdit }],
      professionCode: [{ value:  null, disabled: this.basicEdit }],
      doctorCode: [{ value:  null, disabled: this.basicEdit }],
      tehikProfessionCode: [{ value:  null, disabled: this.basicEdit }],
    }));
  }

  submit() {
    if (this.doctorEditForm.invalid) {
      return;
    }

    if(this.basicEdit){
      this.personService.saveBasic({
        email: this.doctorEditForm.get('email')?.value,
        phone: this.doctorEditForm.get('mobile')?.value,
      }).subscribe({
        next: (response: IPerson) => {
          if (this.isModal) {
            this.ref.close(response);
          } else {
            this.router.navigate([`doctors/${this.id}/details`])
          }
        }
      })
    } else {
      this.personService.save(this.doctorEditForm.getRawValue()).subscribe(
        {
          next: (response: IPerson) => {
            if (this.isModal) {
              this.ref.close(response);
            } else {
              this.router.navigate([`doctors/${response.personId}/details`])
            }
          }
        })
    }
  }

  cancel(): void {
    if (this.isModal) {
      this.ref.close();
    } else {
      this.router.navigate(this.id ? [`doctors/${this.id}/details`] : [`doctors`])
    }
  }

  serviceLanguages = [
    { key: 'et', value: 'Eesti' },
    { key: 'en', value: 'Inglise' },
    { key: 'fi', value: 'Soome' },
    { key: 'ru', value: 'Vene' },
    { key: 'by', value: 'Valgevene' },
    { key: 'de', value: 'Saksa' },
    { key: 'fr', value: 'Prantsusmaa' },
    { key: 'ua', value: 'Ukraina' },
  ];

  protected readonly OrderServiceStatus = OrderServiceStatus;
  protected readonly GeneralList = GeneralList;
  breadcrumbs: MenuItem[] | undefined;

  private initBreadcrumbs() {
    this.breadcrumbs = [
      {
        label: "Osutajad",
        url: "doctors",
        target: HREFTarget.SELF
      },
      {
        label: `${this.person?.fullName}`,
        url: `doctors/${this.person?.personId}/details`,
        target: HREFTarget.SELF
      },
      {
        label: this.pageTitle,
        disabled: true
      },
    ]
  }
}
