import { FinSourceId } from "@core/enums/financial-source.enum";

export enum FinancialSource {
  INSURANCE = 'Tervisekassa',
  PERSON = 'Patsient',
  // UNEMPLOYMENT_INSURANCE = 'Töötukassa',
  // SOCIAL_INSURANCE = 'Sotsiaalkindlustusamet',
  ANOTHER_JURIDICAL_PERSON = 'Teine juriidiline isik',
}

export const FinancialIdBySource: Record<FinancialSource, FinSourceId> = {
  [FinancialSource.INSURANCE]: FinSourceId.PayerHealthInsuranceFund,
  [FinancialSource.PERSON]: FinSourceId.PayerPerson,
  [FinancialSource.ANOTHER_JURIDICAL_PERSON]: FinSourceId.AnotherJuridicalPerson,
}

