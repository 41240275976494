import { Component, OnInit } from '@angular/core';
import { IOrderServiceTime } from "@core/interfaces/order-service-time.interface";
import { OrderService } from "@core/services/order.service";
import { IOrderServiceComment } from "@core/interfaces/order-service-comment.interface";
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { DateTimeDisplayPipe } from "../../pipes/date-time-display.pipe";
import { HttpParams } from "@angular/common/http";
import { AuthService } from "@core/services/auth.service";
import { ICurrentUser } from "@core/interfaces/current-user.interface";
import { Button } from "primeng/button";
import { DialogService, DynamicDialogComponent, DynamicDialogRef } from "primeng/dynamicdialog";
import { InputTextareaModule } from "primeng/inputtextarea";
import { FloatLabelModule } from "primeng/floatlabel";
import { TableModule } from "primeng/table";
import { ToastService } from "@core/services/toast.service";
import { IOrderService } from "@core/interfaces/order-service.interface.interface";
import { ConfirmActionDirective } from "@shared/directives/confirm-action/confirm-action.directive";
import { finalize } from "rxjs";
import { DateTimePipe } from "@shared/pipes/datetime.pipe";

@Component({
  selector: 'medis-comment-order-actions-modal',
  standalone: true,
  templateUrl: './comment-order-actions.modal.component.html',
  imports: [
    ReactiveFormsModule,
    DateTimeDisplayPipe,
    Button,
    InputTextareaModule,
    FloatLabelModule,
    TableModule,
    ConfirmActionDirective,
    DateTimePipe,
  ],
})
export class CommentOrderActionsModalComponent implements OnInit {

  commentForm!: FormGroup;
  orderServiceObj!: IOrderServiceTime | IOrderService;
  commentLoading = false;
  comments: IOrderServiceComment[] = [];
  currentUser!: ICurrentUser | null;

  modalInstance: DynamicDialogComponent | undefined;

  constructor(
    private ref: DynamicDialogRef,
    private orderService: OrderService,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private dialogService: DialogService,
    private toastService: ToastService,
  ) {
    this.modalInstance = this.dialogService.getInstance(this.ref);
  }

  ngOnInit(): void {
    if (!this.modalInstance?.data) {
      console.error('Modal instance or data is not available.');
      return;
    }

    this.createForm();

    if (this.authService.hasAccessToken()) {
      this.currentUser = this.authService.currentUserSubject.value;

      if (!this.currentUser) {
        this.authService.getCurrentUser().subscribe({
          next: (user) => {
            this.currentUser = user;
            this.initializeOrderServiceObj();
          },
          error: (err: any) => {
            console.error("User is not authenticated. Current user data not set.", err);
          },
        });
      } else {
        this.initializeOrderServiceObj();
      }
    } else {
      console.error('User is not authenticated. Current user data not set.');
      return;
    }
  }

  private initializeOrderServiceObj(): void {
    this.orderServiceObj = this.modalInstance!.data['orderServiceObj'];

    if (this.orderServiceObj) {
      this.getComments();
    } else {
      console.warn('OrderServiceId is missing. Skipping comments fetch.');
    }
  }

  private createForm() {
    this.commentForm = this.formBuilder.group({
      comment: [null],
    })
  }

  closeModal(): void {
    this.ref!.close(this.comments);
  }

  private getComments = (): void => {
    if (!this.orderServiceObj || !this.orderServiceObj.orderServiceId) {
      this.toastService.error('orderServiceId is required to fetch comments.');
      return;
    }

    this.commentLoading = true;

    const { orderServiceId } = this.orderServiceObj;
    let orderServiceTimeId: number | undefined;
    let orderServiceQueueId: number | undefined;

    if (this.isOrderServiceTime(this.orderServiceObj)) {
      orderServiceTimeId = this.orderServiceObj.orderServiceTimeId;
      orderServiceQueueId = this.orderServiceObj.orderServiceQueueId;
    }

    const request = this.orderService.getOrderServiceComments(orderServiceId, {
      orderServiceTimeId,
      orderServiceQueueId,
    });

    request.subscribe({
      next: (comments) => {
        this.comments = comments;
        this.commentLoading = false;
      },
      error: (err) => {
        this.toastService.error(err.message || 'Failed to load comments.');
        this.commentLoading = false;
      }
    });
  };

  private isOrderServiceTime(obj: any): obj is IOrderServiceTime {
    return 'orderServiceTimeId' in obj || 'orderServiceQueueId' in obj;
  }

  saveComment = () => {
    if (!this.orderServiceObj || !this.orderServiceObj.orderServiceId) {
      this.toastService.error('orderServiceId is required to fetch comments.');
      return;
    }

    const commentText = (this.comment.value || '').trim();
    if (!commentText) {
      return;
    }

    this.commentLoading = true;

    let comment: IOrderServiceComment = {
      orderServiceId: this.orderServiceObj.orderServiceId,
      comment: commentText,
      userName: this.currentUser ? `${this.currentUser.firstName} ${this.currentUser.lastName}` : '',
      userIdentifier: this.currentUser ? this.currentUser.email : '',
    };


    if (this.isOrderServiceTime(this.orderServiceObj)) {
      comment = {
        ...comment,
        orderServiceTimeId: this.orderServiceObj.orderServiceTimeId,
        orderServiceQueueId: this.orderServiceObj.orderServiceQueueId,
      }
    }

    this.orderService
      .saveOrderServiceComment(comment)
      .pipe(finalize(() => {
        this.commentLoading = false;
      }))
      .subscribe({
        next: () => {
          this.getComments();
          this.comment.setValue('');
        }
      })
  };

  deleteComment = (event: boolean, orderServiceCommentId: number) => {
    if (!event) return;

    this.commentLoading = true;

    const params = new HttpParams()
      .set('orderServiceCommentId', orderServiceCommentId);

    this.orderService.deleteOrderServiceComment(params)
      .subscribe({
        next: () => {
          this.getComments();
          this.commentLoading = false;
        },
        error: (err) => {
          this.toastService.error(err.message);
        }
      });
  };

  get comment(): FormControl {
    return this.commentForm.get('comment') as FormControl;
  }
}
