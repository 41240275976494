import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { LocationService } from "@core/services/location.service";
import { IRoom } from "@core/interfaces/room.interface";
import { HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";

@Component({
  selector: 'medis-room-select-list',
  template: `
    <p-floatLabel>
      <p-dropdown
        [id]="formControlName"
        class="w-full"
        styleClass="w-full"
        [options]="rooms"
        [filter]="rooms.length > 15"
        filterBy="codeName"
        [(ngModel)]="selectedRoomId"
        [showClear]="true"
        (onChange)="onSelect($event.value)"
        optionValue="roomId"
        [disabled]="disabled"
        appendTo="body"
      >
        <ng-template pTemplate="selectedItem">
          @if (selectedRoom) {
            <div class="flex align-items-center gap-2">
              <div>{{ selectedRoom.codeName + (selectedRoom.roomDepartment ? ' | ' + selectedRoom.roomDepartment : '') }}</div>
            </div>
          }
        </ng-template>
        <ng-template let-room pTemplate="item">
          <div class="flex align-items-center gap-2">
            <div>{{ room.codeName + (room.roomDepartment ? ' | ' + room.roomDepartment : '') }}</div>
          </div>
        </ng-template>
      </p-dropdown>
      <label [for]="formControlName">{{ labelName }}</label>
    </p-floatLabel>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RoomSelectListComponent),
      multi: true
    }
  ]
})
export class RoomSelectListComponent implements OnInit, ControlValueAccessor {
  @Input() formControlName: string = '';
  @Input() labelName: string = '';
  addressId?: number;
  @Input() set addressSet(value: any) {
    this.addressId = value;
    this.getRooms();
  }

  @Output() onRoomChange = new EventEmitter<IRoom>();

  rooms: IRoom[] = [];
  selectedRoomId?: number;
  selectedRoom: IRoom | undefined;
  isDisabled = false;
  @Input() disabled: boolean = false;

  constructor(private locationService: LocationService) { }

  ngOnInit(): void {
    this.getRooms();
  }

  getRooms(): void {

    let request: Observable<IRoom[]>;
    if(this.addressId){
      let params = new HttpParams()
        .set('addressId', this.addressId);
      request = this.locationService.getRooms(params);
    } else {
      request = this.locationService.getAllRooms();
    }

    request.subscribe({
      next: (rooms: IRoom[]) => {
        this.rooms = rooms;
        this.selectedRoom = this.rooms.find(room => room.roomId === this.selectedRoomId);
        if(!this.selectedRoom) this.selectedRoomId = undefined;
      }
    })

  }

  onChange = (value: any) => {};
  onTouched = () => {};

  writeValue(value: any): void {
    this.selectedRoomId = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  onSelect(value: any) {
    this.selectedRoomId = value;
    this.selectedRoom = this.rooms.find(room => room.roomId === this.selectedRoomId);
    this.onRoomChange.emit(this.selectedRoom);
    this.onChange(value);
    this.onTouched();
  }

}
