import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { IPersonService } from '@core/interfaces/person-service.interface';
import { IFinancialSource } from "@core/interfaces/financial-source.interface";
import { STATE_OPTIONS } from "@shared/modals/plantime-mass-edit/plantime-mass-edit.modal";

@Component({
  selector: 'medis-service-action',
  templateUrl: 'service-action.component.html',
})
export class ServiceActionComponent implements OnChanges, OnInit {
  @Input() form!: FormGroup;
  @Input() selectedService: IPersonService | null | undefined = null;
  @Input() personServices: IPersonService[] = [];
  @Input() actionValue: string = '';

  @Output() stateOptionValueChange = new EventEmitter<string>();
  @Output() serviceChange = new EventEmitter<any>();
  @Output() finSourceChange = new EventEmitter<any>();

  stateOptions: any[] = [
    { label: 'Kustuta', value: STATE_OPTIONS.DELETE },
    { label: 'Lisa', value: STATE_OPTIONS.ADD },
  ];

  paymentOptions: { label: string; value: number }[] = [];

  ngOnInit() {
    if (this.personServices) {
      this.setDefaultValue(this.personServices)
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedService'] && this.selectedService) {
      this.generatePaymentOptions(this.selectedService);
    }
  }

  private setDefaultValue(data: IPersonService[]){
    if(Array.isArray(data) && data.length > 0){
      this.form.get("personServiceId")?.setValue(data[0].personServiceId);
    } else {
      return;
    }
  }

  private generatePaymentOptions(service: IPersonService): void {
    this.paymentOptions = [];
    const financialSources = service.service?.financialSources || [];

    if (financialSources.length > 0) {
      financialSources.forEach(source => {
        this.paymentOptions.push({
          label: source.financialSourceName || '',
          value: source.financialSourceId || 0,
        });
      });

      const defaultSource = financialSources.find(source => source.isDefault);
      const selectedSource: IFinancialSource = defaultSource || financialSources[0];

      if (selectedSource) {
        this.finSourceChange.emit({ value: selectedSource.financialSourceId, label: selectedSource.financialSourceName });
      }
    }
  }

  onStateOptionChange(event: any){
    this.stateOptionValueChange.emit(event.value);
  }

  onFinancialSourceChange(event: any): void {
    const selectedSource = this.paymentOptions.find(
      (source) => source.value === event.value
    );

    if (selectedSource && this.selectedService) {
      this.finSourceChange.emit({ value: selectedSource.value, label: selectedSource.label });
    } else {
      console.warn('No matching financial source found for value:', event.value);
      return;
    }
  }

  protected readonly STATE_OPTIONS = STATE_OPTIONS;
}
