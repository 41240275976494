import { Component, Input } from "@angular/core";
import { IReferral } from "@core/interfaces/referral.interface";
import { PatientService } from "@core/services/patient.service";
import { HtmlModalComponent } from "@shared/components/html-modal/html-modal.component";
import { ModalSize } from "@core/enums/modalSize";
import { ModalService } from "@core/services/modal.service";
import { IPatient } from "@core/interfaces/patient.interface";

@Component({
  selector: "medis-selected-referral",
  templateUrl: "./selected-referral.component.html",
})


export class SelectedReferralComponent {

  @Input() selectedReferral!: IReferral;
  @Input() patient!: IPatient;

  constructor(private patientService: PatientService,
              private modalService: ModalService) {
  }

  showReferralInfo(referral: IReferral) {
    if (referral.referralId) {
      this.patientService.getReferralTehikHtml(referral.referralId).subscribe({
        next: (data: any) => {
          this.openHtmlModal(data.data);
        }
      })
    } else {
      this.patientService.getReferralTehikHtmlWithoutReferralId(referral.oid, referral.documentNr, this.patient?.patientId ?? 0).subscribe(
        {
          next: (data: any) => {
            this.openHtmlModal(data.data);
          }
        })
    }
  }

  private openHtmlModal(html: string) {
    this.modalService.openModal(
      {
        component: HtmlModalComponent,
        size: ModalSize.EXTRA_LARGE,
        header: 'Saatekiri',
        contentStyle: { 'padding-left' : 0 },
        data: {
          html: html
        }
      }
    )
  }

}
