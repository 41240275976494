import { Component, OnInit } from '@angular/core';
import { AuthService } from "@core/services/auth.service";
import { Router } from "@angular/router";
import { interval, Observable, Subscription } from "rxjs";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'medis-web-frontend';
  sidebarOpen: boolean = false;
  authorized = false;
  sub?: Subscription;

  constructor(
    private authService: AuthService,
    private activatedRoute : Router,
  ) {
  }

  ngOnInit() {
    const savedSidebarState = localStorage.getItem('sidebarOpen');
    this.sidebarOpen = savedSidebarState === 'true';

    this.sub = interval(100000).subscribe(() => {
      if (this.authorized) {
        this.authService.extendSession();
      }
    });

    this.activatedRoute.events.subscribe(url => {
      this.authorized = this.authService.hasAccessToken();
      if(!this.authorized && this.sub){
        this.sub.unsubscribe();
      }
    });
  }

}
