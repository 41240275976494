<div class="flex flex-column h-screen w-screen">
  @if (authorized) {
  <medis-header [(sidebarOpen)]="sidebarOpen"></medis-header>
}

  <main class="page flex flex-grow-1 w-full h-full overflow-hidden surface-ground">
    @if (authorized) {
      <medis-sidebar [sidebarOpen]="sidebarOpen"></medis-sidebar>
    }
    <div class="page__content flex-grow-1 overflow-y-auto overflow-x-hidden pb-4">
      <router-outlet>
      </router-outlet>
    </div>
  </main>
</div>

<medis-confirm-action-outlet></medis-confirm-action-outlet>
<p-toast
  position="bottom-right"
/>

