@if (!isMassUpdateResponseReceived) {
  <div class="flex flex-column pb-4 gap-4">
    <div class="flex row gap-4 w-12 mt-4">
      <div class="flex flex-column w-6">
        <form
          role="form"
          [formGroup]="form"
        >
          <p-iconField>
            <p-floatLabel>
              <p-dropdown
                [options]="persons"
                formControlName="selectedPersonId"
                [filter]="true"
                filterBy="fullName"
                optionLabel="fullName"
                optionValue="personId"
                id="person"
                styleClass="w-full"
                appendTo="body"
                (onChange)="onProviderChange($event)"
              />
              <label for="person">Osutaja</label>
              @if(isLoadingPerson){
                <p-inputIcon styleClass="pi pi-spinner pi-spin" />
              }
            </p-floatLabel>
          </p-iconField>
        </form>
      </div>

      <div class="flex flex-column w-6">
        <p-floatLabel>
          <p-dropdown
            id="edit-action"
            [options]="editActionsOptions"
            [(ngModel)]="selectedEditAction"
            optionLabel="label"
            optionValue="value"
            styleClass="w-full"
            appendTo="body"
            (onChange)="onActionChange($event)"
          />
          <label for="edit-action">Muutmise tegevus</label>
        </p-floatLabel>
      </div>
    </div>

    @if (selectedEditAction && selectedEditAction != EDIT_ACTIONS.CHOOSE_ACTION) {
      <p-divider align="center" type="solid" >
        <b>Vali periood</b>
      </p-divider>

      <form
        role="form"
        [formGroup]="form"
      >
        <div class="flex row gap-4 w-12">
          <div class="flex row w-6 gap-2">
            <div class="form-section__col flex flex-column w-6">
              <p-floatLabel>
                <p-calendar
                  [iconDisplay]="'input'"
                  [showIcon]="true"
                  id="startDate"
                  dateFormat="dd.mm.yy"
                  appendTo="body"
                  formControlName="dateFrom"
                  [ngClass]="{'ng-invalid ng-dirty': formSubmitted && dateFrom.errors}"
                ></p-calendar>
                <label for="startDate">Algus</label>
              </p-floatLabel>
              @if (formSubmitted && dateFrom.errors) {
                <small class="p-error"> Algkuupäev on kohustuslik </small>
              }
            </div>
            <div class="form-section__col flex flex-column w-6">
              <p-floatLabel>
                <p-calendar
                  [iconDisplay]="'input'"
                  [showIcon]="true"
                  id="endDate"
                  dateFormat="dd.mm.yy"
                  formControlName="dateTo"
                  appendTo="body"
                  [ngClass]="{'ng-invalid ng-dirty': formSubmitted && dateTo.errors}"
                ></p-calendar>
                <label for="endDate">Lõpp</label>
              </p-floatLabel>
              @if (formSubmitted && dateTo.errors) {
                <small class="p-error"> Lõppkuupäev on kohustuslik </small>
              }
            </div>
          </div>
          <div class="flex row w-6 gap-2">
            <p-floatLabel>
              <p-calendar
                [iconDisplay]="'input'"
                [showIcon]="true"
                [timeOnly]="true"
                id="startTime"
                formControlName="timeFrom"
                appendTo="body"
              >
                <ng-template
                  pTemplate="inputicon"
                  let-clickCallBack="clickCallBack"
                >
                  <i class="pi pi-clock" (click)="clickCallBack($event)"></i>
                </ng-template>
              </p-calendar>
              <label for="startTime">Algusaeg</label>
            </p-floatLabel>
            <p-floatLabel>
              <p-calendar
                [iconDisplay]="'input'"
                [showIcon]="true"
                [timeOnly]="true"
                id="endTime"
                formControlName="timeTo"
                appendTo="body"
              >
                <ng-template
                  pTemplate="inputicon"
                  let-clickCallBack="clickCallBack"
                >
                  <i class="pi pi-clock" (click)="clickCallBack($event)"></i>
                </ng-template>
              </p-calendar>
              <label for="endTime">Lõppaeg</label>
            </p-floatLabel>
          </div>
        </div>
      </form>
    }

    @if(selectedEditAction !== EDIT_ACTIONS.CHOOSE_ACTION && selectedEditAction !== EDIT_ACTIONS.DELETE_PLAN_TIMES){
      <p-divider align="center" type="solid">
        <b>{{ getDividerLabel(selectedEditAction) }}</b>
      </p-divider>
    }

    @if (selectedEditAction === EDIT_ACTIONS.TOGGLE_ACTIVATION) {
      <div class="flex w-full justify-content-center">
        <p-selectButton
          [options]="ACTIVATION_OPTIONS"
          [(ngModel)]="selectedActivationOption"
          optionLabel="label"
          optionValue="value" />
      </div>
    }

    @if(selectedEditAction === EDIT_ACTIONS.EDIT_SERVICE){
      <medis-service-action
        [form]="form"
        [personServices]="personServices"
        [selectedService]="selectedService"
        [actionValue]="actionValue"
        (stateOptionValueChange)="onStateOptionChange($event)"
        (finSourceChange)="onFinSourceChange($event)"
      ></medis-service-action>
    }

    @if (selectedEditAction === EDIT_ACTIONS.EDIT_RELATED_PERSON) {
      <medis-related-person-action
        [form]="form"
        [personId]="selectedPersonId.value"
        [allProviders]="persons"
        [actionValue]="actionValue"
        (stateOptionValueChange)="onStateOptionChange($event)"
      ></medis-related-person-action>
    }

    @if (selectedEditAction === EDIT_ACTIONS.EDIT_ROOM) {
      <medis-room-action
        [form]="form"
      ></medis-room-action>
    }

    <div class="flex row justify-content-end gap-2">
      <p-button label="Katkesta" [outlined]="true" severity="secondary" (onClick)="closeModal()"/>
      <p-button label="Salvesta" severity="success" (onClick)="saveForm()" [disabled]="formSubmitted"/>
    </div>
  </div>
}

@if (isMassUpdateResponseReceived) {
  <medis-plantime-mass-update-report
    [massUpdateResponse]="massUpdateResponse"
    (continueEdit)="restartMassEditModal()"
    (onClose)="closeModal()">
  </medis-plantime-mass-update-report>
}
