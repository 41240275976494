import { Component, OnInit } from '@angular/core';
import { DialogService, DynamicDialogComponent, DynamicDialogRef } from "primeng/dynamicdialog";
import { EConsultationService } from "@core/services/e-consultation.service";
import { IEConsultation } from "@core/interfaces/e-consultation.interface";
import { ToastService } from "@core/services/toast.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { EConsultationStatus } from "@core/enums/e-consultation-status.enum";

@Component({
  selector: 'medis-modal-e-consultation',
  templateUrl: './e-consultation-reply.modal.component.html',
  styleUrl: './e-consultation-reply.modal.component.scss'
})
export class EConsultationReplyModalComponent implements OnInit {
  eConsultationForm!: FormGroup;
  formSubmitted = false;
  dataUpdated = false;
  eConsultationId!: number;
  readonly: boolean = false;
  eConsultation!: IEConsultation;
  decisionCodes: any[] = [
    { label: 'e-konsultatsioon', value: '1' },
    { label: 'vastuvõtt 7p jooksul', value: '2' },
    { label: 'vastuvõtt 8-42p jooksul', value: '3' },
    { label: 'vastuvõtt rohkem kui 42p pärast', value: '4' },
    { label: 'mittesobiv saatekiri', value: '5' },
    { label: 'Patsient suunatakse haiglaravile', value: '8' },
    { label: 'Patsient suunatakse päevaravile/päevakirurgiasse', value: '9' },
  ];

  instance: DynamicDialogComponent | undefined;

  constructor(
    private ref: DynamicDialogRef,
    private dialogService: DialogService,
    private eConsultationService: EConsultationService,
    private toastService: ToastService,
    private formBuilder: FormBuilder,
  ) {
    this.instance = this.dialogService.getInstance(this.ref);
  }

  ngOnInit(): void {
    if (this.instance && this.instance.data) {
      this.eConsultationId = this.instance.data['eConsultationId'];
      this.readonly = this.instance.data['readonly'];
      if (this.eConsultationId) {
        this.loadEConsultation();
      }
    }
  }

  private createEConsultationForm(): void {
    this.eConsultationForm = this.formBuilder.group({
      decisionCode: [this.eConsultation?.decisionCode ?? null, Validators.required],
      decision: [this.eConsultation?.decision ?? null, Validators.required],
      comment: [this.eConsultation?.comment ?? null],
    })
  }

  reopen(): void {
    this.eConsultation.status = EConsultationStatus.ASSIGNED;
  };

  resolveEConsultation(): void {
    this.formSubmitted = true;
    if (this.eConsultationForm.invalid) {
      return;
    }

    this.updateEConsultationData();

    this.eConsultationService.resolve(this.eConsultation).subscribe({
      next: () => {
        this.dataUpdated = true;
        this.loadEConsultation();
      },
      error: (err) => {
        this.toastService.error(err.message || 'Salvestamine ebaõnnestus!');
      }
    })
  };

  private updateEConsultationData(): void {
    this.eConsultation.status = EConsultationStatus.RESOLVED;
    this.eConsultation.decisionCode = this.eConsultationForm.get('decisionCode')?.value;
    this.eConsultation.decision = this.eConsultationForm.get('decision')?.value;
    this.eConsultation.comment = this.eConsultationForm.get('comment')?.value;
  }

  private loadEConsultation() {
    this.eConsultationService.get(this.eConsultationId).subscribe({
      next: (response) => {
        this.eConsultation = response;
        this.eConsultation.isVisitInProviderQueue = this.eConsultation.misProviderQueueTime !== null;
        this.createEConsultationForm();
      },
      error: (error) => {
        this.toastService.error(error.message);
      }
    })
  }

  confirmEConsultation(): void {
    this.eConsultation.status = EConsultationStatus.CONFIRMED;

    this.eConsultationService.confirm(this.eConsultation).subscribe({
      next: () => {
        this.toastService.success('E-konsultatsioon edukalt kinnitatud!');
        this.ref.close(this.dataUpdated);
      },
      error: (error) => {
        this.toastService.error(error.message);
      }
    })
  };

  closeModal(): void {
    this.ref.close(this.dataUpdated);
  }

  protected readonly EConsultationStatus = EConsultationStatus;
}
