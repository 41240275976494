import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "@environments/environment";
import { Observable } from "rxjs";
import { IRoom } from "../interfaces/room.interface";
import { IRoomType } from "../interfaces/room-type.interface";
import { IAddress } from "../interfaces/address.interface";
import { IAddAddress } from "@core/interfaces/add-address.interface";

@Injectable({
  providedIn: 'root'
})
export class LocationService {
  private readonly BACKEND_URL: string;

  constructor(private http: HttpClient) {
    this.BACKEND_URL = `${environment.backendUrl}/locations`;
  }

  public getRoom(roomId: number): Observable<IRoom> {
    return this.http.get<IRoom>(`${this.BACKEND_URL}/rooms/${roomId}`);
  }

  public saveRoom(room: IRoom): Observable<IRoom> {
    return this.http.post<IRoom>(`${this.BACKEND_URL}/rooms`, room);
  }

  public getAllRooms(): Observable<IRoom[]> {
    return this.http.get<IRoom[]>(`${this.BACKEND_URL}/rooms/all`);
  }

  public getRooms(params: HttpParams): Observable<IRoom[]> {
    return this.http.get<IRoom[]>(`${this.BACKEND_URL}/rooms`, {params});
  }

  public getAvailableRooms(date: string, timeFrom: string, timeUpto: string, planTimeId: number): Observable<IRoom[]> {
    return this.http.get<IRoom[]>(`${this.BACKEND_URL}/rooms/available/${date}/${timeFrom}/${timeUpto}/${planTimeId}`);
  }
  public deleteRoomById(roomId: number): Observable<any> {
    return this.http.delete(`${this.BACKEND_URL}/rooms/${roomId}`);
  }

  // public getAvailableRooms(date: string, timeFrom: string, timeUpto: string, planTimeId: number): Observable<IRoom[]> {
  //   return this.http.get<IRoom[]>(`${this.BACKEND_URL}/rooms/available/${date}/${timeFrom}/${timeUpto}/${planTimeId}`);
  // }
  //
  // public getRoomsByAddress(addressId: any = null, page: any = null, pageSize: any = null): Observable<IRoom[]> {
  //   return this.http.get<IRoom[]>(`${this.BACKEND_URL}/rooms/${addressId}/${page}/${pageSize}`);
  // }
  //
  // public getRoomsByType(roomTypeId: number): Observable<IRoom[]> {
  //   return this.http.get<IRoom[]>(`${this.BACKEND_URL}/rooms/roomswithtype/${roomTypeId}`);
  // }

  public getRoomTypes(): Observable<IRoomType[]> {
    return this.http.get<IRoomType[]>(`${this.BACKEND_URL}/rooms/types`);
  }

  // public saveRoomType(roomType: IRoomType): Observable<IRoomType> {
  //   return this.http.post<IRoomType>(`${this.BACKEND_URL}/rooms/types`, roomType);
  // }

  // public getPlanTimes(roomId: number, providerId: number, date: string): Observable<IPlanTime[]> {
  //   return this.http.get<IPlanTime[]>(`${this.BACKEND_URL}/rooms/planTimes/${roomId}/${providerId}/${date}`);
  // }

  public saveAddress(address: IAddAddress): Observable<IAddress> {
    return this.http.post<IAddress>(`${this.BACKEND_URL}/addresses`, address);
  }

  public getAllAddresses(): Observable<IAddress[]> {
    return this.http.get<IAddress[]>(`${this.BACKEND_URL}/addresses`);
  }

  public getWorkAddresses(): Observable<IAddress[]> {
    return this.http.get<IAddress[]>(`${this.BACKEND_URL}/addresses/work`);
  }
}
