import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { Router } from "@angular/router";
import { jwtDecode } from "jwt-decode";
import { ToastService } from "@core/services/toast.service";

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptorService implements HttpInterceptor {

  constructor(private router: Router, private authService: AuthService, private toastService: ToastService) {
  }

  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    if (err.status === 401 || err.status === 403) {
      this.authService.clearCurrentUser();
      if(this.authService.getAccessToken()){
        this.router.navigate(['/auth']).then( () => {
          window.location.reload();
        });
      }
      this.authService.removeAccessToken();
    }
    return throwError(() => err);
  }

  private validateToken(): void {
    try {
      const authToken = this.authService.getAccessToken();
      if (authToken) {
        const decodedToken: any = jwtDecode(authToken);
        const jwtExp = decodedToken?.exp;
        const currentTime = Math.floor(Date.now() / 1000);

        if (!jwtExp || (jwtExp < currentTime)) {
          this.authService.removeAccessToken();
          this.router.navigate(['/auth']);
        }
      } else {
        this.router.navigate(['/auth']);
      }
    } catch (error: any) {
      this.authService.removeAccessToken();
      this.router.navigate(['/auth']);
    }
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let request = req;
    this.validateToken();
    let headers: any = {};
    if (this.authService.hasAccessToken()) {
      const authToken = this.authService.getAccessToken();
      headers.Authorization = `Bearer ${authToken}`;
    }
    request = req.clone({
      setHeaders: headers
    });
    return next.handle(request)
      .pipe(catchError(x => this.handleAuthError(x)));
  }
}
