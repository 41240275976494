<p-confirmDialog #confirmActionRef key="confirm-action">
  <ng-template pTemplate="headless" let-message>
    <div class="flex flex-column align-items-center p-5 surface-overlay border-round max-w30rem">
      <div class="border-circle {{ message.iconBg ?? defaultText.iconBg }} inline-flex justify-content-center align-items-center h-6rem w-6rem">
        <i class="pi {{ message.icon ?? defaultText.icon }} text-5xl"></i>
      </div>
      <span class="font-bold text-2xl block mb-2 mt-4">
        {{ message.header ?? defaultText.header }}
      </span>
      <p class="mb-0 text-center" [innerHTML]="message.message ?? defaultText.message"></p>
      <div class="flex align-items-center gap-2 mt-4">
        <button pButton
                [outlined]="true"
                severity="secondary"
                [label]="message.rejectLabel ?? defaultText.rejectLabel"
                (click)="confirmActionRef.reject()"
        ></button>
        <button pButton
                [label]="message.acceptLabel ?? defaultText.acceptLabel"
                (click)="confirmActionRef.accept()"
        ></button>
      </div>
    </div>
  </ng-template>
</p-confirmDialog>
