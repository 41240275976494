import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, Renderer2 } from '@angular/core';
import { IPatient } from "@core/interfaces/patient.interface";
import { Router } from "@angular/router";
import { OverlayPanel } from "primeng/overlaypanel";
import { ModalSize } from "@core/enums/modalSize";
import { ExternalMedicalCaseService } from "@core/services/external-medical-case.service";
import { AuthService } from "@core/services/auth.service";
import { ICurrentUser } from "@core/interfaces/current-user.interface";
import { ModalService } from "@core/services/modal.service";
import { ProductSaleModalComponent } from "@shared/modals/product-sale/product-sale.modal.component";
import { PatientService } from "@core/services/patient.service";
import { HttpParams } from "@angular/common/http";
import { formatDate } from "@angular/common";
import { BookingsModalComponent } from "@shared/modals/bookings/bookings.modal";
import { SstService } from "@core/services/sst.service";
import { CreateNewReferralComponent } from "@shared/components/create-new-referral/create-new-referral.component";
import { IOrder } from "@core/interfaces/order.interface";
import { IService } from "@core/interfaces/service.interface";
import { ExternalReferralService } from "@core/services/external-referral.service";

@Component({
  selector: 'app-single-patient-search',
  templateUrl: './single-patient-search.component.html'
})
export class SinglePatientSearchComponent implements OnInit, OnChanges {
  @Input() data!: IPatient;
  @Input() showActions: boolean = true;
  @Input() simpleView: boolean = false;
  @Output() clearSearch = new EventEmitter<void>();
  documentClickListener: (() => void) | null = null;
  futureBookings: any[] = [];
  futureBookingsCount: number = 0;
  isUpdatedLongTimeAgo: boolean = false;
  sixMonthsAgo: Date | null = null;

  isMenuOpen: boolean = false;

  actions = [
    {
      label: 'Koondvaade',
      command: () => this.sendOpenPatientMessage()
    },
    {
      label: 'Ava TSK',
      command: () => this.setSSTMessage()
    },
    {
      label: 'Pildipank',
      command: () => this.imageBankUrl()
    },
    {
      label: 'Alusta müüki',
      command: () => this.startSelling()
    },
    {
      label: 'Lisa saatekiri',
      command: () => this.addReferral()
    }
  ];

  constructor(
    private router: Router,
    private renderer: Renderer2,
    private externalMedicalCaseService: ExternalMedicalCaseService,
    private authService: AuthService,
    private el: ElementRef,
    private modalService: ModalService,
    private patientService: PatientService,
    private sstService: SstService,
    private externalReferralService: ExternalReferralService,
  ) {
    const today = new Date();
    this.sixMonthsAgo = new Date(today.setMonth(today.getMonth() - 6));
  }

  ngOnInit() {
    if (!this.data.gpTeamLastProviderId || !this.data.gpTeamLastProviderName) {
      this.getPatientById(this.data.patientId);
    }
    if(!this.showActions){
      let params = new HttpParams();
      params = params.append('patientId', this.data.patientId)
      params = params.append('dateFrom', this.formatDateISO(new Date()))
      this.patientService.getBookings(params).subscribe({
        next: (bookings) => {
          this.futureBookings = this.getFutureBookings(bookings);
          this.futureBookingsCount = this.futureBookings.length;
        },
        error: (err) => {
          console.error("Error fetching bookings", err);
        }
      })
    }
  }

  ngOnChanges() {
    if (this.data.modified && this.sixMonthsAgo) {
      this.isUpdatedLongTimeAgo = new Date(this.data.modified) < this.sixMonthsAgo;
    } else {
      this.isUpdatedLongTimeAgo = false;
    }
  }

  private getPatientById(id: number) {
    this.patientService.get(id).subscribe({
      next: (patient: IPatient) => {
        this.data = patient;
      },
      error: (err) => {
        console.error("Error fetching patient", err);
      }
    })
  }

  getFutureBookings(bookings: any[]){
    const futureBookings: any[] = []
    const today = new Date();
    const todayDate = today.toLocaleDateString();
    const todayCommonTime = today.toLocaleTimeString().split(" ");
    const todayTime = todayCommonTime[0];

    bookings.forEach(booking => {
      if(booking.date.toLocaleDateString() === todayDate){
        if(booking.timeFrom >= todayTime){
          futureBookings.push(booking);
        }
      } else if(booking.date.toLocaleDateString() > todayDate){
        futureBookings.push(booking);
      }
    })

    return futureBookings;
  }

  formatDateISO(date: Date): string {
    return formatDate(date, "yyyy-MM-dd", "et_EE");
  }

  onClearSearch() {
    this.clearSearch.emit();
  }

  openActions(event: any, overlayPanel: OverlayPanel) {
    overlayPanel.toggle(event);
    this.isMenuOpen = !this.isMenuOpen;

    if (this.isMenuOpen) {
      this.documentClickListener = this.renderer.listen('document', 'click', (e) => {
        if (!this.el.nativeElement.contains(e.target) && !overlayPanel.el.nativeElement.contains(e.target)) {
          this.isMenuOpen = false;
          if (this.documentClickListener) {
            this.documentClickListener();
            this.documentClickListener = null;
          }
        }
      });
    }
  }

  showPatientBooking(patient: IPatient) {
    this.router.navigate(['/reservation'], {state: {patient}});
  }

  getStatusInfo(isInsured: boolean) {
    return isInsured
      ? {class: 'tag--green', name: 'KINDLUSTATUD'}
      : {class: 'tag--red', name: 'KINDLUSTAMATA'};
  }

  private sendOpenPatientMessage(): void {
    this.authService.getCurrentUser().subscribe({
      next: (response: ICurrentUser | null) => {
        if (response) {
          this.externalMedicalCaseService.open({
            patient: {
              id: this.data.patientId
            },
            doctor: {
              id: response.personId,
              departmentId: response.selectedDepartmentId
            }
          });
        }
      }
    });
  }

  private imageBankUrl() {
    this.patientService.getPatientImageBankLink(this.data.idCode)
      .subscribe({
      next: (result: any) => {
        if(result){
          window.open(result.url);
        }

      }
    })
  }

  private startSelling(): void {
    this.modalService.openModal({
      component: ProductSaleModalComponent,
      size: ModalSize.EXTRA_LARGE,
      data: { patient: this.data },
      customHeight: 'auto',
      header: 'Müük',
    });
  }

  showFutureReservations(){
    this.modalService.openModal({
      component: BookingsModalComponent,
      size: ModalSize.LARGE,
      customWidth: '50vw',
      data: { bookings: this.futureBookings },
      header: `${this.data.fullName} - tulevased broneeringud`,
    });
  }

  protected readonly parseInt = parseInt;

  private setSSTMessage() {
    this.authService.getCurrentUser().subscribe({
      next: (response: ICurrentUser | null) => {
        this.sstService.open({
          patientId: this.data.patientId,
          patientCode: this.data.idCode,
          departmentId: response?.selectedDepartmentId,
          departmentName: response?.personDepartments
            .find(x => x.departmentId == response?.selectedDepartmentId)?.department.name
        });
      }
    })
  }

  private addReferral() {
    let ref = this.modalService.openModal({
      component: CreateNewReferralComponent,
      size: ModalSize.MEDIUM,
      header: 'Lisa uus saatekiri',
      data: { patientId: this.data.patientId },
    })

    ref.onClose.subscribe({
      next: (response: { order: IOrder, service: IService } | false) => {
        if(response) {
          this.authService.getCurrentUser().subscribe({
            next: (user: ICurrentUser | null) => {
              this.externalReferralService.openReferralCreateForm({
                patientId: this.data.patientId,
                providerId: user?.personId,
                departmentId: user?.selectedDepartmentId,
                type: response.service.referralType,
                orderServiceId: response.order.orderServices[0].orderServiceId
              });
            }
          })
        }
      }
    });


  }
}
