import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from "@environments/environment";
import { Observable } from "rxjs";
import { IGeneralPractitionerTeam } from "../interfaces/general-practitioner-team.interface";

@Injectable({
  providedIn: 'root'
})
export class GpteamService {
  private readonly BACKEND_URL: string;

  constructor(private http: HttpClient) {
    this.BACKEND_URL = `${environment.backendUrl}/gpteams`;1
  }

  public getAll(): Observable<IGeneralPractitionerTeam[]> {
    return this.http.get<IGeneralPractitionerTeam[]>(`${this.BACKEND_URL}/all`);
  }

  public get(id: number): Observable<IGeneralPractitionerTeam> {
    return this.http.get<IGeneralPractitionerTeam>(`${this.BACKEND_URL}/${id}`);
  }

  public save(gpTeam: IGeneralPractitionerTeam): Observable<IGeneralPractitionerTeam> {
    return this.http.post<IGeneralPractitionerTeam>(`${this.BACKEND_URL}/save`, gpTeam);
  }

  public changePatientGpTeam(data: any): Observable<IGeneralPractitionerTeam> {
    return this.http.post<IGeneralPractitionerTeam>(`${this.BACKEND_URL}/savePatient`, data);
  }

  public getGpTeamsByAssistant(providerId: number): Observable<IGeneralPractitionerTeam[]>{
    return this.http.get<IGeneralPractitionerTeam[]>(`${this.BACKEND_URL}/assistant/${providerId}`);
  }

  public getGpTeamRoles(): Observable<any>{
    return this.http.get<any>(`${this.BACKEND_URL}/roles`);
  }

  // public savePatient(patient: IPatient): Observable<any> {
  //   return this.http.post<any>(`${this.BACKEND_URL}/save-patient`, patient);
  // }

  // public getAssistantGPTeams(providerId: number): Observable<IGeneralPractitionerTeam[]> {
  //   return this.http.get<IGeneralPractitionerTeam[]>(`${this.BACKEND_URL}/assistant/${providerId}`);
  // }
}
