export enum PermissionsEnum {

  MENU_DASHBOARD = "Permission_Dashboard",
  MENU_DOCTORS = "Permission_Provider",
  MENU_MEDICAL_CASE = "Permission_MedicalCase",
  MENU_ORDERS = "Permission_Orders",
  MENU_EXTERNAL_DOCTORS = "Permission_ExternalCompanies",
  MENU_SERVICES = "Permission_Services",
  MENU_GOODS = "Permission_Goods",
  MENU_SPECIAL_SERVICES = "Permission_SpecialServices",
  MENU_SPECIALITIES = "Permission_Specialities",
  MENU_DISCOUNTS = "Permission_Discounts",
  MENU_ROOMS = "Permission_Rooms",
  MENU_DEVICES = "Permission_Devices",
  MENU_GP_TEAMS = "Permission_GPTeams",
  MENU_ECONSULTATIONS = "Permission_Econsultations",
  MENU_PAYMENTS = "Permission_Payments",
  MENU_REHABILITATION = "Permission_Rehabilitation",
  MENU_SAMPLER = "Permission_Sampler",
  MENU_STUDIES = "Permission_Studies",
  MENU_PROCEDURES = "Permission_Procedures",

  DOCTORS_EDIT = "Permission_PersonEdit",

  SERVICE_EDIT = "Permission_ServiceEdit",

  ROOMS_EDIT = "Permission_RoomEdit",

  PLANTIME_EDIT = "Permission_PlanTimeEdit",

  PERMISSION_REFERRALDISMISS = "Permission_ReferralDismiss",
}
