import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, lastValueFrom, Observable, of } from "rxjs";
import { environment } from "@environments/environment";
import { HttpClient } from "@angular/common/http";
import { IPermission } from "@core/interfaces/permission.interface";
import { AuthService } from "@core/services/auth.service";
import { ICurrentUser } from "@core/interfaces/current-user.interface";
import { PermissionsEnum } from "@core/enums/permissions.enum";

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {

  private readonly BACKEND_URL: string;
  private user: ICurrentUser | null = null;
  readonly allPermissions = new BehaviorSubject<IPermission[]|undefined>(undefined);

  constructor(
    private http: HttpClient,
    private authService: AuthService,
  ) {
    this.BACKEND_URL = `${environment.backendUrl}/permissions`;
  }

  public loadCurrentUserPermissions(): Promise<void> {
    return lastValueFrom(
      forkJoin({
        user: this.authService.getCurrentUser(),
        permissions: this.getAllPermissions(),
      })
    )
      .then((result) => {
        this.user = result.user;
        this.allPermissions.next(result.permissions);
      })
      .catch(() => {
        return Promise.resolve();
      })
  }

  public hasPermission(permission: PermissionsEnum | undefined): boolean {
    if(this.user?.isCustomerSupport || !permission) {
      return true;
    }
    return this.user?.permissions?.some(x => x.code === permission) ?? false;
  }

  public getNoPermissionText(permission: PermissionsEnum | undefined): string|undefined {
    if(!permission || this.hasPermission(permission)) return undefined;

    let permissionText = this.allPermissions.value?.find(x => x.code === permission)?.name.toLowerCase() ?? 'õigus';
    return `Teil puudub õigus: '${permissionText}'`;
  }

  public getAllPermissions(): Observable<IPermission[]> {
    if(this.allPermissions.value != null){
      return of(this.allPermissions.value);
    }
    return this.http.get<IPermission[]>(`${this.BACKEND_URL}`);
  }

  public getUserPermissions(id: number): Observable<IPermission[]> {
    return this.http.get<IPermission[]>(`${this.BACKEND_URL}/${id}`);
  }

  public save(data: any): Observable<any> {
    return this.http.post<any>(`${this.BACKEND_URL}/save`, data);
  }


}
