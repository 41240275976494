import { Injectable, Input, Type } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import { PermissionsService } from "@core/services/permissions.service";
import { ToastService } from "@core/services/toast.service";
import { AuthService } from "@core/services/auth.service";
import { DoctorEditComponent } from "app/feature/doctors/doctors/doctor-edit/doctor-edit.component";

@Injectable({
  providedIn: 'root',
})
export class PermissionGuard implements CanActivate {
  constructor(private permissionService: PermissionsService,
              private toastService: ToastService,
              private authService: AuthService,
              private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const requiredPermission = route.data['permission'];
    const canActivate = this.canActivateComponent(route.component, route);
    if (this.permissionService.hasPermission(requiredPermission) || canActivate || !requiredPermission) {
      return true; // Allow navigation
    }

    this.toastService.error("Pole õigust");
    this.router.navigate(['/office']);
    return false; // Block navigation
  }

  private canActivateComponent(component: Type<any>|null, route: ActivatedRouteSnapshot): boolean {
    // Define a mapping or dynamic logic to determine permissions
    if (component === DoctorEditComponent) {
      return this.authService.currentUserSubject.value?.personId == route.params['id'];
    }
    return true;
  }
}
