import { Component } from "@angular/core";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { PlanTimeCopyType } from "@core/enums/plantime-copy-type.enum";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { IPlanTime } from "@core/interfaces/plan-time.interface";
import { ToastService } from "@core/services/toast.service";
import { PlanTimeService } from "@core/services/plan-time.service";
import { ChangeDetectorRef } from '@angular/core';
import { lastValueFrom, Subscription, timer } from "rxjs";
import { IPlanTimeCopyReport } from "@core/interfaces/plantime-copy-report";

@Component({
  selector: 'medis-copy-plantime-modal',
  templateUrl: './copy-plantime.modal.html',
  styleUrl: './copy-plantime.modal.scss'
})

export class CopyPlantimeModal {
  copyOptions: {label: string; value: string}[] = [
    { label: 'Korduvad päevad', value: PlanTimeCopyType.REPEAT_DAYS },
    { label: 'Üksikpäevad', value: PlanTimeCopyType.SINGLE_DAYS }
  ];

  weekDayOptions: any[] = [
    { label: 'E', value: 1 },
    { label: 'T', value: 2 },
    { label: 'K', value: 3 },
    { label: 'N', value: 4 },
    { label: 'R', value: 5 },
  ];

  planTimes: IPlanTime[] = [];
  copyForm!: FormGroup;

  selectedCopyType: PlanTimeCopyType = PlanTimeCopyType.REPEAT_DAYS;
  selectedWeekDays: number[] = [];

  totalRequests: number = 0;
  completedRequests: number = 0;
  copyProgress: number = 0;
  private progressTimeout!: Subscription;

  isCopying: boolean = false;
  showReport: boolean = false;
  isRequestsReady: boolean = false;

  copyResponseErrorArray: { message: string; errorCode: string; date: string; entityId: number }[] = [];
  copyResult: any | null;

  constructor
  (
    private formBuilder: FormBuilder,
    public config: DynamicDialogConfig,
    public toastService: ToastService,
    public ref: DynamicDialogRef,
    public planTimeService: PlanTimeService,
    private cdRef: ChangeDetectorRef,
  ) {
      if (this.config.data.planTimes) {
        this.planTimes = this.config.data.planTimes;
        this.createCopyForm();
      } else {
        this.toastService.warn("Tekkis viga. Palun proovige hiljem");
        this.ref.close();
      }
  }

  private createCopyForm() {
    this.copyForm = this.formBuilder.group({
      dates: this.formBuilder.array([
          this.createDateGroup(new Date(new Date().setDate(new Date().getDate() + 1))),
          this.createDateGroup(new Date(new Date().setDate(new Date().getDate() + 2))),
        ]),
    });
    this.dates.at(0).get('date')?.valueChanges.subscribe((startDate: Date) => {
      this.updateEndDate(startDate);
    });
  }

  private updateEndDate(startDate: Date) {
    if (startDate) {
      const nextDay = new Date(startDate);
      nextDay.setDate(startDate.getDate() + 1);
      this.dates.at(1).patchValue({ date: nextDay });
    }
  }

  private createDateGroup(date: Date = new Date()): FormGroup {
    return this.formBuilder.group({
      date: [date, Validators.required],
    });
  }

  addDate() {
    const lastDateGroup = this.dates.at(this.dates.length - 1);
    const lastDate = lastDateGroup
      ? new Date(lastDateGroup.value.date)
      : new Date();

    const nextDate = lastDateGroup
      ? new Date(lastDate.setDate(lastDate.getDate() + 1))
      : lastDate;

    this.dates.push(this.formBuilder.group({
      date: [nextDate, Validators.required],
    }));
  }

  removeDate(indexToRemove: number) {
    this.dates.removeAt(indexToRemove);
  }

  getDateFormGroup(index: number): FormGroup {
    return this.dates.at(index) as FormGroup;
  }

  get dates(): FormArray {
    return this.copyForm.get('dates') as FormArray;
  }

  changeCopyOptions(event: any){
    this.selectedCopyType = event.value;
  }

  changeWeekDaysOptions(event: any){
    this.selectedWeekDays = event.value;
  }

  closeModal(){
    if (this.isRequestsReady) {
      this.isRequestsReady = false;
      this.isCopying = false;
    }
    this.clear();
    this.ref.close();
  }

  private isValidISODateString(date: any): boolean {
    return typeof date === 'string' && !isNaN(Date.parse(date));
  }

  private formatDate(date: any): string {
    if (date instanceof Date) {
      return date.toISOString().slice(0, -1);
    }
    if (this.isValidISODateString(date)) {
      return date;
    }
    throw new Error(`Invalid date format: ${date}`);
  }

  async handleCopyRequests(dates: Date[] | string[], planTimes: IPlanTime[]): Promise<void> {
    planTimes = planTimes.filter((p: IPlanTime) => p.planTimeId && p.planTimeId !== 0);

    const requests = [];
    for (const planTime of planTimes) {
      for (const date of dates) {
        const config = { planTimeId: planTime.planTimeId, date: this.formatDate(date) };
        requests.push(config);
      }
    }

    for (const config of requests) {
      try {
        await lastValueFrom(this.planTimeService.copyPlanTime(config));
      } catch (err: any) {
        this.handleError(err, config.date);
      } finally {
        this.updateProgress();
      }
    }
  }

  private handleError(err: any, date: string) {
    if (err && err.error && err.error.errors) {
      err.error.errors.forEach((errorItem: any) => {
        this.copyResponseErrorArray.push({
          message: errorItem.message,
          errorCode: errorItem.errorCode,
          date: date,
          entityId: errorItem.entityId //planTimeID
        });
      });
    }
  }

  private checkCompletion() {
    if (this.completedRequests >= this.totalRequests) {
      this.finalizeProgress();
    }
  }

  private finalizeProgress() {
    if (this.isRequestsReady) return;

    let selectedDates: Date[] | string[] = [];

    if (this.selectedCopyType === PlanTimeCopyType.REPEAT_DAYS) {
       selectedDates = this.getDatesForRepeatedDays(this.dates.controls[0].value.date, this.dates.controls[1].value.date)
    } else if (this.selectedCopyType === PlanTimeCopyType.SINGLE_DAYS) {
       selectedDates = this.getDatesForSingleDays(this.dates.controls.map(date => date.value.date))
    }

    const reportData: IPlanTimeCopyReport = {
      total: this.totalRequests,
      failed: this.copyResponseErrorArray.length,
      errors: this.copyResponseErrorArray,
      planTimes: this.planTimes,
      dates: selectedDates,
    }

    this.generateReport(reportData);

    this.showReport = true;
    this.isRequestsReady = true;
    this.completedRequests = 0;
    this.totalRequests = 0;
    this.isCopying = false;

    if (this.progressTimeout) {
      this.progressTimeout.unsubscribe();
    }
  }

  private generateReport(reportData: IPlanTimeCopyReport) {
    const { total, failed, errors, planTimes, dates } = reportData;

    const dateReport: Record<string, Record<string, any>> = {};

    dates.forEach((date: any) => {
      const formattedDate = new Date(date).toLocaleDateString("en-US");
      dateReport[formattedDate] = {};
      if (date instanceof Date) {
        date = date.toISOString().slice(0, -1);
      }

      planTimes.forEach(plan => {
        const timeFrom = plan.timeFrom.split(":").slice(0, 2).join(":");
        const timeUpto = plan.timeUpto.split(":").slice(0, 2).join(":");
        const timeRange = `${timeFrom}-${timeUpto}`;

        dateReport[formattedDate][timeRange] = [{ code: this.getErrorCode(date, errors, plan) }];
      });
    });

    this.copyResult = {
      total : total,
      failed: failed,
      result: dateReport
    }

  }

  private getErrorCode(date: string, errors: any[], plan: IPlanTime): string {
    const thisDayErrors = errors.filter(e => e.date === date);

    if (!thisDayErrors.length || thisDayErrors.length === 0) {
      return "0"
    }
    else {
      if (thisDayErrors.length === 1) {
        return plan.planTimeId === thisDayErrors[0].entityId ? thisDayErrors[0].errorCode : "0";
      }
      else {
        const error = thisDayErrors.find(e => e.entityId === plan.planTimeId);
        if (error) { return error.errorCode}
      }
    }
    return "0"
  }

  private updateProgress(): void {
    this.completedRequests += 1;

    if (this.totalRequests > 0) {
      this.copyProgress = Math.round((this.completedRequests / this.totalRequests) * 100);
    } else {
      this.copyProgress = 100;
    }

    this.cdRef.detectChanges();
    this.checkCompletion();
  }

  private startProgressSimulation() {
    this.isCopying = true;
    this.copyProgress = 0;
    const interval = setInterval(() => {
      if (this.isRequestsReady || this.copyProgress >= 100) {
        this.copyProgress = 100;
        clearInterval(interval);
      }
    }, 100);
  }

  copyPlanTimes(){
    this.planTimes = this.planTimes.filter(p => p.planTimeId !== 0);

    if (this.selectedCopyType === PlanTimeCopyType.REPEAT_DAYS) {

      if (this.dates.controls[0].invalid){
        this.toastService.warn("Puudub alg kuupäev kopeerimiseks");
        return;
      } else if (this.dates.controls[1].invalid){
        this.toastService.warn("Puudub lõpp kuupäev kopeerimiseks");
        return;
      }

      const dateFrom = this.dates.controls[0].value.date;
      const dateUpto = this.dates.controls[1].value.date;

      if (this.isFutureDate(dateFrom) && this.isFutureDate(dateUpto)) {

        this.selectedWeekDays = this.selectedWeekDays.length > 0 ? this.selectedWeekDays : [1, 2, 3, 4, 5];
        const datesToSend: Date[] = this.getDatesForRepeatedDays(dateFrom, dateUpto);
        this.totalRequests = datesToSend.length * this.planTimes.length;

        if (this.totalRequests < 1) {
          this.toastService.warn("Pole töögraafikuid kopeerimiseks");
          return;
        }

        const maxWaitTime = 90000;
        this.progressTimeout = timer(maxWaitTime).subscribe(() => {
          this.finalizeProgress();
        });

        this.startProgressSimulation();

        this.handleCopyRequests(datesToSend, this.planTimes)
          .then()
          .finally(() => {
            this.finalizeProgress();
          });
      } else {
        this.toastService.warn("Palun valige tuleviku kuupäeva");
        return;
      }
    } else if (this.selectedCopyType === PlanTimeCopyType.SINGLE_DAYS) {
      const date = this.dates.controls[0].value.date;
      if (!date) {
        this.toastService.warn("Palun valige kuupäeva");
        return;
      } else if (!this.isFutureDate(date)) {
        this.toastService.warn("Palun valige tuleviku kuupäeva");
        return;
      } else {
        const selectedDates = this.getDatesForSingleDays(this.dates.controls.map(date => date.value.date))

        this.totalRequests = selectedDates.length * this.planTimes.length;

        if (this.totalRequests < 1) {
          this.toastService.warn("Pole töögraafikuid kopeerimiseks");
          return;
        }

        this.isCopying = true;

        const maxWaitTime = 20000;
        this.progressTimeout = timer(maxWaitTime).subscribe(() => {
          this.finalizeProgress();
        });

        this.startProgressSimulation();

        this.handleCopyRequests(selectedDates, this.planTimes)
          .then()
          .finally(() => {
            this.finalizeProgress();
          });
      }
    }
  }

  private isFutureDate(date: Date): boolean{
    const currentDate = new Date();
    return date > currentDate;
  }

  private getDatesForSingleDays(singleDates: (Date | string)[]): string[] {

    const dates: string[] = [];
    for (const date of singleDates) {
      const parsedDate = typeof date === "string" ? new Date(date) : date;

      const formattedDate = new Date(parsedDate.getTime() - parsedDate.getTimezoneOffset() * 60000);

      dates.push(formattedDate.toISOString().slice(0, -1));
    }

    return dates;
  }

  private getDatesForRepeatedDays(dateFrom: Date, dateUpto: Date): Date[] {
    const dates: Date[] = [];

    const startDate = new Date(dateFrom);
    startDate.setHours(0, 0, 0, 0);

    const endDate = new Date(dateUpto);
    endDate.setHours(0, 0, 0, 0);

    while (startDate <= endDate) {
      const dayOfWeek = startDate.getDay();

      if (this.selectedWeekDays.includes(dayOfWeek)) {
        dates.push(new Date(startDate.getTime() - startDate.getTimezoneOffset() * 60000));
      }

      startDate.setDate(startDate.getDate() + 1);
    }

    return dates;
  }

  restartCopy() {
    this.clear();
    this.createCopyForm();
  }

  private clear() {
    this.isRequestsReady = false;
    this.selectedWeekDays = [];
    this.showReport = false;
    this.copyResult = null;
    this.copyResponseErrorArray = [];
    this.dates.clear();
  }


  protected readonly PlanTimeCopyType = PlanTimeCopyType;
}
