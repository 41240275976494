import { Component, Input } from '@angular/core';

@Component({
  selector: 'medis-insurance-status-label',
  styleUrl: './insurance-status-label.component.scss',
  template: `
    <p-tag
      [styleClass]="'white-space-nowrap tag tag--' + size + ' ' + (getStatusInfo(isInsured).class)"
      rounded="true"
      [value]="getStatusInfo(isInsured).name">
    </p-tag>
  `
})
export class InsuranceStatusLabelComponent {
  @Input() isInsured: boolean | undefined = false;
  @Input() size: 'large' | 'small' = 'large';

  getStatusInfo(isInsured: boolean | undefined): { class: string, name: string } {
    return isInsured
      ? { class: 'tag--green', name: 'KINDLUSTATUD' }
      : { class: 'tag--red', name: 'KINDLUSTAMATA' };
  }

}
