@if (discountForm) {
  <form [formGroup]="discountForm" class="bg-white data-details__section border-round-md" (ngSubmit)="submit()">
    <div class="flex flex-column gap-4 pt-4">
        <div class="modal__col flex gap-4 w-full">
          <div class="w-4">
            <p-floatLabel>
              <p-dropdown
                id="discount"
                class="w-full"
                styleClass="w-full"
                [options]="discounts"
                formControlName="discount"
                optionValue="value"
                optionLabel="label"
                (onChange)="selectDiscount()"
              />
              <label for="discount">Allahindlus</label>
            </p-floatLabel>
          </div>
          @if (!discountChanged && reason.value) {
            <div class="w-10 flex modal__row align-items-center">
              <strong class="pr-3">Põhjendus:</strong>
              <span>{{ unpaidServices[0].discountReason }}</span>
            </div>
          }
          @if (discountChanged) {
            <div class="w-8 modal__col w-full">
              <p-floatLabel>
                <input pInputText
                       id="reason"
                       formControlName="reason"
                       type="text"
                       class="w-full"
                />
                <label for="reason">Põhjendus</label>
              </p-floatLabel>
              @if (formSubmitted && reason.errors) {
                <small class="p-error my-2">Põhjendus on kohustuslik</small>
              }
            </div>
            <p-button type="submit" label="Salvesta"/>
          }
        </div>
    </div>
  </form>
}

