import { Injectable } from '@angular/core';
import {MessageService} from "primeng/api";

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  private readonly DEFAULT_LIFE = 5000;
  private readonly SHORT_LIFE = 2000;

  constructor(private messageService: MessageService) {}

  public error(message: string|undefined|null, summary?: string): void {
    return this.messageService.add({
      severity: 'error',
      summary: summary,
      detail: message ?? "Default message",
      life: this.DEFAULT_LIFE
    });
  }

  public message(message: string|undefined|null, summary?: string): void {
    return this.messageService.add({
      severity: 'info',
      summary: summary,
      detail: message ?? "Default message",
      life: this.DEFAULT_LIFE
    });
  }

  public success(message: string|undefined|null, summary?: string): void {
    return this.messageService.add({
      severity: 'success',
      icon: 'pi pi-check-circle',
      summary: summary,
      detail: message ?? "Default message",
      life: this.DEFAULT_LIFE
    });
  }

  public warn(message: string|undefined|null, summary?: string): void {
    return this.messageService.add({
      severity: 'warn',
      summary: summary,
      detail: message ?? "Default message",
      life: this.DEFAULT_LIFE
    });
  }

  public contrast(message: string|undefined|null, summary?: string): void {
    return this.messageService.add({
      severity: 'contrast',
      summary: summary,
      detail: message ?? "Default message",
      life: this.SHORT_LIFE
    });
  }

  public secondary(message: string|undefined|null, summary?: string): void {
    return this.messageService.add({
      severity: 'secondary',
      summary: summary,
      detail: message ?? "Default message",
      life: this.DEFAULT_LIFE
    });
  }
}
