import { Component, Input, OnInit } from '@angular/core';
import { PatientService } from "@core/services/patient.service";
import { ToastService } from "@core/services/toast.service";
import { IOrder } from "@core/interfaces/order.interface";
import { IOrderService } from "@core/interfaces/order-service.interface.interface";
import { ModalSize } from "@core/enums/modalSize";
import { ModalService } from "@core/services/modal.service";
import { ChangeTimeComponent } from "@shared/components/change-time/change-time.component";
import { IOrderReservationConfig } from "@core/interfaces/order-reservation-config.interface";
import { OrderServiceStatus } from "@core/enums/order-service-status.enum";
import { OrderServiceTimeService } from "@core/services/order-service-time.service";
import { OrderServiceService } from "@core/services/order-service.service";
import { IOrderServicePayment } from "@core/interfaces/order-service-payment.interface";
import { OrderSummaryModal } from "@shared/modals/order-summary/order-summary.modal";
import { IPatient } from "@core/interfaces/patient.interface";
import { finalize } from "rxjs";
import { PrintReservationsModalComponent } from "@shared/modals/print-reservations/print-reservations.modal.component";

@Component({
  selector: 'medis-implementation-plan',
  templateUrl: './implementation-plan.component.html',
  styleUrl: './implementation-plan.component.scss'
})
export class ImplementationPlanComponent implements OnInit {
  @Input() patient!: IPatient;
  loading = false;

  orders: IOrder[] = [];
  orderServices: IOrderService[] = [];
  expandedRows: { [key: string]: boolean } = {};

  constructor(
    private patientService: PatientService,
    private toastService: ToastService,
    private modalService: ModalService,
    private orderServiceTimeService: OrderServiceTimeService,
    private orderServiceService: OrderServiceService,
  ) {
  }

  ngOnInit() {
    this.getPatientPhysiotherapy();
  }

  private getPatientPhysiotherapy(): void {
    this.loading = true;
    this.patientService.getPatientPhysiotherapy(this.patient.patientId)
      .pipe(finalize(() => this.loading = false))
      .subscribe({
        next: result => {
          this.orders = result;
          this.orderServices = this.getFilteredOrders(result);
        },
        error: (err) => {
          if (err.error && err.error.errors?.length > 0) {
            err.error.errors.forEach((err: Error) => {
              this.toastService.error(err.message);
            })
          } else {
            this.toastService.error(err.message);
          }
        }
      })
  }

  private getFilteredOrders(orders: IOrder[]): IOrderService[] {
    const orderServices: IOrderService[] = [];

    orders.forEach(order => {
      if (order.orderServices) {
        order.orderServices.forEach(service => {
          orderServices.push({
            ...service,
            orderServiceTimes: this.orderServiceTimeService.sortAndGetConfirmedTimes(service.orderServiceTimes ?? []),
          });
        });
      }
    });

    orderServices.forEach(service => {
      if (service.orderServiceTimes?.length) {
        this.expandedRows[service.orderServiceId] = true;
      }
    })

    return orderServices;
  }

  onRowExpand(event: any) {
    this.expandedRows[event.data.orderServiceId] = true;
  }

  onRowCollapse(event: any) {
    delete this.expandedRows[event.data.orderServiceId];
  }

  changeTime(orderService: IOrderService): void {
    const data: IOrderReservationConfig = {
      serviceId: orderService?.serviceId,
      patient: this.orders.find(o => o.orderId === orderService?.orderId)?.patient,
      times: this.orderServiceTimeService.sortAndGetConfirmedTimes(orderService?.orderServiceTimes),
      financialSourceId: orderService.financialSourceId,
      orderService,
      bookingCount: orderService?.repeat,
      disableService: true,
      showMultiple: true,
      returnSavedData: true,
      bookingCountDisabled: true,
    };

    const dialogRef = this.modalService.openModal({
      component: ChangeTimeComponent,
      size: ModalSize.EXTRA_LARGE,
      header: this.orderServiceService.getReservationDialogTitle(orderService),
      data,
      styleClass: 'order-reservation-modal',
    })

    dialogRef.onClose.subscribe((result: IOrderService) => {
      if (result) {
        orderService.orderServiceTimes = this.orderServiceTimeService.sortAndGetConfirmedTimes(result.orderServiceTimes);
        this.checkUnpaidOrderServices();
        // result.orderServiceTimes = this.orderServiceTimeService.sortAndGetConfirmedTimes(result.orderServiceTimes);
        // this.router.navigate(['/reservation/booking-confirmation'], {
        //   state: {orderService: result, patientId: orderService?.patientId}
        // });
      }
    });
  }

  private checkUnpaidOrderServices(): void {
    this.orderServiceService
      .getUnpaidServices(this.patient.patientId)
      .subscribe({
        next: (response) => {
          if (response && response.length > 0) {
            this.openOrderSummaryModal(response);
          }
        }
      })
  }

  private openOrderSummaryModal(unpaidServices: IOrderServicePayment[]): void {
    this.modalService.openModal({
      component: OrderSummaryModal,
      size: ModalSize.EXTRA_LARGE,
      header: 'Tellimuse kokkuvõte',
      contentStyle: { 'flex-grow': 0 },
      data: { patient: this.patient, unpaidServices },
    })
  }

  printReservations(orderService: IOrderService): void {
    this.modalService.openModal({
      component: PrintReservationsModalComponent,
      header: 'Prindi teostusplaani',
      data: {
        orderService: orderService,
        patient: this.patient,
      }
    })
  }

  protected readonly OrderServiceStatus = OrderServiceStatus;
}
