import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { IPerson } from "@core/interfaces/person.interface";
import { STATE_OPTIONS } from "@shared/modals/plantime-mass-edit/plantime-mass-edit.modal";

@Component({
  selector: "medis-related-person-action",
  templateUrl: "./related-person-action.component.html",
})

export class RelatedPersonActionComponent implements OnInit {
  @Input() personId!: number;
  @Input() form!: FormGroup;
  @Input() actionValue: string = '';
  @Input() allProviders: IPerson[] = [];

  validProviders: IPerson[] = [];

  stateOptions: any[] = [
    { label: 'Kustuta', value: STATE_OPTIONS.DELETE },
    { label: 'Lisa', value: STATE_OPTIONS.ADD }
  ];

  @Output() selectedRelatedPerson = new EventEmitter();
  @Output() stateOptionValueChange = new EventEmitter();

  ngOnInit() {
    if (Array.isArray(this.allProviders) && this.allProviders.length > 0) {
      this.validProviders = this.filterValidProviders(this.allProviders, this.personId);
      this.setDefaultValue(this.validProviders);
    }
  }

  private filterValidProviders(providers: IPerson[], excludedPersonId: string | number): IPerson[] {
    return providers.filter(provider =>
      provider.personId &&
      provider.fullName?.length > 0 &&
      provider.personId !== excludedPersonId
    );
  }

  private setDefaultValue(data: IPerson[]): void {
    if (Array.isArray(data) && data.length > 0 && data[0].personId != null) {
      this.form.get("relatedPersonId")?.setValue(data[0].personId);
    }
  }

  onStateOptionChange(event: any){
    this.stateOptionValueChange.emit(event.value);
  }

}
