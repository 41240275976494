@if (!loading) {
  <p-table [value]="orderServices" dataKey="orderServiceId"
           [expandedRowKeys]="expandedRows"
           (onRowExpand)="onRowExpand($event)"
           (onRowCollapse)="onRowCollapse($event)"
  >
    <ng-template pTemplate="header">
      <tr>
        <th class="arrow-col"></th>
        <th>Tellimus</th>
        <th>Periood</th>
        <th>Kordade arv</th>
        <th [colSpan]="2">Teenus</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-orderService let-expanded="expanded">
      <tr [class.expanded-background]="expanded">
        <td>
          @if (orderService.orderServiceTimes?.length > 0 || orderService.orderServiceQueues?.length > 0) {
            <p-button
              type="button"
              [pRowToggler]="orderService"
              [text]="true"
              [rounded]="true"
              [plain]="true"
              [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
            ></p-button>
          }
        </td>
        <td>
          <div class="gap-1">
            <p class="m-0">{{ orderService.orderId }}</p>
            <span class="text-base font-semibold">{{ orderService.created | dateFormat }}</span>
          </div>
        </td>
        <td>
          <div class="flex align-items-center">
            <p class="m-0">{{ orderService.fromDate | dateFormat }} - {{ orderService.toDate | dateFormat }}</p>
          </div>
        </td>
        <td>{{ orderService.repeat }}</td>
        <td>
          <div class="flex gap-2 align-items-center">
            <medis-service-badge [badges]="orderService.serviceBadges"></medis-service-badge>
            {{ orderService.serviceName }}
          </div>
        </td>
        <td class="last-col">
          <div class="flex gap-2 justify-content-end align-items-center">
            <p-button
              type="button"
              outlined
              severity="secondary"
              icon="pi pi-print"
              styleClass="h-full w-full"
              [style.height.rem]="2.745"
              [style.width.rem]="2.745"
              (click)="printReservations(orderService)"
            />
            <p-button
              label="Vali aeg"
              outlined
              severity="secondary"
              (click)="changeTime(orderService)"
            />
          </div>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="rowexpansion" let-orderService let-expanded="expanded">
      @for (reservation of (orderService.orderServiceTimes!.length > 0)
        ? orderService.orderServiceTimes
        : orderService.orderServiceQueues; let index = $index; track reservation) {
        <tr class="border-0 expanded-background">
          <td [colSpan]="4"></td>
          <td [colSpan]="2">
            Aeg {{ index + 1 }} - <span [innerHTML]="reservation.date | dateTimeDisplay: 'dd.MM.yyyy': true"></span> &nbsp;
            {{ reservation?.timeFrom | timeFormat }} - {{ reservation?.timeUpto | timeFormat }}
            &nbsp;
            {{ reservation?.roomCode }} &nbsp;
            {{ reservation?.departmentShort }}
          </td>
        </tr>
      }
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="5">Pole midagi leitud</td>
      </tr>
    </ng-template>
  </p-table>
} @else {
  <div class="flex flex-column gap-4">
    <p-skeleton width="100%" height="3rem"></p-skeleton>
    <p-skeleton width="100%" height="3rem"></p-skeleton>
    <p-skeleton width="100%" height="3rem"></p-skeleton>
  </div>
}

