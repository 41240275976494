import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IPatientComment } from "@core/interfaces/patient-comment.interface";
import { DateFormatPipe } from "@shared/pipes/date-format.pipe";

@Component({
  selector: 'medis-comment-item',
  standalone: true,
  templateUrl: 'comment-item.component.html',
  styleUrl: 'comment-item.component.scss',
  imports: [
    DateFormatPipe
  ]
})
export class CommentItemComponent {
  @Input() comment: IPatientComment | null = null;
  @Input() last: boolean = false;
  @Output() deleteComment = new EventEmitter<number>();

  onDeleteComment(): void {
    if (this.comment) {
      this.deleteComment.emit(this.comment.patientCommentId);
    }
  }
  protected readonly Date = Date;
}
