import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { IFullOrder } from "@core/interfaces/full-order.interface";
import { getChannelName } from "@core/enums/channel-id.enum";
import { IOrderServiceTime } from "@core/interfaces/order-service-time.interface";
import { OrderServiceService } from "@core/services/order-service.service";
import { IOrderServiceQueue } from "@core/interfaces/order-service-queue.interface";
import { OrderServiceStatus } from "@core/enums/order-service-status.enum";
import { FinancialSource } from "@core/enums/financial-sources.enum";
import { IOrderService } from "@core/interfaces/order-service.interface.interface";

@Component({
  selector: 'medis-order-table',
  templateUrl: './order-table.component.html',
  styleUrls: ['./order-table.component.scss'],
})
export class OrderTableComponent implements OnInit, OnChanges {
  @Input() set loading(event: any) {
    this.loadingVal = event;
    if (!event) {
      this.resetExpanded();
    }
  };

  @Input() actions = true;
  @Input() hideOrdersWithoutBookings = false;
  loadingVal = false;
  @Input() printableReservations = false;
  @Input() data: IFullOrder[] = [];
  @Input() columns: OrderTableColumn[] = [
    {
      name: OrderColumnName.PATIENT,
      width: '14%'
    },
    {
      name: OrderColumnName.PROVIDER,
      width: '10.5%'
    },
    {
      name: OrderColumnName.FIN_SOURCE,
      width: '6%'
    },
    {
      name: OrderColumnName.SERVICE,
      width: '22.5%'
    },
    {
      name: OrderColumnName.STATUS,
      width: '10.5%'
    },
    {
      name: OrderColumnName.CHANNEL,
      width: '6.5%'
    },
    {
      name: OrderColumnName.REFERRAL_STATUS,
      width: '10.5%'
    },
    {
      name: OrderColumnName.REFERRAL,
      width: '8.5%'
    }
  ];
  @Input() patientClickable: boolean = true;

  @Output() updateList = new EventEmitter<{ time: IOrderServiceTime | IOrderServiceQueue, status: OrderServiceStatus }>();

  expandedRows: { [key: string]: boolean } = {};
  serviceStatusIdx = 0;

  reservations: Array<Partial<IOrderServiceTime> | Partial<IOrderServiceQueue>> = [];

  constructor(private orderServiceService: OrderServiceService) {
  }

  ngOnInit(): void {
    this.serviceStatusIdx = this.columns.findIndex(x => x.name == OrderColumnName.SERVICE);

    if (this.data && this.hideOrdersWithoutBookings) {
      this.data = this.data.filter(order => this.orderHasBookings(order));
    }

    this.resetExpanded();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data'] && this.printableReservations) {
      this.reservations = this.allReservationsFromFullOrder();
    }
  }

  private resetExpanded(): void {
    this.data
      .filter(order => order.orderService.orderServiceId && this.orderHasBookings(order))
      .forEach(order => this.expandedRows[order.orderService.orderServiceId] = true);
  }

  private orderHasBookings(order: IFullOrder): boolean {
    const hasServiceTimes = (order.orderService.orderServiceTimes ?? []).length > 0;
    const hasServiceQueues = (order.orderService.orderServiceQueues ?? []).length > 0;
    return hasServiceQueues || hasServiceTimes;
  }

  onRowExpand(event: any): void {
    this.expandedRows[event.data.orderService.orderServiceId] = true;
  }

  onRowCollapse(event: any): void {
    delete this.expandedRows[event.data.orderService.orderServiceId];
  }

  getChannelName(channelId: number): string {
    return getChannelName(channelId);
  }

  openCommentsByOrder = (orderServiceTime: IOrderService | IOrderServiceTime | null) => {
    this.orderServiceService.openCommentsByOrder(orderServiceTime);
  };

  onTimeChanged(timeData: { time: IOrderServiceTime | IOrderServiceQueue, status: OrderServiceStatus }): void {
    this.updateList.emit(timeData);
  }

  private allReservationsFromFullOrder(): Array<Partial<IOrderServiceTime> | Partial<IOrderServiceQueue>> | any[] {
    if (this.data) {
      const dataCopy = this.data;
      let reservations: Array<IOrderServiceTime | IOrderServiceQueue> = [];
      dataCopy.forEach(order => {
        if (order.orderService.orderServiceQueues?.length) {
          reservations.push(...this.filterConfirmedReservations(order.orderService.orderServiceQueues));
        } else if (order.orderService.orderServiceTimes?.length) {
          reservations.push(...this.filterConfirmedReservations(order.orderService.orderServiceTimes));
        }
      });
      return reservations.sort((a, b) => {
        const dateA = new Date(a.date!).getTime();
        const dateB = new Date(b.date!).getTime();
        if (dateA !== dateB) {
          return dateA - dateB;
        }
        const minutesA = new Date(`1970-01-01T${a.timeFrom}`).getTime();
        const minutesB = new Date(`1970-01-01T${b.timeUpto}`).getTime();

        return minutesA - minutesB;
      })
    }
    return [];
  }

  private filterConfirmedReservations(reservations: Array<IOrderServiceTime | IOrderServiceQueue>): Array<IOrderServiceTime | IOrderServiceQueue> {
    return reservations.filter(o => o.orderServiceTimeStatus === OrderServiceStatus.CONFIRMED);
  }

  protected readonly OrderColumnName = OrderColumnName;
  protected readonly FinancialSource = FinancialSource;
}

export interface OrderTableColumn {
  name: OrderColumnName;
  width?: string;
  /**
   * @param {IOrderServiceTime | IOrderServiceQueue} [row] - The row data to generate content from.
   * @returns {string} String to display in the expandable section.
   */
  expandableContent?: (row?: IOrderServiceTime | IOrderServiceQueue) => string;
}

export enum OrderColumnName {
  PATIENT = 'patsient',
  PROVIDER = 'osutaja',
  SUBSCRIBER_PROVIDER = 'tellija',
  FIN_SOURCE = 'maksja',
  SERVICE = 'teenus',
  STATUS = 'staatus',
  CHANNEL = 'kanal',
  REFERRAL_STATUS = 'saatekiri',
  REFERRAL_NR = 'saatekiri nr',
  REFERRAL = 'suunaja',
  DESCRIPTION = 'kirjeldus',
  RESERVATION_CHANNEL = 'broneeringu kanal',
  TEHIK_STATUS = 'tehik staatus'
}
