@if (patientId || (referralNr && !this.loading && referralsToShow.length == 0)) {
  <p-table [value]="referralsToShow">
    <ng-template pTemplate="header">
      <tr>
        <th>Kuupäev</th>
        <th>Number</th>
        <th>Tellija</th>
        <th>Eriala</th>
        <th>Eesmärk</th>
        <th>Teenus</th>
        <th>Cito</th>
        <th>Kehtivus</th>
        <th>Staatus</th>
        <th>Tehik staatus</th>
        <th></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-referral>
      <tr>
        <td>{{ referral.documentDate | dateTimeDisplay }}</td>
        <td>
          <a class="text-primary underline cursor-pointer"
             (click)="showInfo(referral)">
            {{ referral.documentNr }}
          </a>
        </td>
        <td>
          {{ referral.authorName }}
          <br/>
          <small class="text-muted">
            <i>{{ referral.sourceCompanyName }}</i>
          </small>
        </td>
        <td>{{ referral.targetProfession }}</td>
        <td>{{ referral.reason }}</td>
        <td>{{ referral.targetServiceCode }} - {{ referral.serviceName }}</td>
        <td>{{ referral.cito }}</td>
        <td>
                <span *ngIf="referral.validFrom  || referral.validTo ">
                    {{ referral.validFrom | dateTimeDisplay }} - {{ referral.validTo | dateTimeDisplay }}
                </span>
        </td>
        <td>
          <medis-order-referral-status-label
            [referralStatus]="referral.status"
          ></medis-order-referral-status-label>
        </td>

        <td>
          <medis-referral-etervis-status-label [eTervisStatus]="referral.eTervisStatus">

          </medis-referral-etervis-status-label>
        </td>
        <td>
          @if (!selectedReferral &&
          !hideBook &&
          referral.eTervisStatus == OrderReferralETervisStatusEnum.OPEN) {
            <p-button (click)="booked(referral)">
              Loo broneering
            </p-button>
          } @else if (selectedReferral) {
            <p-button
              [rounded]="true"
              icon="pi pi-times"
              [text]="true"
              severity="secondary"
              (onClick)="remove()"
            />
          }
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage">
      @if (loading) {
        <tr>
          @for (i of columnNumber(); track $index) {
            <td>
              <p-skeleton></p-skeleton>
            </td>
          }
        </tr>
      } @else if (patientId && service) {
        <tr>
          <td [attr.colspan]="11">
            <p class="p-error mt-2 mb-3">
              Saatekiri on aja broneerimiseks kohustuslik. Valitud erialale ja teenusele vastavat saatekirja ei leitud
            </p>
            <p-button
              (click)="loadBooked()"
            >
              Vaata patsiendi saatekirju
            </p-button>
          </td>
        </tr>
      } @else if (patientId) {
        <tr>
          <td [attr.colspan]="11">
            <p>
              Valitud patsiendile saatekirju pole leitud
            </p>
            @if (showLoad) {
              <p-button
                (click)="loadBooked()"
              >
                Vaata patsiendi saatekirju
              </p-button>
            }
          </td>
        </tr>
      }
    </ng-template>
  </p-table>
}
