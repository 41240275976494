import { Component, Input, OnInit, Optional } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { BookingsService } from "@core/services/bookings.service";
import { ToastService } from "@core/services/toast.service";
import { IOrderService } from "@core/interfaces/order-service.interface.interface";
import { IOrderReservationConfig } from "@core/interfaces/order-reservation-config.interface";
import { IOrderReservationOutput } from "@core/interfaces/order-reservation-output.interface";
import { Router } from "@angular/router";
import { OrderServiceTimeService } from "@core/services/order-service-time.service";
import { OrderServiceService } from "@core/services/order-service.service";
import { IOrderServicePayment } from "@core/interfaces/order-service-payment.interface";
import { OrderSummaryModal } from "@shared/modals/order-summary/order-summary.modal";
import { ModalSize } from "@core/enums/modalSize";
import { ModalService } from "@core/services/modal.service";
import { PatientService } from "@core/services/patient.service";
import { IPatient } from "@core/interfaces/patient.interface";

@Component({
  selector: 'medis-change-time',
  templateUrl: './change-time.component.html',
  styleUrl: './change-time.component.scss'
})
export class ChangeTimeComponent implements OnInit {
  @Input() externalBookingConfig: IOrderReservationConfig | null = null;

  config!: IOrderReservationConfig;
  orderReservationOutput: IOrderReservationOutput|null = null;
  returnSavedData: boolean = false;
  orderServiceTimeIds: number[] = [];
  patient!: IPatient;

  setOrderReservationOutput(output: IOrderReservationOutput) {
    this.orderReservationOutput = output;
  }

  constructor(
    @Optional() public dynamicDialogConfig: DynamicDialogConfig,
    public bookingsService: BookingsService,
    @Optional() public ref: DynamicDialogRef,
    private toastService: ToastService,
    private router: Router,
    private orderServiceTimeService: OrderServiceTimeService,
    private orderServiceService: OrderServiceService,
    private modalService: ModalService,
    private patientService: PatientService,
  ) {
    if (this.dynamicDialogConfig) {
      this.config = this.dynamicDialogConfig.data;
      this.orderServiceTimeIds = this.config?.times?.map(time => time.orderServiceTimeId) ?? [];
      this.config.submitHandler = () => this.save();
      this.returnSavedData = this.dynamicDialogConfig.data?.returnSavedData;
    }
  }

  ngOnInit() {
    if (this.externalBookingConfig) {
      this.config = this.externalBookingConfig;
      this.orderServiceTimeIds = this.config?.times?.map(time => time.orderServiceTimeId) ?? [];
      this.config.submitHandler = () => this.save();
      if (this.externalBookingConfig.patientId) {
        this.patientService.get(this.externalBookingConfig.patientId).subscribe({
          next: (result) => {
            this.patient = result;
            this.config.patient = result;
          },
          error: (err) => {
            this.toastService.error(err.message);
          }
        })
      }
    }
  }

  save(){
    if(this.orderReservationOutput!.times && this.orderReservationOutput!.times.length){
      this.bookingsService.changeBooking({
        orderServiceTimeId: this.config.orderServiceTime?.orderServiceTimeId,
        orderServiceQueueId: this.config.orderServiceTime?.orderServiceQueueId,
        orderServiceId: this.config.orderServiceTime?.orderServiceId ?? this.config?.orderService?.orderServiceId,
        orderServiceTimesModel: this.orderReservationOutput!.times.filter(time => !time.orderServiceTimeId && !time.isQueue),
        orderServiceQueuesModel: this.orderReservationOutput!.times.filter(time => !time.orderServiceTimeId && time.isQueue),
        financialSourceId: this.orderReservationOutput?.financialSourceId,
        changedOrderServiceTimeIds: this.getChangedTimeIds(),
        companyId: this.orderReservationOutput?.companyFilter?.companyId ?? this.orderReservationOutput?.companySelected?.companyId
      }).subscribe({
        next: (response: IOrderService) => {
          if (response && this.ref && !this.externalBookingConfig) {
            this.ref.close(this.returnSavedData ? response : true);
          } else {
            this.handleExternalBookingSave(response);
          }
        },
        error: () => {
          this.toastService.error("Broneeringu muutmise viga");
        }
      })

    } else if(!this.orderReservationOutput!.patient){
      this.toastService.error("Teenuse broneerimiseks valige patsient.")
    } else if(!this.orderReservationOutput!.times.length){
      this.toastService.error("Teenuse broneerimiseks valige aeg.")
    } else if(!this.orderReservationOutput!.service){
      this.toastService.error("Teenuse broneerimiseks valige teenus.")
    }
  }

  private getChangedTimeIds(): number[] {
    const bookingTimes = this.orderReservationOutput?.times.map(time => time.orderServiceTimeId) ?? [];

    return this.orderServiceTimeIds.filter(id => !bookingTimes.includes(id));
  }

  private handleExternalBookingSave(orderService: IOrderService): void {
    orderService.orderServiceTimes = this.orderServiceTimeService.sortAndGetConfirmedTimes(orderService.orderServiceTimes);

    if (this.config.patientId) {
      this.orderServiceService
        .getUnpaidServices(this.config.patientId)
        .subscribe({
          next: (response) => {
            if (response && response.length > 0) {
              this.openOrderSummaryModal(response, orderService);
            } else {
              this.navigateToBookingConfirmation(orderService);
            }
          },
          error: err => {
            this.toastService.error(err.message)
          }
        })
    } else {
      this.navigateToBookingConfirmation(orderService);
    }
  }

  private openOrderSummaryModal(unpaidServices: IOrderServicePayment[], orderService: IOrderService): void {
    const ref = this.modalService.openModal({
      component: OrderSummaryModal,
      size: ModalSize.EXTRA_LARGE,
      header: 'Tellimuse kokkuvõte',
      contentStyle: { 'flex-grow': 0 },
      data: {patient: this.patient, unpaidServices, externalBooking: true},
    })

    ref.onClose.subscribe(() => {
      this.navigateToBookingConfirmation(orderService);
    })
  }

  private navigateToBookingConfirmation(orderService: IOrderService): void {
    this.router.navigate(['/reservation/booking-confirmation'], {
      state: { orderService: orderService, patientId: this.config.patientId }
    });
  }
}
