<div>
  @if (!showReport) {
    @if (isCopying) {
      <div class="flex flex-column w-full card pb-4">
        <p-progressBar
          id="myId"
          [value]="copyProgress"
          class="pb-4">
        </p-progressBar>
      </div>
    } @else {
      <div class="pb-4 flex w-full">
        <p-selectButton
          [options]="copyOptions"
          [ngModel]="selectedCopyType"
          (onChange)="changeCopyOptions($event)"
          optionValue="value"
          styleClass="flex white-space-nowrap w-full"
        />
      </div>
      @if (selectedCopyType === PlanTimeCopyType.REPEAT_DAYS) {
        <div class="flex flex-column pb-4 w-full gap-4">
          <div class="flex justify-content-evenly">
            <p-selectButton
              [options]="weekDayOptions"
              [ngModel]="selectedWeekDays"
              (onChange)="changeWeekDaysOptions($event)"
              [multiple]="true"
              optionValue="value"
              styleClass="flex white-space-nowrap"
            />
          </div>
          <div class="flex row w-full gap-4">
            <div class="flex flex-column justify-content-center w-full" [formGroup]="this.getDateFormGroup(0)">
              <p-floatLabel>
                <p-calendar
                  [iconDisplay]="'input'"
                  [showIcon]="true"
                  [showButtonBar]="true"
                  id="startDate"
                  appendTo="body"
                  class="w-full"
                  styleClass="w-full"
                  dateFormat="dd.mm.yy"
                  formControlName="date"
                ></p-calendar>
                <label for="startDate">Kordub alates</label>
              </p-floatLabel>
            </div>
            <div class="flex flex-column justify-content-center w-full" [formGroup]="this.getDateFormGroup(1)">
              <p-floatLabel>
                <p-calendar
                  [iconDisplay]="'input'"
                  [showIcon]="true"
                  id="endDate"
                  appendTo="body"
                  class="w-full"
                  styleClass="w-full"
                  dateFormat="dd.mm.yy"
                  formControlName="date"
                ></p-calendar>
                <label for="endDate">Kordub kuni</label>
              </p-floatLabel>
            </div>
          </div>
        </div>
      }

      @if (selectedCopyType === PlanTimeCopyType.SINGLE_DAYS) {
        <div class="flex flex-column w-full">
          @for (date of dates.controls; track $index) {
            <div class="flex row w-full gap-2 pb-4" [formGroup]="this.getDateFormGroup($index)">
              <div class="flex flex-column w-full">
                <p-calendar
                  [iconDisplay]="'input'"
                  [showIcon]="true"
                  id="date-{{$index}}"
                  appendTo="body"
                  class="w-full"
                  styleClass="w-full"
                  dateFormat="dd.mm.yy"
                  formControlName="date"
                ></p-calendar>
              </div>
              @if($index != 0){
                <div class="form-section__col min-w-max">
                  <p-button
                    icon="pi pi-times"
                    severity="secondary"
                    [rounded]="true"
                    [text]="true"
                    (click)="removeDate($index)"
                  />
                </div>
              }
            </div>
          }
        </div>
        <div class="form-section__col min-w-max">
          <p-button
            label="Lisa päev"
            icon="pi pi-plus"
            [outlined]="true"
            (click)="addDate()"
          />
        </div>
      }
      <div class="flex row justify-content-end pb-4 gap-2">
        <p-button label="Katkesta" [outlined]="true" severity="secondary" (click)="closeModal()"/>
        <p-button label="Salvesta" (click)="copyPlanTimes()"/>
      </div>
    }
  }
  @if (showReport) {
    <medis-plantime-mass-update-report
      [copyReport]="copyResult"
      (onClose)="closeModal()"
      (continueCopy)="restartCopy()"
    >
    </medis-plantime-mass-update-report>
  }
</div>
