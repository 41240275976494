import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, interval, Observable, of, take } from 'rxjs';
import { Router } from '@angular/router';
import { ICurrentUser } from '../interfaces/current-user.interface';
import { environment } from '@environments/environment';
import { IUser } from '../interfaces/user.interface';
import { ModalSize } from "@core/enums/modalSize";
import { ModalService } from "@core/services/modal.service";
import { SelectDepartmentModalComponent } from "@shared/modals/select-department/select-department.modal.component";
import { ConfirmationService } from "primeng/api";
import { CookieService } from "ngx-cookie-service";
import { tap } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private readonly AUTH_TOKEN = 'authToken';
  public readonly TENANT_ID = 'tenant_id';
  private readonly BACKEND_URL: string;
  readonly currentUserSubject = new BehaviorSubject<ICurrentUser | null>(null);

  constructor(private http: HttpClient,
              private router: Router,
              private confirmationService: ConfirmationService,
              private cookieService: CookieService,
              private modalService: ModalService,) {
    this.BACKEND_URL = `${environment.backendUrl}/auth`;
  }

  private addAccessTokenToLocalStorage(token: string): void {
    this.cookieService.set(this.AUTH_TOKEN, token,  undefined, '/', '', true);
  }

  getAccessToken(): any {
    return this.cookieService.get(this.AUTH_TOKEN);
  }

  getTenantId(): any {
    return localStorage.getItem(this.TENANT_ID);
  }

  hasAccessToken(): boolean {
    return !!this.getAccessToken();
  }

  removeAccessToken(): void {
    this.cookieService.delete(this.AUTH_TOKEN, '/');
  }

  start(credentials: IUser): Observable<string> {
    return this.http.post<string>(`${this.BACKEND_URL}/login`, credentials);
  }

  authStatus(): Observable<any> {
    return this.http.get<string>(`${this.BACKEND_URL}/status`);
  }

  extend(): Observable<any> {
    return this.http.put<string>(`${this.BACKEND_URL}/keepAlive`, {});
  }

  getCurrentUser(): Observable<ICurrentUser | null> {
    if (this.currentUserSubject.value !== null) {
      return of(this.currentUserSubject.value);
    }

    return this.http.get<ICurrentUser>(`${this.BACKEND_URL}/user`).pipe(
      tap((user) => this.currentUserSubject.next(user))
    );
  }

  changeDepartment(departmentId: number, personProfessionId: number): Observable<void> {
    const payload = {departmentId, personProfessionId};
    return this.http.post<void>(`${this.BACKEND_URL}/department`, payload);
  }

  handleUserLogin(token: string): void {
    this.addAccessTokenToLocalStorage(token);
    this.handleDepartmentLogin();
  }

  handleDepartmentLogin(): void {
    this.getCurrentUser().subscribe((currentUser) => {
      if (currentUser && currentUser.personDepartments && currentUser.personDepartments.length > 0) {
        if (!currentUser.selectedDepartmentId) {
          this.modalService.openModal({
            component: SelectDepartmentModalComponent,
            size: ModalSize.MEDIUM,
            data: {currentUser},
            header: 'Vali osakond',
            disableClose: true,
          });
        } else {
          location.reload();
        }
      }
    });
  }

  extendSession(): void {
    this.authStatus().subscribe({
      next: (status: any) => {
        let endDate = new Date(status.expirationTime);

        let time = endDate.getTime() - Date.now();

        if (time < (5 * 60 * 1000)) {
          let a = interval(time)
            .pipe(take(1))
            .subscribe({
              next: () => {
                this.authStatus().subscribe({
                  next: (status: any) => {
                    if(status.status == 'Expired') {
                      this.removeAccessToken();
                      location.reload();
                    } else {
                      modal.close();
                    }
                  }
                })
              },
              error: () => {
                this.removeAccessToken();
                location.reload();
              }
            });
          let modal = this.confirmationService.confirm({
            key: 'confirm-action',
            message: `Logime Su turvakaalutlustel välja, kuna olid veidi aega eemal. Kas soovid süsteemi kasutamist jätkata?`,
            header: 'Kinnita tegevust',
            rejectButtonStyleClass: 'p-button-outlined p-button-secondary',
            acceptLabel: 'Kinnita',
            rejectLabel: 'Katkesta',
            accept: () => {
              a.unsubscribe();
              this.extend().subscribe();
            },
            reject: () => {
              this.removeAccessToken();
              location.reload();
            }
          });
        }
      }
    })
  }

  logout(): void {
    localStorage.clear();
    this.removeAccessToken();
    this.clearCurrentUser();
    this.router.navigate(['/auth']);
  }

  clearCurrentUser(): void {
    this.currentUserSubject.next(null);
  }

  setCurrentUserToSubject(): void {
    this.getCurrentUser().subscribe({
      next: (response) => {
        if (response) {
          this.currentUserSubject.next(response);
        }
      },
      error: (err) => {
        console.error(err);
      }
    })
  }

  setTenantId(tenantId: string | null) {
    localStorage.setItem(this.TENANT_ID, tenantId ?? '');
  }
}
