import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { catchError, Observable, of, throwError } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { Router } from "@angular/router";
import { jwtDecode } from "jwt-decode";
import { ToastService } from "@core/services/toast.service";
import { tap } from "rxjs/operators";
import { er } from "@fullcalendar/core/internal-common";

@Injectable({
  providedIn: 'root',
})
export class ErrorInterceptor implements HttpInterceptor {

    constructor(
    private toastService: ToastService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req)
      .pipe(
      catchError((error: HttpErrorResponse) => {

        const skipErrorToast = req.headers.get('X-Skip-Error-Toast') === 'true';

        if (!skipErrorToast) {
          let message;
          if (error.status === 0) {
            message = 'Serveri viga';
          } else if (error.error?.errors?.length) {
            message = error.error.errors[0].message;
          } else if (error.status === 401) {
            message = 'Sessioon on aegunud';
          }
          this.toastService.error(message);
        }

        return throwError(() => error);
      }));
  }
}
