<div class="pb-4">
  @if (!loading) {
    <p-table [value]="patients" class="table patients-table">
      <ng-template pTemplate="header">
        <tr class="w-12">
          <th class="w-2">PATSIENT</th>
          <th class="w-2">TELEFON</th>
          <th class="w-2">OSUTAJA</th>
          <th class="w-2">TIIM</th>
          <th class="w-2">ESMANE KONTAKT</th>
          <th class="w-2">KINDLUSTATUD</th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-patient>
        <tr (click)="choosePatient(patient)"
            class="cursor-pointer hover:bg-primary-50"
        >
          <td>
            <medis-patient-basic-data
              [patientName]="patient.fullName"
              [patientCode]="patient.idCode"
              (onPatientClick)="openPatientView(patient.patientId)"
            />
          </td>
          <td>
            <span>{{ patient.phone }}</span>
          </td>
          <td>
            <span>{{ patient.doctorName }}</span>
          </td>
          <td>
            <span>{{ patient.generalPractitionerTeamName }}</span>
          </td>
          <td>
            <span>{{ patient.gpTeamPrimaryProviderName }}</span>
          </td>
          <td>
            <span>
              <p-tag
                [styleClass]="'white-space-nowrap tag tag--large ' + (getStatusInfo(patient.isInsured).class)"
                rounded="true"
                [value]="getStatusInfo(patient.isInsured).name">
              </p-tag>
            </span>
          </td>
          <td>
            <span class="flex justify-content-end align-items-center">
              <p-button
                severity="secondary"
                [outlined]="true"
                label="Vali"
                (click)="choosePatient(patient)"
                [style]="{ height: '34px', width: '62px'}">
              </p-button>
            </span>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td [attr.colspan]="7">Midagi pole leitud.</td>
        </tr>
      </ng-template>
    </p-table>
  } @else {
    <p-skeleton height="40rem"/>
  }
</div>
