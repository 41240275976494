<div class="comment__item w-full" [class.last]="last">
  <div class="comment__label flex flex-row justify-content-between">
    <div>
      <b>{{ comment?.userName }}</b>  |  {{ comment?.created | dateFormat }}
    </div>
    <div>
      <span
        class="cursor-pointer"
        (click)="onDeleteComment()"
      >
        <i class="pi pi-times"></i>
      </span>
    </div>
  </div>
  <div class="comment__value">{{ comment?.comment }}</div>
</div>
